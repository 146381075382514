import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { Description, Header } from './no-delivery-option-modal.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
}

const NoDeliveryOptionModal = ({ isOpen = false, onClose, message }: Props) => {
  const {
    parcelCreationStore: { setNoDeliveryOptionAvailable },
  } = useStore();

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
    setNoDeliveryOptionAvailable(false);
  };

  return (
    <ModalDialog
      onClose={handleClose}
      isOpen={isOpen}
      width='40rem'
      padding='3.2rem 4rem'
      displayButtons={false}>
      <Header>{t('reprice_header_no_delivery_option')}</Header>
      <Description>{t('reprice_message')}</Description>
      <Button
        $mb='1.6rem'
        onClick={handleClose}>
        {t('okay')}
      </Button>
    </ModalDialog>
  );
};

export default observer(NoDeliveryOptionModal);
