import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import InputSearch from '../input-search/input-search.component';
import Sidebar from '../sidebar-right/sidebar.component';
import { Container, Content } from './search-with-sidebar.styles';
import MenuItem from '../menu-item/menu-item.component';
import { MenuItems } from '../menu-item/menu-item.styles';
import EmptyList from '../empty-list/empty-list.component';
import { SidebarName } from '../../types/sidebar.types';
import { NO_VALIDATION_RULE } from '../../constants/validation';
import { ValidationRuleType } from '../../types/input-validation-types';

interface Props<T> {
  defaultOption?: T | null;
  name: SidebarName;
  sidebarTitle: string;
  sidebarInputPlaceholder: string;
  debounceSidebarInputChange?: boolean;

  // where to get search options and how to filter them and how to display
  displayAllOptionsWithEmptyFilter?: boolean;
  // predicate which defines which search option will have 'selected' style
  isOptionSelected?: (
    currentOption: T | undefined | null,
    selectedOption: T | undefined | null
  ) => boolean;
  getSearchOptions: (inputFilterValue?: string) => Promise<T[] | undefined>;
  getKeyForSearchOption?: (option: T) => string;
  getDisplayValueForSearchOption?: (option: T) => string;
  onSearchOptionSelected?: (option: T) => void;
  onSearchFinished?: () => void;
  onSidebarClosed?: () => void;

  shouldValidateSearchQuery?: boolean;
  showSkeleton?: boolean;
  validationRule?: ValidationRuleType;
}

function SearchWithSidebar<T>({
  name,
  defaultOption,
  sidebarTitle,
  sidebarInputPlaceholder,
  debounceSidebarInputChange,
  displayAllOptionsWithEmptyFilter,
  isOptionSelected,
  getSearchOptions,
  getKeyForSearchOption,
  getDisplayValueForSearchOption,
  onSearchOptionSelected,
  onSearchFinished,
  onSidebarClosed,
  shouldValidateSearchQuery,
  showSkeleton,
  validationRule = NO_VALIDATION_RULE,
}: Props<T>) {
  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const [searchOptions, setSearchOptions] = useState<T[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState<T | undefined | null>(
    defaultOption
  );
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (displayAllOptionsWithEmptyFilter) {
      getSearchOptions().then((p) => setSearchOptions(p));
    }
    // eslint-disable-next-line
  }, [getSearchOptions]);

  const modifyListOfDisplayedOptions = async (value?: string) => {
    if (value || displayAllOptionsWithEmptyFilter) {
      setSearchOptions((await getSearchOptions(value)) ?? []);
      setSearchInputValue(value);
    } else {
      setSearchOptions(undefined);
    }
  };

  const handleSearchOptionSelected = (option: T) => {
    onSearchOptionSelected && onSearchOptionSelected(option);
    onSearchFinished && onSearchFinished();
    toggleSidebarByName(name);
    modifyListOfDisplayedOptions();
    setSelectedOption(option);
    setSearchInputValue('');
  };

  const { t } = useTranslation();

  return (
    <Sidebar
      name={name}
      withBlurredBackground
      header={sidebarTitle}
      onClose={() => {
        modifyListOfDisplayedOptions();
        setSearchInputValue('');
        onSidebarClosed && onSidebarClosed();
      }}>
      <Container>
        <InputSearch
          name={`search_${sidebarTitle}`}
          placeholder={sidebarInputPlaceholder}
          focusOnOpen
          searchIconPosition='left'
          disableSearchIconHover
          showClearInputButton
          debounceInputChange={debounceSidebarInputChange}
          shouldValidateSearchQuery={shouldValidateSearchQuery}
          onChange={(targetValue) => modifyListOfDisplayedOptions(targetValue)}
          showSkeleton={showSkeleton}
          validationRule={validationRule}
        />
        <Content>
          {searchOptions && searchOptions.length > 0 ? (
            <MenuItems>
              {searchOptions.map((option) => (
                <MenuItem
                  key={
                    getKeyForSearchOption
                      ? getKeyForSearchOption(option)
                      : (option as string)
                  }
                  isSelected={
                    isOptionSelected && isOptionSelected(option, selectedOption)
                  }
                  onClick={() => handleSearchOptionSelected(option)}>
                  {getDisplayValueForSearchOption
                    ? getDisplayValueForSearchOption(option)
                    : (option as string)}
                </MenuItem>
              ))}
            </MenuItems>
          ) : (
            <EmptyList
              isSidebar
              imageType='loupe'
              title={searchInputValue ? t('no_search_results') : undefined}
              description={
                searchInputValue
                  ? t('please_try_again')
                  : t('havent_searched_yet')
              }
            />
          )}
        </Content>
      </Container>
    </Sidebar>
  );
}

export default observer(SearchWithSidebar);
