import styled, { css } from 'styled-components';
import {
  TextInput,
  TextInputPlaceholder,
  TextLabel,
  FontSizes,
} from '../typography/typography.styles';
import errorIcon from '../../assets/error-icon.svg';
import showPassIcon from '../../assets/pass-show-icon.svg';
import hidePassIcon from '../../assets/pass-hide-icon.svg';
import { BaseMarginComponent } from '../base-margin/base-margin.component';
import { InputSelect } from '../input-select-with-sidebar/input-select-with-sidebar.styles';

interface FieldProps {
  $error?: boolean;
  $maxInputHeight?: string;
  $borderNone?: boolean;
  $padding?: string;
  $fontSize?: string;
  $disabled?: boolean;
  $readOnly?: boolean;
}

export const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 6px;
`;

export const TextLabelOptional = styled.label`
  font-size: ${FontSizes.normal};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.input.labelColor};
`;

export const FieldContainer = styled(BaseMarginComponent('div'))`
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputContainer = styled.div<FieldProps>`
  position: relative;

  input,
  ${InputSelect} {
    position: relative;
    width: 100%;
    border: ${({ $borderNone, theme }) =>
      $borderNone ? 'none' : `1px solid ${theme.colors.baseColors.grey50}`};
    border-radius: 10px;
    padding: ${({ $padding = '1rem 1.6rem' }) => $padding};
    outline: none;
    transition: border-color 0.2s;
    max-height: ${({ $maxInputHeight = '4rem' }) => $maxInputHeight};
    ${TextInput}
    font-size: ${FontSizes.medium};
    font-family: 'Noto Color Emoji', Montserrat !important;

    @media screen and (min-width: 768px) {
      font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
    }

    ${({ $error }) =>
      $error &&
      css`
        border-color: ${({ theme }) => theme.colors.input.errorColor};
      `}

    &::placeholder {
      ${TextInputPlaceholder}
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${FontSizes.medium};
      font-family: 'Noto Color Emoji', Montserrat !important;

      @media screen and (min-width: 768px) {
        font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
      }
    }

    &:focus-visible {
      border-color: ${({ theme }) => theme.colors.input.borderColorFocus};
    }

    ${({ $disabled }) =>
      $disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.baseColors.grey5};
        cursor: not-allowed;
        opacity: 1;
      `}
  }

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      input {
        background-color: #f5f6f7;
      }
      pointer-events: none;
    `}

  ${TextLabel} {
    position: relative;
    display: inline-block;
  }
`;

interface PassToggleProps {
  $type: 'show' | 'hide';
}

export const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const InfoTextContainer = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  span {
    ${TextInputPlaceholder}
  }
`;

export const ErrorIcon = styled.span`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background: url(${errorIcon}) no-repeat content-box;
`;

export const Hint = styled.div`
  display: flex;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const PassVisibilityIcon = styled.span<PassToggleProps>`
  display: inline-block;
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
  background: url(${({ $type }) =>
      $type === 'show' ? showPassIcon : hidePassIcon})
    no-repeat right content-box;
  transition: background 0.2s;
`;

export const LabelTooltipContainer = styled.div`
  display: flex;
  gap: 0.8rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;
