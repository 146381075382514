import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import {
  HeaderSecondary,
  TextBody,
} from '../../../common/typography/typography.styles';
import {
  ParcelCreationMobileHeader,
  ParcelCreationProgressMobileContainer,
  ProgressBarWrapper,
} from './parcel-creation-progress.styles';
import CloseButton from '../../../common/close-button/close-button.component';
import { generateLocaleRoute } from '../../../utils/uri/uri.utils';
import ProgressBar from '../../../common/progress-bar/progress-bar.component';
import { theme } from '../../../styles/themes';
import {
  findStepIndex,
  toTranslationKey,
} from '../../../models/parcelCreationFlowNavigation';
import { AppRoute } from '../../../constants/route';

const ParcelCreationProgressMobile = () => {
  const {
    parcelCreationStore: { navigation, isDraftEditing },
  } = useStore();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentStepIndex =
    findStepIndex(navigation, navigation.current!.name) + 1;
  const maxStep = navigation.map.length;

  const isSignatureStep = navigation.current!.name === AppRoute.SIGNATURE;
  const progressPercent =
    (currentStepIndex / (maxStep > 2 ? maxStep : 6)) * 100;

  return (
    <ParcelCreationProgressMobileContainer id='parcelProgressMobile'>
      <ParcelCreationMobileHeader>
        <HeaderSecondary>
          {isDraftEditing ? t('edit_parcel') : t('create_a_parcel')}
        </HeaderSecondary>
        <CloseButton
          size='big'
          onClick={(e) => {
            e.stopPropagation();
            navigate(generateLocaleRoute());
          }}
        />
        {!isSignatureStep && (
          <ProgressBarWrapper>
            <ProgressBar width={progressPercent} />
          </ProgressBarWrapper>
        )}
      </ParcelCreationMobileHeader>
      {!isSignatureStep && (
        <TextBody style={{ color: `${theme.colors.baseColors.grey150}` }}>
          {`${t('step')} ${currentStepIndex}. ${t(toTranslationKey(navigation.current?.name))}`}
        </TextBody>
      )}
    </ParcelCreationProgressMobileContainer>
  );
};

export default observer(ParcelCreationProgressMobile);
