import { observer } from 'mobx-react-lite';
import ParcelDetailsSummary from '../../features/parcel-creation/parcel-details-summary/parcel-details-summary.component';
import SenderDetailsSummary from '../../features/parcel-creation/sender-details/sender-details-summary.component';
import ReceiverDetailsSummary from '../../features/parcel-creation/receiver-details-summary/receiver-details-summary.component';
import ParcelContentSummary from '../../features/parcel-creation/parcel-content-summary/parcel-content-summary.component';
import InsuranceDetailsSummary from '../../features/parcel-creation/insurance-details-summary/insurance-details-summary.component';
import SummaryCheckboxSection from '../../features/parcel-creation/summary-checkbox-section/summary-checkbox-section.component';
import { useStore } from '../../stores/store';
import PromocodeSummary from '../../features/parcel-creation/promocode-summary/promocode-summary.component';
import ParcelTaxesInfo from '../../features/parcel-creation/parcel-taxes-info/parcel-taxes-info';

const ParcelSummary = () => {
  const {
    parcelCreationStore: { shipment },
  } = useStore();

  if (!shipment) return null;

  return (
    <>
      <ParcelDetailsSummary />
      <SenderDetailsSummary />
      <ReceiverDetailsSummary />
      <ParcelContentSummary />
      <InsuranceDetailsSummary />
      <ParcelTaxesInfo
        shipment={shipment}
        hideRules
      />
      <PromocodeSummary />
      <SummaryCheckboxSection />
    </>
  );
};

export default observer(ParcelSummary);
