/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Phone, User, Receiver } from '../models/user';
import { store } from './store';
import agent from '../api/agent';
import {
  signInWithGooglePopup,
  signInWithApplePopup,
} from '../utils/firebase/firebase.utils';
import { PersonalDataValues } from '../models/personalDataValues';
import { ContactDetailsValues } from '../models/contactDetailsValues';
import { PreferencesDataValues } from '../models/preferencesDataValues';
import { getCountryName } from '../common/form-field-phone/form-field-phone.component';
import { AddressDataValues } from '../models/addressDataValues';
import { currencySymbolsMap } from '../constants/currency';
import { translate } from '../../i18n';
import { ApiResponseStatus } from '../constants/server';
import { IOptionsPages } from '../models/paginationOptions';
import {
  buildQueryParams,
  generateLocaleRoute,
  generateMyRoute,
} from '../utils/uri/uri.utils';
import { AppRoute, SearchParam } from '../constants/route';
import { router } from '../../Router';
import { Manual } from '../models/manual';
import { Measure } from '../utils/parcel-measurements.ts/parcel-measurements-utils';
import { DEFAULT_COUNTRY } from './localizationsStore';
import { Modals } from '../constants/modals';
import { handleError } from '../utils/generic/generic.utils';
import { SignUpFormValues } from '../features/authentication/sign-up/sign-up.component';

export type SocialLoginType = 'apple' | 'google';

export default class UserStore {
  user: User | null = null;

  receivers: Receiver[] | undefined = [];

  isLoadingUser: boolean = false;

  isLoadingReceivers: boolean = false;

  googleSignInProgress: boolean = false;

  appleSignInProgress: boolean = false;

  emailSignInProgress: boolean = false;

  signUpInProgress: boolean = false;

  passwordResetProgress: boolean = false;

  emailVerificationProgress: boolean = false;

  isLogoutRequested: boolean = false;

  isOnboardingManualLoading: boolean = false;

  onboardingManual: Manual | undefined = undefined;

  isNeedMigrationAgreement: boolean = false;

  receiversOptions: IOptionsPages = {
    page: 1,
    totalPagesCount: 1,
    perPage: 12,
    totalElCount: 3,
  };

  isMigratingInProgress: boolean = false;

  isDeletingAccount: boolean = false;

  isRequestingPhoneCode: boolean = false;

  isPhoneVerifying: boolean = false;

  signUpFormValues?: SignUpFormValues = undefined;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: ['receivers', 'receiversOptions'],
      storage: window.localStorage,
    });
    makePersistable(this, {
      name: 'UserStoreSession',
      properties: ['isLogoutRequested', 'signUpFormValues'],
      storage: window.sessionStorage,
    });
  }

  get urlSearchParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = searchParams ? `?${searchParams}` : '';
    return params;
  }

  get measures() {
    return (
      store.userStore.user?.measures ??
      store.localizationsStore.selectedCountry?.default_measures ??
      Measure.KG_CM
    );
  }

  getCurrencySymbol = (currencyCode?: string) => {
    if (currencyCode) return currencySymbolsMap[currencyCode];
    if (this.user) return currencySymbolsMap[this.user!.currency];
    const selectedCountryCurrency =
      store.localizationsStore.selectedCountry?.default_currency;
    if (selectedCountryCurrency)
      return currencySymbolsMap[selectedCountryCurrency];
    return currencySymbolsMap[DEFAULT_COUNTRY.default_currency];
  };

  getInitials = () => {
    let initials = '';

    if (this.user?.first_name && this.user?.last_name) {
      initials =
        this.user!.first_name.charAt(0) + this.user!.last_name.charAt(0);
    } else {
      initials = this.user!.email?.slice(0, 2).toLocaleUpperCase();
    }

    return initials;
  };

  getAddress = () => {
    let address: string = '';

    if (this.user?.address?.building && this.user?.address?.building !== '')
      address += this.user!.address!.building;
    if (this.user?.address?.street && this.user?.address?.building !== '')
      address += (address !== '' ? ' ' : '') + this.user!.address!.street;
    if (this.user?.address?.apartment && this.user?.address?.building !== '')
      address +=
        (address !== '' ? `, ${translate('apt')}. ` : `${translate('apt')}. `) +
        this.user!.address!.apartment;

    return address !== '' ? address : null;
  };

  getCountryName = () => {
    let countryName: string | undefined;

    if (this.user?.country) {
      countryName = getCountryName(this.user.country);
    }

    return countryName;
  };

  getUserName = (includeMiddleName: boolean = false) => {
    const fullName: (string | undefined | null)[] = [];

    if (!this.user) return '';

    fullName.push(this.user?.first_name);
    if (includeMiddleName) fullName.push(this.user?.middle_name);
    fullName.push(this.user?.last_name);

    return fullName.filter((x) => x).join(' ');
  };

  redirectFromLogin = () => {
    runInAction(() => {
      store.navStore.closeAllRightSidebars();
    });
    const params = new URL(window.location.href).searchParams;
    const redirect = params.get('redirect');
    router.navigate(redirect || generateMyRoute(AppRoute.PARCEL_LIST));
  };

  setLoginData = ({
    isSocialLogin,
    meestToken,
    refreshToken,
  }: {
    isSocialLogin: boolean;
    meestToken: string;
    refreshToken: string;
  }) => {
    runInAction(() => {
      store.commonStore.setMeestToken(meestToken!);
      store.commonStore.setRefreshToken(refreshToken!);
      store.commonStore.setIsSocialLogin(isSocialLogin);
      store.commonStore.setIsGuestParcelCreation(false);
    });
  };

  socialLogin = async ({
    socialType,
    termsAndConditions,
    newsletters,
    omitLoginPopup = false,
  }: {
    socialType: SocialLoginType;
    termsAndConditions?: boolean;
    newsletters?: boolean;
    omitLoginPopup?: boolean;
  }) => {
    this.appleSignInProgress = socialType === 'apple';
    this.googleSignInProgress = socialType === 'google';
    try {
      let handler;
      let authUser;
      let firebaseToken;
      let firebaseUid;
      if (!omitLoginPopup) {
        handler =
          socialType === 'apple' ? signInWithApplePopup : signInWithGooglePopup;
        authUser = await handler();

        firebaseToken = (authUser.user as any).accessToken;
        firebaseUid = authUser.user.uid;
      } else {
        firebaseToken = store.commonStore.firebaseToken;
        firebaseUid = store.commonStore.firebaseUid;
      }

      if (!firebaseToken || !firebaseUid) return;

      store.commonStore.setSocialLoginType(socialType);
      store.commonStore.setFirebaseUid(firebaseUid);
      store.commonStore.setFirebaseToken(firebaseToken);

      const { meestToken, refreshToken, isPhoneVerified } =
        await agent.Auth.socialLogin({
          firebase_uid: firebaseUid,
          firebase_token: firebaseToken,
          device_uuid: store.commonStore.getDeviceUuid,
          newsletters,
          terms_and_conditions: termsAndConditions,
        });

      this.setLoginData({
        isSocialLogin: true,
        meestToken,
        refreshToken,
      });

      if (!isPhoneVerified) {
        store.navStore.setAuthCurrentScreen('add-phone-number');
        router.navigate(
          generateLocaleRoute(
            `${AppRoute.AUTH}/${AppRoute.PHONE_VERIFICATION}${this.urlSearchParams}`
          )
        );
      } else {
        this.redirectFromLogin();
      }
      return { success: true, isPhoneVerified };
    } catch (error: any) {
      if (error.response?.status === ApiResponseStatus.PRECONDITION_REQUIRED) {
        store.navStore.setAuthCurrentScreen('welcome-social-account');
        return;
      }

      handleError(error);
      return { success: false, isPhoneVerified: false };
    } finally {
      runInAction(() => {
        this.appleSignInProgress = false;
        this.googleSignInProgress = false;
      });
    }
  };

  googleLogin = async () => this.socialLogin({ socialType: 'google' });

  appleLogin = async () => this.socialLogin({ socialType: 'apple' });

  emailLogin = async ({
    login,
    password,
    termsAndConditions,
    newsletters,
  }: {
    login: string;
    password: string;
    termsAndConditions?: boolean;
    newsletters?: boolean;
  }) => {
    this.emailSignInProgress = true;

    const result = {
      success: false,
      loginOrPasswordIsIncorrect: false,
      isPhoneVerified: false,
    };

    try {
      const {
        meestToken,
        refreshToken,
        isPhoneVerified,
        isNeedMigrationAgreement,
      } = await agent.Auth.login({
        login,
        password,
        newsletters,
        terms_and_conditions: termsAndConditions,
      });

      this.setLoginData({ isSocialLogin: false, meestToken, refreshToken });
      runInAction(() => {
        this.isNeedMigrationAgreement = !!isNeedMigrationAgreement;
      });

      result.success = true;
      result.isPhoneVerified = !!isPhoneVerified;

      if (!isPhoneVerified) {
        store.navStore.setAuthCurrentScreen('add-phone-number');
        router.navigate(
          generateLocaleRoute(
            `${AppRoute.AUTH}/${AppRoute.PHONE_VERIFICATION}${this.urlSearchParams}`
          )
        );
      } else if (isNeedMigrationAgreement) {
        store.navStore.setAuthCurrentScreen('data-migration-agreement');
        router.navigate(
          generateLocaleRoute(
            `${AppRoute.AUTH}/${AppRoute.DATA_MIGRATION}${this.urlSearchParams}`
          )
        );
      } else {
        this.redirectFromLogin();
      }
    } catch (error: any) {
      if (error.response?.status === ApiResponseStatus.FORBIDDEN) {
        if (store.navStore.timerEnd === 0) {
          store.navStore.setShowSendAgain(true);
        }
        store.commonStore.setCurrentAuthData(login, password);
        store.navStore.setAuthCurrentScreen('sign-up-email-sent');
        return result;
      }

      if (error.response?.status === ApiResponseStatus.PRECONDITION_REQUIRED) {
        store.commonStore.setCurrentAuthData(login, password);
        store.navStore.setAuthCurrentScreen('welcome-old-account');
        return result;
      }
      // Ignore error handling in specific cases and just show field error label (handled in calling component)
      result.loginOrPasswordIsIncorrect = [
        ApiResponseStatus.VALIDATION_ERROR, // userNotRegistered,
        ApiResponseStatus.LOCKED, // loginOrPasswordIsIncorrect,
      ].includes(error.response?.status);

      if (!result.loginOrPasswordIsIncorrect) {
        handleError(error);
      }
    } finally {
      runInAction(() => {
        this.emailSignInProgress = false;
      });
    }

    return result;
  };

  submitSocialWithTermsAndConditions = async ({
    termsAndConditions,
    newsletters,
  }: {
    termsAndConditions: boolean;
    newsletters: boolean;
  }) => {
    if (!store.commonStore.socialLoginType) {
      store.navStore.setAuthCurrentScreen('auth-tabs');
      return;
    }

    this.socialLogin({
      socialType: store.commonStore.socialLoginType,
      termsAndConditions,
      newsletters,
      omitLoginPopup: true,
    });
  };

  submitWithTermsAndConditions = async ({
    termsAndConditions,
    newsletters,
  }: {
    termsAndConditions: boolean;
    newsletters: boolean;
  }) => {
    if (!store.commonStore.currentAuthData) {
      return;
    }

    this.emailLogin({
      login: store.commonStore.currentAuthData!.email,
      password: store.commonStore.currentAuthData!.password!,
      termsAndConditions,
      newsletters,
    });
  };

  signUp = async (email: string, password: string, newsletters: boolean) => {
    this.signUpInProgress = true;

    const result = {
      success: false,
      userAlreadyExists: false,
    };

    try {
      // NOTE: email is sent automatically inside Auth.register
      /* eslint-disable-next-line */
      const { isEmailSent, isSmsSent } = await agent.Auth.register(
        email,
        password,
        newsletters
      );

      store.navStore.setAuthCurrentScreen('sign-up-email-sent');
      store.navStore.setShowSendAgain(false);
      store.commonStore.setCurrentAuthData(email, password);
      result.success = true;
    } catch (error: any) {
      result.userAlreadyExists =
        error.response?.status === ApiResponseStatus.FORBIDDEN;

      if (!result.userAlreadyExists) {
        handleError(error);
      }
    } finally {
      runInAction(() => {
        this.signUpInProgress = false;
      });
    }

    return result;
  };

  signUpFinishEmailVerification = async (oobCode: string) => {
    this.emailVerificationProgress = true;

    try {
      await agent.OobActions.verifyEmail(oobCode);
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.emailVerificationProgress = false;
      });
    }
  };

  // resetPassword start procedure
  resetPasswordStart = async (email: string) => {
    this.passwordResetProgress = true;

    try {
      await agent.OobActions.sendResetPasswordEmail(email);

      store.navStore.setAuthCurrentScreen('password-reset-email-sent');
      store.commonStore.setCurrentAuthData(email);
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.passwordResetProgress = false;
      });
    }
  };

  changePassword = async (newPassword: string) => {
    this.isLoadingUser = true;

    try {
      await agent.Profile.updatePassword(newPassword);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
      });
    }
  };

  getCurrentUser = async () => {
    runInAction(() => {
      this.isLoadingUser = true;
    });
    if (!this.user) store.commonStore.setAppLoaded(false);
    try {
      const user = await agent.Profile.profile();
      runInAction(() => {
        if (!user) return;
        this.user = user;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
        store.commonStore.setAppLoaded(true);
      });
    }
  };

  setPhoneForVerification = (phoneNumber: string | undefined) => {
    if (this.user) {
      this.user.phoneForVerification = phoneNumber;
    }
  };

  updatePhone = async (phone: Phone) => {
    this.isLoadingUser = true;
    this.isRequestingPhoneCode = true;
    try {
      await agent.Profile.updatePhone(phone);
      this.setPhoneForVerification(phone.phone_number);

      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isRequestingPhoneCode = false;
      });
    }
  };

  verifyPhone = async (
    phoneCountryCode: string,
    phoneNumber: string,
    code: string
  ) => {
    this.isLoadingUser = true;
    this.isPhoneVerifying = true;
    try {
      const phoneCountryCodeUppercase = phoneCountryCode.toUpperCase();
      const result = await agent.Profile.verifyPhone(
        phoneCountryCodeUppercase,
        phoneNumber,
        code
      );

      runInAction(() => {
        // clear verification phone when main phone is approved
        this.setPhoneForVerification(undefined);
        if (this.user) {
          this.user.phone = {
            country_code: phoneCountryCodeUppercase,
            phone_number: phoneNumber,
          };
          this.user.phone_verified = true;
        }
        store.commonStore.setMeestToken(result?.token!);
        store.commonStore.setRefreshToken(result?.refresh_token!);
      });
      return true;
    } catch (error) {
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
        this.isPhoneVerifying = false;
      });
    }
  };

  // Updates first_name, last_name and middle_name for currently logged in user.
  updatePersonalData = async (personalData: PersonalDataValues) => {
    if (!this.user) return false;

    this.isLoadingUser = true;
    try {
      const updatedUser = await agent.Profile.updatePersonalData(personalData);

      runInAction(() => {
        this.user! = updatedUser!;
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
      });
    }
  };

  // Updates user localization settings
  updatePreferences = async (preferences: PreferencesDataValues) => {
    if (!this.user) return false;

    this.isLoadingUser = true;
    try {
      const updatedUser = await agent.Profile.updatePreferences(preferences);

      runInAction(() => {
        this.user! = updatedUser!;
      });

      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
      });
    }
  };

  /* eslint-disable-next-line */
  updateContactInfo = async (contactDetails: ContactDetailsValues) => {
    if (!this.user) return false;

    this.isLoadingUser = true;
    try {
      // For now this API is removed but may appear again in future so I want to preserve the user store method and related form
      // const updatedUser = await agent.Profile.updateContactInfo(contactDetails);

      // runInAction(() => {
      //   this.user! = updatedUser!;
      // });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
      });
    }
  };

  updateAddress = async (addressInfo: AddressDataValues) => {
    if (!this.user) return false;

    this.isLoadingUser = true;
    try {
      const updatedUser = await agent.Profile.updateAddress(addressInfo);

      runInAction(() => {
        this.user! = updatedUser!;
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingUser = false;
      });
    }
  };

  logout = async (
    {
      omitApiLogout,
      shouldRedirectToMainPage,
      interruptLogoutAfterRedirect,
    }: {
      omitApiLogout?: boolean;
      shouldRedirectToMainPage?: boolean;
      interruptLogoutAfterRedirect?: boolean;
    } = {
      omitApiLogout: false,
      shouldRedirectToMainPage: true,
      interruptLogoutAfterRedirect: false,
    }
  ) => {
    this.setIsLogoutRequested(true);

    if (shouldRedirectToMainPage) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(SearchParam.NAVIGATE_TO, generateLocaleRoute());
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      router.navigate(newUrl, { replace: true });
    }

    // Required to intercept logout behaviour (e.g. with modal dialog during parcel creation flow)
    if (interruptLogoutAfterRedirect) {
      return;
    }

    if (!omitApiLogout) {
      runInAction(() => {
        store.commonStore.setAppLoaded(false);
      });

      try {
        await agent.Auth.logout();
      } catch (error) {
        handleError(error);
      } finally {
        runInAction(() => {
          store.commonStore.setAppLoaded(true);
        });
      }
    }

    runInAction(() => {
      store.commonStore.resetAppState();
      this.setIsLogoutRequested(false);
    });
  };

  deleteAccount = () => {
    if (!this.user) return;
    this.isDeletingAccount = true;
    store.modalStore.update(Modals.DELETE_ACCOUNT, { isLoading: true });

    try {
      agent.Auth.deleteAccount().then(() => {
        this.logout({ omitApiLogout: true });
        store.modalStore.close(Modals.DELETE_ACCOUNT);
        this.isDeletingAccount = false;
      });
    } catch (error) {
      console.error(error);
    }
  };

  getReceivers = async (
    options: IOptionsPages = {
      page: 1,
      totalPagesCount: 1,
      perPage: 12,
      totalElCount: 3,
    },
    append: boolean = false
  ) => {
    this.isLoadingReceivers = true;
    const query = buildQueryParams(options);

    try {
      const { data, meta } = await agent.Profile.receivers(query);

      runInAction(() => {
        const existingReceivers = this.receivers || [];

        if (
          append &&
          options.page &&
          options.page > 1 &&
          existingReceivers.length > 0
        ) {
          this.receivers = [...existingReceivers, ...data!];
        } else {
          this.receivers = data;
        }

        this.receiversOptions = { ...options };
        this.receiversOptions.totalPagesCount = meta?.pageCount;
        this.receiversOptions.totalElCount = meta?.totalCount;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingReceivers = false;
      });
    }
  };

  setReceiversOptions = (options: IOptionsPages) => {
    this.receiversOptions = { ...this.receiversOptions, ...options };
  };

  incrementPerPage = (perPage: number) => {
    this.receiversOptions.perPage = perPage + 12;
  };

  setIsLogoutRequested = (isRequested: boolean) => {
    this.isLogoutRequested = isRequested;
  };

  getOnboardingManual = async () => {
    store.modalStore.open({
      id: Modals.ONBOARDING_MANUAL,
      name: Modals.ONBOARDING_MANUAL,
    });
    this.isOnboardingManualLoading = true;
    try {
      const data = await agent.Shipment.onboarding();
      runInAction(() => {
        this.onboardingManual = data;
        this.isOnboardingManualLoading = false;
      });
    } catch (error) {
      handleError(error);
    }
  };

  confirmOnboardingManual = async () => {
    try {
      const data = await agent.User.confirmOnboardingManual();
      if (data?.user) {
        runInAction(() => {
          this.user = data.user;
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  migrateUser = async (shouldMigrate: boolean) => {
    this.isMigratingInProgress = true;
    try {
      await agent.Profile.migrate(shouldMigrate);
      if (shouldMigrate) {
        await this.getCurrentUser();
      }

      runInAction(() => {
        this.isNeedMigrationAgreement = false;
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.isMigratingInProgress = false;
    }
  };

  setSignUpFormValues = (formValues: SignUpFormValues | undefined) => {
    this.signUpFormValues = formValues;
  };
}
