import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { ParcelDetailsSearchInputsContainer } from '../../../routes/parcel-details/parcel-details.styles';
import { useStore } from '../../../stores/store';
import {
  getCityFromPlaceDetails,
  getPredictionsWithUserInput,
  getZipCodeFromPlaceDetails,
} from '../../../utils/google-places/google-places.utils';
import {
  getShipmentCountriesByFilter,
  getShipmentCountryName,
  getZipCodeDisplayValue,
} from '../../../utils/parcel-creation/parcel-creation-utils';
import { IShipmentCountry } from '../../../stores/parcelCreationFlowStore';
import { PLACES_API_MINIMAL_REQUEST_LENGTH } from '../../../stores/placesSearchStore';
import {
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_ONLY_LETTERS,
} from '../../../constants/validation';
import { getSettingReceiverCountry } from '../../../utils/countries/countries.utils';

const ParcelDetailsDestination = () => {
  const {
    parcelCreationStore: {
      countryDeparture,
      countryDestination,
      setDestinationShipmentCountry,
      getDeliveryLimitations,
      isLoadingShipmentApi,
      setIsNextButtonClicked,
      isNextButtonClicked,
    },
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    localizationsStore: { isLanguageChanging },
  } = useStore();

  const { t } = useTranslation();

  const showSkeleton = isLoadingShipmentApi || isLanguageChanging;

  const receiverCountrySetting = getSettingReceiverCountry(countryDeparture);
  const isDefaultFlow = receiverCountrySetting === 'default_flow';

  useEffect(() => {
    const isDestinationZipRequired = receiverCountrySetting !== 'without_zip';

    const isCountryDepartureValid =
      countryDeparture?.countryCode && countryDeparture?.zipCode;
    const isCountryDestinationValid =
      countryDestination?.countryCode &&
      (isDestinationZipRequired ? countryDestination.zipCode : true);

    if (isCountryDepartureValid && isCountryDestinationValid) {
      getDeliveryLimitations();
    }
  }, [
    countryDeparture,
    countryDestination,
    getDeliveryLimitations,
    receiverCountrySetting,
  ]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('to')}</ServicesSubSectionHeader>
      <ParcelDetailsSearchInputsContainer>
        <InputSearchWithSidebar<IShipmentCountry>
          disabled={!countryDeparture}
          name='destination_country_search'
          inputValue={getShipmentCountryName(countryDestination, t)}
          defaultOption={countryDestination}
          placeholder=''
          label={t('country')}
          sidebarTitle={t('country')}
          sidebarInputPlaceholder={t('start_typing_country_name')}
          displayAllOptionsWithEmptyFilter
          isOptionSelected={(a) =>
            a?.countryCode === countryDestination?.countryCode
          }
          getSearchOptions={(filter) =>
            Promise.resolve(
              getShipmentCountriesByFilter(
                countryDeparture?.destinationCountries ?? [],
                filter
              )
            )
          }
          getKeyForSearchOption={(country) => country.countryCode}
          getDisplayValueForSearchOption={(country) =>
            getShipmentCountryName(country, t)
          }
          onSearchOptionSelected={(country) => {
            setIsNextButtonClicked(false);
            setDestinationShipmentCountry(country);
          }}
          errorMessage={t('this_field_cannot_be_empty')}
          mainInputValidationPredicate={(inputValue) => !!inputValue}
          showSkeleton={showSkeleton}
          validationRule={VALIDATION_RULE_ONLY_LETTERS}
          triggerValidation={isNextButtonClicked}
        />

        {isDefaultFlow && (
          <InputSearchWithSidebar<google.maps.places.AutocompletePrediction>
            disabled={!countryDestination}
            inputValue={getZipCodeDisplayValue(countryDestination)}
            name='destination_zip_search'
            placeholder=''
            label={t('city&zip-code')}
            sidebarTitle={t('city&zip-code')}
            sidebarInputPlaceholder={t('start_typing_zip-code')}
            debounceSidebarInputChange
            shouldValidateSearchQuery
            getSearchOptions={async (filter) => {
              const predictions = await getAutocompletePredictions({
                value: filter ?? '',
                predictionTypes: ['geocode'],
                countryConstraint:
                  countryDestination?.countryCode.toLocaleLowerCase(),
              });

              return getPredictionsWithUserInput(
                predictions,
                filter,
                PLACES_API_MINIMAL_REQUEST_LENGTH
              );
            }}
            getKeyForSearchOption={(place) => place.place_id}
            getDisplayValueForSearchOption={(place) => place.description}
            onSearchOptionSelected={async (place) => {
              setIsNextButtonClicked(false);
              if (place.place_id && place.place_id !== place.description) {
                const placeDetails = await getPlaceDetails(place);
                if (!placeDetails || !countryDestination) return;
                const zipCode = getZipCodeFromPlaceDetails(
                  placeDetails.address_components
                );
                const city = getCityFromPlaceDetails(
                  placeDetails.address_components
                );

                setDestinationShipmentCountry({
                  ...countryDestination,
                  zipCode: zipCode || place.description,
                  city,
                });
              } else {
                if (!countryDestination) return;
                setDestinationShipmentCountry({
                  ...countryDestination,
                  zipCode: place.description,
                  city: undefined,
                });
              }
            }}
            canModifyInput={false}
            onInputChange={(inputValue) => {
              const zipCode = inputValue;

              countryDestination &&
                setDestinationShipmentCountry({
                  ...countryDestination,
                  zipCode,
                  city: undefined,
                });
            }}
            errorMessage={t('this_field_cannot_be_empty')}
            mainInputValidationPredicate={(inputValue) => !!inputValue}
            showSkeleton={showSkeleton}
            validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
            triggerValidation={isNextButtonClicked}
          />
        )}
      </ParcelDetailsSearchInputsContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelDetailsDestination);
