import { SpinnerCircle, SpinnerOverlay } from './spinner.styles';

interface SpinnerProps {
  isFullScreen?: boolean;
  isSmall?: boolean;
}

const Spinner = ({ isFullScreen, isSmall }: SpinnerProps) => (
  <SpinnerOverlay
    $isFullScreen={isFullScreen}
    $isSmall={isSmall}>
    <SpinnerCircle />
  </SpinnerOverlay>
);

export default Spinner;
