import { useEffect, useState } from 'react';
import { DeliveryDetailsFormValues } from '../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import { User } from '../models/user';
import { Address } from '../models/parcelDeliveryMiles';

interface UseAddressInitializationProps {
  isUserSender: boolean;
  isUserReceiver: boolean;
  isHomeAddress: boolean;
  shipmentAddress?: Address;
  accountUser: User | null;
  initialCountry: string;
  isHideFields: boolean;
}

// Cases for initial values (empty, pre-filled):

// empty:
// home + empty user settings
// home + post codes not equal

// pre-filled:
// post codes equal
// non-home

// home
// -empty:
// accountUser != shipmentUser

// -pre-filled:
// accountUser == shipmentUser

// non-home

const useDeliveryDetailsInitialization = ({
  isUserSender,
  isUserReceiver,
  isHomeAddress,
  shipmentAddress,
  accountUser,
  initialCountry,
  isHideFields,
}: UseAddressInitializationProps) => {
  const [initialValues, setInitialValues] = useState<DeliveryDetailsFormValues>(
    !isHomeAddress
      ? {
          country: initialCountry,
          region: shipmentAddress?.region || '',
          city: shipmentAddress?.city || '',
          post_code: shipmentAddress?.post_code || '',
          street: shipmentAddress?.street || '',
          building: shipmentAddress?.building || '',
          section: shipmentAddress?.section || '',
          apartment: shipmentAddress?.apartment || '',
          buzz_code: shipmentAddress?.buzz_code || '',
          note: shipmentAddress?.note || '',
          full_address: shipmentAddress?.full_address,
        }
      : {
          country: initialCountry,
          region: '',
          city: '',
          post_code: '',
          street: '',
          building: '',
          section: '',
          apartment: '',
          buzz_code: '',
          note: '',
          full_address: '',
        }
  );

  useEffect(() => {
    if (isUserSender) {
      if (shipmentAddress?.post_code === accountUser?.address?.post_code) {
        setInitialValues({
          country: initialCountry,
          region: accountUser?.address?.region || '',
          city: accountUser?.address?.city || '',
          post_code: accountUser?.address?.post_code || '',
          street: accountUser?.address?.street || '',
          building: accountUser?.address?.building || '',
          section: accountUser?.address?.section || '',
          apartment: accountUser?.address?.apartment || '',
          buzz_code: accountUser?.address?.buzz_code || '',
          note: accountUser?.address?.note || '',
          full_address: accountUser?.address?.full_address,
        });
      }
    }

    if (isUserReceiver) {
      const isDefaultsEmpty = isHideFields && !shipmentAddress?.street;

      setInitialValues({
        country: initialCountry,
        region: isDefaultsEmpty ? '' : shipmentAddress?.region ?? '',
        city: isDefaultsEmpty ? '' : shipmentAddress?.city ?? '',
        post_code: isDefaultsEmpty ? '' : shipmentAddress?.post_code ?? '',
        street: shipmentAddress?.street ?? '',
        building: shipmentAddress?.building ?? '',
        section: shipmentAddress?.section ?? '',
        apartment: shipmentAddress?.apartment ?? '',
        buzz_code: shipmentAddress?.buzz_code ?? '',
        note: shipmentAddress?.note ?? '',
        full_address: shipmentAddress?.full_address ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentAddress]);

  return { initialValues };
};

export default useDeliveryDetailsInitialization;
