import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  ParcelItemsManagementContainer,
} from './parcel-items-management.styles';
import { useStore } from '../../../stores/store';
import { HsCode } from '../../../models/hsCode';
import ParcelItem from './parcel-item.component';
import Button from '../../../common/button/button.component';
import ParcelItemEditForm from './parcel-item-form.component';
import ParcelProhibitedItemsWithSidebar from '../parcel-prohibited-items-sidebar/parcel-prohibited-items-sidebar.component';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';
import { isMeest } from '../../../utils/client/client.utils';
import { client } from '../../../config';

interface FormValue {
  hsCode?: HsCode;
  category?: string;
  quantity?: number;
  declaredValue?: number;
}

const ParcelItemsManagement = () => {
  const {
    parcelCreationStore: {
      shipment,
      deleteItemInShipment,
      addItemToShipment,
      modifyItemInShipment,
      isLoadingShipmentApi,
    },
    localizationsStore: { isLanguageChanging },
    userStore: { getCurrencySymbol },
  } = useStore();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const [itemInEditMode, setItemInEditMode] = useState<HsCode | undefined>();
  const [nextItem, setNextItem] = useState<HsCode | undefined>();
  const [addingNewItemMode, setAddingNewItemMode] = useState(false);

  const numOfItemsInShipment = shipment?.items?.length ?? 0;
  const handleCancel = () => {
    setItemInEditMode(undefined);
    setAddingNewItemMode(false);
  };

  const initialValues: FormValue = {
    hsCode: itemInEditMode,
    category: itemInEditMode?.description_standart ?? '',
    quantity: itemInEditMode?.number_of_items ?? 1,
    declaredValue: itemInEditMode?.value,
  };

  const handleFormSubmit = async (
    formValues: FormValue,
    formikHelpers: FormikHelpers<FormValue>
  ) => {
    const newParcelItem = {
      ...formValues.hsCode,
      number_of_items: formValues.quantity,
      value: formValues.declaredValue!,
    } as HsCode;

    if (itemInEditMode) {
      // take existing id, which is required for update operation
      newParcelItem.id = itemInEditMode.id;
      await modifyItemInShipment(newParcelItem!);
      setItemInEditMode(undefined);
      setNextItem(undefined);
    } else {
      await addItemToShipment(newParcelItem!);
      handleCancel();
    }

    formikHelpers.resetForm();
  };

  const pracelItemValidationSchema = Yup.object({
    category: Yup.string().required().min(3),
    quantity: Yup.number()
      .min(1, t('only_numbers'))
      .max(1000000, t('only_numbers')) // Will be later replaced with maxQuantity from backend
      .required(t('this_field_cannot_be_empty'))
      .integer(t('only_numbers'))
      .typeError(t('only_numbers')),
    declaredValue: Yup.number()
      .min(0.01, t('this_field_cannot_be_empty'))
      .required(t('this_field_cannot_be_empty'))
      .max(10 ** 8 - 0.1, t('only_numbers'))
      .typeError(t('only_numbers')),
  });

  const handleCloseEditItem = () => {
    closeModal(Modals.QUIT_EDIT_ITEM);
    setItemInEditMode(nextItem);

    if (!nextItem) {
      setAddingNewItemMode(true);
    }
  };

  const showQuitEditItemDialog = () => {
    openModal({
      id: Modals.QUIT_EDIT_ITEM,
      name: Modals.QUIT_EDIT_ITEM,
      props: {
        onApprove: handleCloseEditItem,
      },
    });
  };

  return (
    <ParcelItemsManagementContainer>
      <ServicesSubSectionHeader>{t('parcel_content')}</ServicesSubSectionHeader>
      <Content>
        <ParcelProhibitedItemsWithSidebar />
        <Formik
          enableReinitialize
          validationSchema={pracelItemValidationSchema}
          initialValues={initialValues}
          onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
          {({ values, resetForm }) => {
            const hasValuesChanged =
              JSON.stringify(values) !== JSON.stringify(initialValues);
            return (
              <>
                {shipment?.items
                  ?.slice()
                  .sort((a, b) => a.id - b.id)
                  .map((item, i) => (
                    <ParcelItem
                      shouldShowDeleteButton={numOfItemsInShipment > 1}
                      itemInEditModeId={itemInEditMode?.id}
                      currencySymbol={getCurrencySymbol(
                        shipment?.converted_price?.currency
                      )}
                      key={item.id}
                      item={item}
                      withSeparator={
                        i + 1 < shipment!.items!.length ||
                        !!itemInEditMode ||
                        (addingNewItemMode && numOfItemsInShipment > 0)
                      }
                      onCancel={handleCancel}
                      onEdit={() => {
                        if (item.id === itemInEditMode?.id) return;
                        if (hasValuesChanged) {
                          setNextItem(item);
                          showQuitEditItemDialog();
                          return;
                        }

                        setAddingNewItemMode(false);
                        setItemInEditMode(item);
                      }}
                      onDelete={() => {
                        const { id } = item;
                        deleteItemInShipment(item.id).then(() => {
                          if (id === itemInEditMode?.id) {
                            setItemInEditMode(undefined);
                          }
                        });
                      }}
                    />
                  ))}

                {numOfItemsInShipment > 0 && !addingNewItemMode && (
                  <Button
                    appearance={isMeest(client) ? 'link' : 'text-danger'}
                    hideLinkUnderline
                    onClick={() => {
                      if (hasValuesChanged && itemInEditMode !== undefined) {
                        setNextItem(undefined);
                        showQuitEditItemDialog();
                        return;
                      }
                      resetForm();
                      setItemInEditMode(undefined);
                      setAddingNewItemMode(true);
                    }}
                    icon={{ glyph: 'plus' }}
                    disabled={isLoadingShipmentApi || isLanguageChanging}>
                    {t('add_new_item')}
                  </Button>
                )}

                {(numOfItemsInShipment === 0 || addingNewItemMode) && (
                  <ParcelItemEditForm
                    parcelItem={itemInEditMode}
                    onClose={handleCancel}
                    displayCancelButton={numOfItemsInShipment > 0}
                    currencySymbol={getCurrencySymbol(
                      shipment?.converted_price?.currency
                    )}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </Content>
    </ParcelItemsManagementContainer>
  );
};

export default observer(ParcelItemsManagement);
