import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button from '../../../common/button/button.component';
import { HsCode } from '../../../models/hsCode';
import {
  FormBtnsBlock,
  FormInputs,
  ParcelItemEditFormContainer,
} from './parcel-items-management.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import ItemCategorySearch from './parcel-item-category-search.component';
import { transformToDecimal } from '../../../utils/forms/forms.utils';
import InputMessage from '../../../common/input-message/input-message.component';
import { isAnyValueEmpty } from '../../../utils/generic/generic.utils';
import { useStore } from '../../../stores/store';

interface Props {
  parcelItem?: HsCode;
  displayCancelButton: boolean;
  currencySymbol: string;
  onClose: () => void;
}

const ParcelItemEditForm = ({
  parcelItem,
  displayCancelButton,
  currencySymbol,
  onClose,
}: Props) => {
  const {
    parcelCreationStore: {
      setIsNextButtonClicked,
      isNextButtonClicked,
      shipment,
    },
  } = useStore();

  const { isSubmitting, dirty, isValid, handleSubmit, setFieldValue, values } =
    useFormikContext();

  const { t } = useTranslation();

  const isAllFieldsProvided = !isAnyValueEmpty(values);

  const triggerFieldValidation =
    isNextButtonClicked && !shipment?.items?.length;
  const showSaveChangesError = triggerFieldValidation && isAllFieldsProvided;

  return (
    <ParcelItemEditFormContainer>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        <FormInputs>
          <ItemCategorySearch />

          <FormFieldText
            name='quantity'
            placeholder='1'
            label={t('quantity')}
            inputMode='numeric'
            onChange={(event) => {
              const val = event.target.value.replace(/\D/g, '');
              setFieldValue(event.target.name, val);
              setIsNextButtonClicked(false);
            }}
            triggerValidation={triggerFieldValidation}
          />

          <FormFieldText
            name='declaredValue'
            placeholder=''
            label={t('declared_value')}
            infoText={currencySymbol}
            inputMode='decimal'
            onChange={(event) => {
              setFieldValue(
                event.target.name,
                transformToDecimal(event.target.value)
              );
              setIsNextButtonClicked(false);
            }}
            triggerValidation={triggerFieldValidation}
          />
          {showSaveChangesError && (
            <InputMessage type='error'>{t('please_save_changes')}</InputMessage>
          )}
        </FormInputs>

        <FormBtnsBlock $editMode={displayCancelButton}>
          {displayCancelButton && (
            <Button
              appearance='secondary'
              onClick={onClose}>
              {t('cancel')}
            </Button>
          )}
          <Button
            type='submit'
            disabled={!dirty || !isValid}
            isLoading={isSubmitting}>
            {!parcelItem ? t('add') : t('save_changes')}
          </Button>
        </FormBtnsBlock>
      </form>
    </ParcelItemEditFormContainer>
  );
};

export default observer(ParcelItemEditForm);
