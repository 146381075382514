import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import { Modals } from '../../../constants/modals';
import SignatureDialog from '../../parcel-creation/signature-dialog/signature-dialog.component';

interface Props {
  onClose: () => void;
  handleCreateParcel: () => void;
}

const SignatureModal = ({ onClose, handleCreateParcel }: Props) => {
  const {
    parcelListStore: { setActiveManualId },
    modalStore: { isModalVisible },
  } = useStore();

  const handleClose = () => {
    onClose();
    setActiveManualId(undefined);
  };

  return (
    <SignatureDialog
      isOpen={isModalVisible(Modals.SIGNATURE)}
      onClose={handleClose}
      handleCreateParcel={handleCreateParcel}
    />
  );
};

export default observer(SignatureModal);
