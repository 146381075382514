export const Modals = {
  PARCEL_MANUAL: 'parcelManual',
  ONBOARDING_MANUAL: 'onboardingManual',
  CREATE_ACCOUNT: 'createAccount',
  DELETE_ACCOUNT: 'deleteAccount',
  LEAVE_THIS_PAGE: 'leaveThisPage',
  DELETE_ITEM: 'deleteItem',
  QUIT_EDIT_ITEM: 'quitEditItem',
  ALLOW_LOCATION_ACCESS: 'allowLocationAccess',
  CODE_GENERATION: 'codeGeneration',
  PAYMENT: 'payment',
  LEAVE_FEEDBACK: 'leaveFeedback',
  NO_DELIVERY_OPTION: 'noDeliveryOptionModal',
  SIGNATURE: 'signature',
} as const;
