import styled from 'styled-components';
import { SidebarInner } from '../sidebar-right/sidebar.styles';
import { scrollStyles } from '../scroll/scroll.styles';

export const Container = styled(SidebarInner)`
  display: grid;
  gap: 1.6rem;
  grid-template-rows: auto 1fr;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  ${scrollStyles};
`;
