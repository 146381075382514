import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from '../../../../common/flex-container/flex-container.styles';
import {
  HeaderSecondary,
  TextBody,
} from '../../../../common/typography/typography.styles';
import { FavoriteButton } from '../../parcel-list-card/parcel-list-card.styles';
import {
  DeliveryStatusHeader,
  DeliveryStatusMore,
  DeliveryStatusAccordionTrigger,
  DeliveryStatuses,
  DeliveryStatusItem,
  Section,
  CodeGenerationButtonsContainer,
} from '../details-sidebar.styles';
import { ReactComponent as StarIcon } from '../../../../assets/star.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/chevron-down.svg';
import MoreDropdown from '../../more-dropdown/more-dropdown.component';
import MoreSidebar from '../../more-sidebar/more-sidebar.component';
import {
  ICardActionItem,
  getFavoriteLabel,
  getStatusBadgeConfig,
} from '../../../../routes/parcel-list/config';
import { Shipment } from '../../../../models/parcelDeliveryMiles';
import Tag from '../../../../common/tag/tag.component';
import { formatDate } from '../../../../utils/generic/generic.utils';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../../../common/tooltip/tooltip.component';
import Button from '../../../../common/button/button.component';
import { useModal } from '../../../../common/modal';
import { Modals } from '../../../../constants/modals';
import { MarginContainer } from '../../../../common/base-margin/base-margin.component';
import Sidebar from '../../../../common/sidebar-right/sidebar.component';
import { useStore } from '../../../../stores/store';
import ParcelHistory from './parcel-history/parcel-history.component';
import {
  isParcelReadyForPickup,
  isParcelSent,
  normalizeHistory,
} from './parcel-history/parcel-history.helpers';
import { SidebarName } from '../../../../types/sidebar.types';
import { client } from '../../../../config';
import { isMeest } from '../../../../utils/client/client.utils';

interface Props {
  menuItems: ICardActionItem[];
  shipment: Shipment;
  onToggleFavorite: (value: boolean) => void;
  isGuestUser?: boolean;
}

const sidebarFullHistory: SidebarName = 'full_history';

const DeliveryStatus = ({
  menuItems,
  shipment,
  onToggleFavorite,
  isGuestUser = false,
}: Props) => {
  const {
    id: shipmentId,
    is_favorite: isFavorite,
    tracking_number: trackingNumber,
    status,
    status_history: statusHistory,
    delivery_at: deliveryDateTime,
    tracking_numbers: { first_mile: firstMile },
  } = shipment;

  const { t } = useTranslation();
  const { openModal } = useModal();

  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const [isDeliveryStatusOpen, setIsDeliveryStatusOpen] = useState(false);

  const handleButtonClick = (content: 'qrcode' | 'barcode') => {
    openModal({
      id: Modals.CODE_GENERATION,
      name: Modals.CODE_GENERATION,
      props: {
        modalContent: content,
        firstMile,
      },
    });
  };

  const favoritesLabel = getFavoriteLabel(isFavorite);
  const statusConfig = getStatusBadgeConfig(status);

  const shouldShowCodeGenerationButtons =
    status !== 'created' && status !== 'draft' && firstMile.length !== 0;

  // Required to fill ready_to_pickup history entry with temp data (default text and delivery_at)
  const normalizedStatusHistory =
    isParcelSent(statusHistory) && !isParcelReadyForPickup(statusHistory)
      ? normalizeHistory(statusHistory, deliveryDateTime)
      : statusHistory;

  return (
    <Section>
      <DeliveryStatusHeader>
        <FlexContainer
          $gap='4px'
          $align='center'>
          {!isGuestUser && (
            <FavoriteButton
              onClick={() => onToggleFavorite(!isFavorite)}
              $isFavorite={isFavorite}
              aria-label={t(favoritesLabel)}>
              <Tooltip placement='bottom-start'>
                <TooltipTrigger>
                  <StarIcon />
                </TooltipTrigger>
                <TooltipContent>{t(favoritesLabel)}</TooltipContent>
              </Tooltip>
            </FavoriteButton>
          )}
          <HeaderSecondary $size='medium'>{trackingNumber}</HeaderSecondary>
        </FlexContainer>

        {!isGuestUser && (
          <DeliveryStatusMore>
            <MoreDropdown items={menuItems} />
            <MoreSidebar
              id={shipmentId}
              items={menuItems}
            />
          </DeliveryStatusMore>
        )}
      </DeliveryStatusHeader>
      <FlexContainer>
        <DeliveryStatusAccordionTrigger
          id='delivery-status-trigger'
          aria-controls='delivery-statuses'
          aria-expanded={isDeliveryStatusOpen}
          $isActive={isDeliveryStatusOpen}
          onClick={() => {
            status !== 'draft' && setIsDeliveryStatusOpen((prev) => !prev);
          }}>
          <TextBody>{t('delivery_status')}</TextBody>
          {status !== 'draft' && <ChevronDownIcon />}
        </DeliveryStatusAccordionTrigger>
        <Tag
          size='small'
          type={statusConfig.type || 'semi-filled'}
          color={statusConfig.color}>
          {t(statusConfig.text)}
        </Tag>
      </FlexContainer>
      {isDeliveryStatusOpen && (
        <>
          <DeliveryStatuses
            id='delivery-statuses'
            aria-labelledby='delivery-status-trigger'
            $align='stretch'
            $direction='column'
            $gap='8px'>
            {normalizedStatusHistory?.map((item) => {
              const isActive = status === item.status;
              const fontWeight = isActive ? 500 : 400;
              return (
                item.created_at && (
                  <DeliveryStatusItem
                    key={item.status}
                    $isActive={isActive}
                    $gap='4px'
                    $justify='space-between'>
                    <TextBody
                      $fontWeight={fontWeight}
                      $color='currentColor'>
                      {t(getStatusBadgeConfig(item.status).text)}
                    </TextBody>{' '}
                    <TextBody
                      $fontWeight={fontWeight}
                      $color='currentColor'
                      as='time'>
                      {formatDate(item.created_at, true)}
                    </TextBody>
                  </DeliveryStatusItem>
                )
              );
            })}
          </DeliveryStatuses>
          <MarginContainer $mt='1.2rem'>
            <Button
              appearance={isMeest(client) ? 'link' : 'tertiary'}
              contentStyle={isMeest(client) ? 'thin' : 'normal'}
              size='small'
              onClick={() => toggleSidebarByName(sidebarFullHistory)}>
              {t('parcel_details_full_history')}
            </Button>
          </MarginContainer>
        </>
      )}
      {shouldShowCodeGenerationButtons && (
        <CodeGenerationButtonsContainer>
          <Button
            size='large'
            icon={{ glyph: 'bar-code' }}
            onClick={() => handleButtonClick('barcode')}
            appearance='default'>
            Bar code
          </Button>
          <Button
            size='large'
            icon={{ glyph: 'qr-code' }}
            onClick={() => handleButtonClick('qrcode')}
            appearance='default'>
            QR code
          </Button>
        </CodeGenerationButtonsContainer>
      )}
      <Sidebar
        name={sidebarFullHistory}
        sidebarWidth='64rem'
        withoutHeader
        contentWithVerticalScroll>
        <Button
          fullWidth={false}
          icon={{ glyph: 'chevron-left' }}
          $pl='0'
          $mb='3.2rem'
          $mbMobile='2.4rem'
          size='large'
          appearance={isMeest(client) ? 'text' : 'tertiary'}
          onClick={(e) => {
            e.stopPropagation();
            toggleSidebarByName(sidebarFullHistory);
          }}>
          {t('back')}
        </Button>
        <ParcelHistory
          currentStatus={status}
          statusHistory={normalizedStatusHistory}
        />
      </Sidebar>
    </Section>
  );
};

export default DeliveryStatus;
