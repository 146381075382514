import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { PudoFilterContainer } from './pudo-filter.styles';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import { useStore } from '../../../stores/store';
import { ScrollableContent } from '../../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import 'rc-slider/assets/index.css';
import PudoFilterContent from './pudo-filter-content.component';
import BottomSidebar from '../../../common/sidebar-bottom/bottom-sidebar.component';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';

const rightSidebarName = 'pudo_filters_sidebar';
const btmSidebarName = 'btm_pudo_filters_sidebar';

const PudoFilter = () => {
  const {
    navStore: {
      toggleSidebarByName,
      setPudoFiltersOpened,
      isPudoFiltersOpened,
    },
  } = useStore();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isPudoFiltersOpened) {
      toggleSidebarByName(!isMobile ? rightSidebarName : btmSidebarName);
    }
    // eslint-disable-next-line
  }, [isPudoFiltersOpened]);

  return (
    <PudoFilterContainer
      onClick={(e) => {
        e.stopPropagation();
      }}>
      {!isMobile && (
        <Sidebar
          name={rightSidebarName}
          noBodyPadding
          withoutHeader>
          <ScrollableContent
            $heightOfAboveContent='6.5rem'
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <PudoFilterContent
              onClose={() => {
                toggleSidebarByName(rightSidebarName);
                setPudoFiltersOpened(false);
              }}
            />
          </ScrollableContent>
        </Sidebar>
      )}
      {isMobile && (
        <BottomSidebar
          name={btmSidebarName}
          height='63.5rem'
          withBlurredBackground
          onClose={() => setPudoFiltersOpened(false)}>
          <PudoFilterContent
            onClose={() => {
              toggleSidebarByName(btmSidebarName);
              setPudoFiltersOpened(false);
            }}
          />
        </BottomSidebar>
      )}
    </PudoFilterContainer>
  );
};

export default observer(PudoFilter);
