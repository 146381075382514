import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useCallback } from 'react';
import { WithSelectionCallback } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import { useStore } from '../../../stores/store';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import { ScrollableContent } from '../../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import ParcelListOption from './parcel-list-option.component';
import { MarginContainer } from '../../../common/base-margin/base-margin.component';
import Spinner from '../../../common/spinner/spinner.component';
import useScrollPagination from '../../../hooks/use-scroll-pagination.hook';
import { IOptionsPages } from '../../../models/paginationOptions';

const ParcelListOptions = ({
  onSelect,
}: WithSelectionCallback<ParcelListShipment>) => {
  const {
    parcelListStore: {
      parcels,
      options,
      fetchParcels,
      setParcels,
      isLoadingShipmentApi,
    },
  } = useStore();

  useEffect(() => {
    setParcels(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWithAbortController = useCallback(
    async (
      fetchOptions: IOptionsPages | undefined,
      append: boolean = false
    ) => {
      const abortController = new AbortController();
      await fetchParcels(fetchOptions, abortController.signal, true, append);
    },
    [fetchParcels]
  );

  useEffect(() => {
    setParcels(undefined);
    fetchWithAbortController(undefined, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  useScrollPagination(
    options,
    containerRef,
    'parcel',
    'container',
    fetchWithAbortController
  );

  return (
    <ScrollableContent
      $heightOfAboveContentMobile='13.6rem'
      ref={containerRef}>
      {parcels?.map((parcel) => (
        <ParcelListOption
          key={parcel.id}
          parcel={parcel}
          onSelect={onSelect}
        />
      ))}
      {isLoadingShipmentApi && (
        <MarginContainer $mb='1rem'>
          <Spinner isSmall />
        </MarginContainer>
      )}
    </ScrollableContent>
  );
};

export default observer(ParcelListOptions);
