import { useCallback, useRef } from 'react';
import { useStore } from '../stores/store';
import { executeWithControlledInterval } from '../utils/generic/generic.utils';
import useTabVisibility from './use-tab-visility.hook';

const usePaymentStatusUpdater = () => {
  const {
    parcelListStore: {
      tryUpdateShipmentPaymentStatus,
      parcelsInPaymentProgress,
      removeParcelFromPaymentInProgress,
    },
  } = useStore();

  const parcelsAlreadyInUpdate = useRef<number[]>([]);

  const removeParcelFromUpdateCycle = (id: number) => {
    removeParcelFromPaymentInProgress(id);
    parcelsAlreadyInUpdate.current = parcelsAlreadyInUpdate.current.filter(
      (pId) => pId !== id
    );
  };

  const tryUpdateParcels = useCallback(() => {
    if (parcelsInPaymentProgress.length === 0) return;

    parcelsInPaymentProgress
      .filter((id) => !parcelsAlreadyInUpdate.current.includes(id))
      .forEach((id) => {
        parcelsAlreadyInUpdate.current.push(id);
        executeWithControlledInterval(
          () => tryUpdateShipmentPaymentStatus(id),
          1000,
          3000,
          () => removeParcelFromUpdateCycle(id),
          () => removeParcelFromUpdateCycle(id)
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelsInPaymentProgress]);

  useTabVisibility({ onVisible: tryUpdateParcels });
};

export default usePaymentStatusUpdater;
