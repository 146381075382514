import styled, { css } from 'styled-components';
import { InputMessageContainer } from '../input-message/input-message.styles';

interface ContainerProps {
  $isLoading?: boolean;
}

interface WrapperProps {
  $isError?: boolean;
}

export const RecaptchaWrapper = styled.div<WrapperProps>`
  padding: ${({ $isError }) =>
    $isError ? '0.9rem 0.9rem 1.3rem 0.9rem;' : '0'};
  border: 1px solid
    ${({ theme, $isError }) =>
      $isError ? theme.colors.baseColors.red80 : 'transparent'};

  ${InputMessageContainer} {
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.3rem;
    letter-spacing: -0.5px;
  }
`;

export const Container = styled.div<ContainerProps>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      ${RecaptchaWrapper} {
        opacity: 0;
      }
    `}
`;
