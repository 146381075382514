import styled from 'styled-components';
import { ServicePageContainer } from '../../features/services/services-page-container/services-page-container.styles';
import { ServicesSubSectionContainer } from '../../features/services/services-subsection/services-subsection.styles';

export const PersonalDataContainer = styled(ServicePageContainer)`
  ${ServicesSubSectionContainer}:nth-child(4) {
    form .inputs {
      display: grid;
      width: 100%;
      row-gap: 2rem;
      grid-template-columns: 1fr;

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      > *:nth-child(-n + 6) {
        grid-column: 1 / -1;
      }

      > *:nth-child(n + 7) {
        grid-column: auto;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: 80% 20%;
        padding-right: 2.4rem;
      }

      > *:nth-last-child(2),
      > *:last-child {
        grid-column: span 1;
      }
    }
  }

  ${ServicesSubSectionContainer}:nth-child(3) {
    .location-inputs {
      display: flex;
      gap: 2.4rem;
      max-width: 724px;

      @media (max-width: 750px) {
        flex-direction: column;
        gap: 1.6rem;
      }
    }
  }
`;
