import { Loader } from '@googlemaps/js-api-loader';
import { createValidationRule } from '../forms/forms.utils';
import { VALIDATION_RULE_IS_NOT_CYRILLIC } from '../../constants/validation';
import { envConfig } from '../../config';

const loader = new Loader({
  apiKey: envConfig.REACT_APP_GOOGLE_MAP_API_KEY ?? '',
  version: 'weekly',
  libraries: ['places', 'geocoding'],
});

// eslint-disable-next-line import/no-mutable-exports
export let autocompleteService: google.maps.places.AutocompleteService;
// eslint-disable-next-line import/no-mutable-exports
export let geocoder: google.maps.Geocoder;

// eslint-disable-next-line unused-imports/no-unused-vars
let didInitPlacesLibs = false;

export const loadPlacesLibs = async () => {
  const importPlaces = loader.importLibrary('places');
  const importGeocoder = loader.importLibrary('geocoding');

  // Promise all to fix warning caused by loading 2 libs
  Promise.all([importPlaces, importGeocoder]).then(() => {
    autocompleteService = new google.maps.places.AutocompleteService();
    geocoder = new google.maps.Geocoder();
    didInitPlacesLibs = true;
  });
};

export const getGeocodingInfo = async (
  address?: string,
  placeId?: string
): Promise<google.maps.GeocoderResult | undefined> => {
  if (!address && !placeId) return;

  try {
    const request = {
      address,
      placeId,
      language: 'en',
    } as google.maps.GeocoderRequest;
    const { results } = await geocoder.geocode(request);

    if (results.length > 0) return results[0];
  } catch (error) {
    console.error(
      `Failed to retrieve geocoding info for address: ${address} and/or placeId=${placeId}`
    );
  }
};

export const getPredictions = async (
  value: string,
  predictionTypes: string[] = ['address'],
  countryConstraint?: string,
  cityName?: string
) => {
  if (!autocompleteService) return [];

  let autocompleteRequest: google.maps.places.AutocompletionRequest = {
    input: value,
    types: predictionTypes, // Specify the type of predictions you want (e.g., addresses)
    language: 'en', // store.localizationsStore.selectedLocalization.code, - for now ALWAYS USE EN
  };

  if (countryConstraint) {
    autocompleteRequest = {
      ...autocompleteRequest,
      componentRestrictions: {
        country: countryConstraint,
      },
    };
  }

  if (cityName) {
    const geocodeInfo = await getGeocodingInfo(cityName);
    if (geocodeInfo) {
      autocompleteRequest = {
        ...autocompleteRequest,
        locationRestriction: geocodeInfo.geometry.bounds,
      };
    }
  }

  return new Promise<google.maps.places.AutocompletePrediction[]>(
    (resolve, reject) => {
      autocompleteService.getPlacePredictions(
        autocompleteRequest,
        (receivedPredictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(receivedPredictions ?? []);
          } else if (
            status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
          ) {
            resolve([]);
          } else {
            reject(
              `Failed to get predictions for value: ${value} with status: ${status}`
            );
          }
        }
      );
    }
  );
};

export const getPlaceDetails = async (placeId: string) => {
  if (!geocoder) return null;

  try {
    const geocodeResults: google.maps.GeocoderResult[] = await new Promise(
      (resolve, reject) => {
        geocoder.geocode({ placeId, language: 'en' }, (results, status) => {
          if (status === 'OK') {
            resolve(results || []);
          } else {
            reject(
              `Failed to get place details for placeID: ${placeId} with status: ${status}`
            );
          }
        });
      }
    );

    if (geocodeResults && geocodeResults.length > 0) {
      const addressComponents = geocodeResults[0].address_components;

      const streetComponent = addressComponents.find((component) =>
        component.types.includes('route')
      );

      const postalCodeObj = addressComponents.find((component) =>
        component.types.includes('postal_code')
      );

      if (postalCodeObj || streetComponent) {
        return {
          address_components: addressComponents,
          formatted_address: geocodeResults[0].formatted_address,
          postal_code: postalCodeObj?.long_name || null,
          street: streetComponent?.long_name || null,
          location: geocodeResults[0].geometry.location,
        };
      }

      const location = {
        lat: geocodeResults[0].geometry.location.lat(),
        lng: geocodeResults[0].geometry.location.lng(),
      };

      const reverseGeocodeResults: google.maps.GeocoderResult[] =
        await new Promise((resolve, reject) => {
          geocoder.geocode({ location, language: 'en' }, (results, status) => {
            if (status === 'OK') {
              resolve(results || []);
            } else {
              reject(status);
            }
          });
        });

      if (reverseGeocodeResults && reverseGeocodeResults.length > 0) {
        const reverseAddressComponents =
          reverseGeocodeResults[0].address_components;

        const reverseStreetComponent = reverseAddressComponents.find(
          (component) => component.types.includes('route')
        );

        const reversePostalCodeObj = reverseAddressComponents.find(
          (component) => component.types.includes('postal_code')
        );

        if (reversePostalCodeObj || reverseStreetComponent) {
          return {
            address_components: reverseAddressComponents,
            formatted_address: reverseGeocodeResults[0].formatted_address,
            postal_code: reversePostalCodeObj?.long_name || null,
            street: reverseStreetComponent?.long_name || null,
            location: geocodeResults[0].geometry.location,
          };
        }
      } else {
        console.error('No results found for the given coordinates.');
      }
    } else {
      console.error('No results found for the given placeID.');
    }
  } catch (error) {
    console.error('Geocoder failed due to:', error);
  }

  return null;
};

export async function getAddressComponentsByZipCode(
  zipCode: string,
  countryCode: string,
  config: { includeCity?: boolean; includeRegion?: boolean }
): Promise<{ city?: string; region?: string } | undefined> {
  try {
    const response = await new Promise<any>((resolve, reject) => {
      geocoder.geocode(
        {
          componentRestrictions: {
            country: countryCode,
            postalCode: zipCode,
          },
          language: 'en',
        },
        (
          results: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus
        ) => {
          if (status === 'OK' && results !== null) {
            resolve(results);
          } else {
            reject(`Geocoder failed with status: ${status}`);
          }
        }
      );
    });

    const result: { city?: string; region?: string } = {};
    if (response.length > 0) {
      if (config.includeCity) {
        result.city = getCityFromPlaceDetails(response[0].address_components);
      }

      if (config.includeRegion) {
        result.region = getRegionFromPlaceDetails(
          response[0].address_components
        );
      }

      return result;
    }
  } catch (error) {
    console.error('Error fetching region by zip code:', error);
    return undefined;
  }
}

export async function getCityByZipCode(
  zipCode: string,
  countryCode: string
): Promise<string | undefined> {
  return (
    await getAddressComponentsByZipCode(zipCode, countryCode, {
      includeCity: true,
    })
  )?.city;
}

export async function getRegionByZipCode(
  zipCode: string,
  countryCode: string
): Promise<string | undefined> {
  return (
    await getAddressComponentsByZipCode(zipCode, countryCode, {
      includeRegion: true,
    })
  )?.region;
}

export async function getCityAndRegionByZipCode(
  zipCode: string,
  countryCode: string
): Promise<{ city?: string; region?: string } | undefined> {
  const res = await getAddressComponentsByZipCode(zipCode, countryCode, {
    includeCity: true,
    includeRegion: true,
  });

  return res;
}

export function getCityFromPlaceDetails(
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) {
  if (!address_components) return '';

  let cityComp = address_components.find(
    (comp) =>
      comp.types.includes('political') && comp.types.includes('locality')
  );
  if (!cityComp) {
    cityComp = address_components.find(
      (comp) =>
        comp.types.includes('sublocality_level_1') &&
        comp.types.includes('sublocality') &&
        comp.types.includes('political')
    );
  }

  if (!cityComp) {
    cityComp = address_components.find(
      (comp) =>
        comp.types.includes('neighborhood') && comp.types.includes('political')
    );
  }

  if (!cityComp) return '';
  return cityComp.long_name;
}

export const getCountryFromPlaceDetails = (
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) => {
  if (!address_components) return '';

  const cityComp = address_components.find(
    (comp) => comp.types.includes('political') && comp.types.includes('country')
  );

  if (!cityComp) return '';
  return cityComp.short_name;
};

export const getStreetFromPlaceDetails = (
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) => {
  if (!address_components) return '';

  const cityComp = address_components.find((comp) =>
    comp.types.includes('route')
  );

  if (!cityComp) return '';
  return cityComp.long_name;
};

export const getZipCodeFromPlaceDetails = (
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) => {
  if (!address_components) return '';

  const cityComp = address_components.find((comp) =>
    comp.types.includes('postal_code')
  );

  if (!cityComp) return '';
  return cityComp.long_name;
};

export const getBuildingFromPlaceDetails = (
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) => {
  if (!address_components) return '';

  const cityComp = address_components.find((comp) =>
    comp.types.includes('street_number')
  );

  if (!cityComp) return '';
  return cityComp.long_name;
};

export const getApartmentFromPlaceDetails = (
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) => {
  if (!address_components) return '';

  const cityComp = address_components.find((comp) =>
    comp.types.includes('subpremise')
  );

  if (!cityComp) return '';
  return cityComp.long_name.split(' ')[1] ?? '';
};

export function getRegionFromPlaceDetails(
  address_components: google.maps.GeocoderAddressComponent[] | undefined
) {
  if (!address_components) return '';

  let regionComp = address_components.find((comp) =>
    comp.types.includes('administrative_area_level_1')
  );

  if (!regionComp) {
    regionComp = address_components.find((comp) =>
      comp.types.includes('administrative_area_level_2')
    );
  }

  if (!regionComp) return '';
  return regionComp.long_name;
}

export const tryGetBuildingFromPrediction = (
  prediction: google.maps.places.AutocompletePrediction | null,
  buildingFromDetails?: string
) => {
  if (!prediction || !buildingFromDetails) return '';

  const buildingFromDescription = prediction.description
    .split(', ')
    .find((di) => di === buildingFromDetails);

  return buildingFromDescription &&
    buildingFromDescription !== '' &&
    buildingFromDescription !== buildingFromDetails
    ? buildingFromDescription
    : buildingFromDetails;
};

export const getPredictionsWithUserInput = (
  predictions: google.maps.places.AutocompletePrediction[],
  filter: string | undefined,
  minLength: number
): google.maps.places.AutocompletePrediction[] => {
  if (
    predictions.length === 0 &&
    filter &&
    filter.length >= minLength &&
    createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC).isValidSync(filter)
  ) {
    return [
      {
        description: filter,
        place_id: filter,
      } as google.maps.places.AutocompletePrediction,
    ];
  }

  return predictions;
};
