/* eslint-disable react/no-array-index-key */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useStore } from '../../../stores/store';

import { ReactComponent as MoneyIcon } from '../../../assets/money.svg';
import InfoSelectWithSidebar from '../../../common/info-select-with-sidebar/info-select-with-sidebar.component';
import InputMessage from '../../../common/input-message/input-message.component';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import {
  Content,
  Main,
  TextBlock,
  TextItem,
  TextItems,
  TextItemStyle,
  TextNotice,
} from './taxes-sidebar.styles';
import { SpinnerCircle } from '../../../common/spinner/spinner.styles';

const TaxesSidebar = () => {
  const {
    userStore: { user },
    parcelCreationStore: {
      getTaxes,
      taxes,
      isLoadingTaxes,
      countryDestination,
    },
    localizationsStore: { isLanguageChanging },
  } = useStore();

  const { t } = useTranslation();

  const isDestinationUA = countryDestination?.countryCode === 'UA';

  const handleInputClick = () => {
    window.open(
      'https://us.meest.com/services/service/from-us-to-international',
      '_blank',
      'noopener,noreferrer'
    );
  };

  useEffect(() => {
    getTaxes();
  }, [getTaxes, user?.lang]);

  return (
    <InfoSelectWithSidebar
      onInputClick={!isDestinationUA ? handleInputClick : undefined}
      theme='blue'
      icon={<MoneyIcon />}
      sidebarName='customs_and_duty'
      sidebarTitle={t('taxes_customs')}
      placeholder={t('taxes_customs')}
      disableSidebar={!isDestinationUA}>
      <Main $isEmpty={!taxes}>
        {isLoadingTaxes && <SpinnerCircle />}
        {taxes && (
          <>
            {taxes.main_block.map(({ title, content }, outerIndex) => (
              <ServicesSubSectionContainer key={outerIndex}>
                <ServicesSubSectionHeader>{title}</ServicesSubSectionHeader>
                <Content>
                  {content.map(
                    ({ texts, notice, general_style: generalStyle }, index) => {
                      const isOnlyNotice = notice && texts.length === 0;

                      return (
                        <TextBlock
                          $isOnlyNotice={!!isOnlyNotice}
                          key={index}>
                          {texts.length > 0 && (
                            <TextItems $generalStyle={generalStyle[0]}>
                              {texts.map(
                                (
                                  { content: innerContent, style },
                                  innerIndex
                                ) => (
                                  <TextItem
                                    key={innerIndex}
                                    $style={style[0] as TextItemStyle}>
                                    {innerContent}
                                  </TextItem>
                                )
                              )}
                            </TextItems>
                          )}
                          {notice && <TextNotice>{notice}</TextNotice>}
                        </TextBlock>
                      );
                    }
                  )}
                </Content>
              </ServicesSubSectionContainer>
            ))}
            {taxes.general_notice && (
              <InputMessage size='medium'>{taxes.general_notice}</InputMessage>
            )}
          </>
        )}
      </Main>
    </InfoSelectWithSidebar>
  );
};

export default observer(TaxesSidebar);
