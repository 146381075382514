import { FC, RefObject, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ItemsAmountToShow,
  Pages,
  PagesItem,
  Pagination,
  PaginationArrowContainer,
  PaginationArrowContainerReverse,
  PaginationContainer,
} from './pagination.styles';
import Select, { IDropdownItem } from '../select/select.component';
import {
  IPerPageItem,
  PerPageCountVariants,
} from '../../routes/parcel-list/config';
import { ReactComponent as DoubleArrowIcon } from '../../assets/double-arrow-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-pagination.svg';
import { IOptionsPages } from '../../models/paginationOptions';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import useScrollPagination from '../../hooks/use-scroll-pagination.hook';

interface IProps {
  options: IOptionsPages;
  onPageChange: (page: number) => void;
  fetchItems: (options: IOptionsPages, append?: boolean) => Promise<void>;
  initialPerPageItem?: IDropdownItem<string | number>;
  containerRef: RefObject<HTMLElement>;
  listType: 'parcel' | 'receiver';
  onPerPageChange: (perPage: number) => void;
}

const PaginationComponent: FC<IProps> = ({
  options,
  fetchItems,
  onPageChange,
  initialPerPageItem,
  containerRef,
  listType,
  onPerPageChange,
}) => {
  const isMobile = useIsMobile();

  useLayoutEffect(() => {
    if (!isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isMobile, options.page]);

  const { t } = useTranslation();

  useScrollPagination(options, containerRef, listType, 'window', fetchItems);

  const moveNextPage = async () => {
    const currentPage = options.page ?? 1;
    const totalPages = options.totalPagesCount ?? 1;

    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      await fetchItems({ ...options, page: currentPage + 1 });
    }
  };

  const movePrevPage = async () => {
    const currentPage = options.page ?? 1;

    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      await fetchItems({ ...options, page: currentPage - 1 });
    }
  };

  const handlePerPageChange = async (item: IPerPageItem) => {
    const updatedOptions = { ...options, perPage: item.value, page: 1 };
    await fetchItems(updatedOptions);
    onPerPageChange(Number(item.value));
  };
  const openFirstPage = async () => {
    const currentPage = options.page ?? 1;

    if (currentPage > 1) {
      onPageChange(1);
      await fetchItems({ ...options, page: 1 });
    }
  };

  const openLastPage = async () => {
    const currentPage = options.page ?? 1;
    const totalPages = options.totalPagesCount ?? 1;

    if (currentPage < totalPages) {
      onPageChange(totalPages);
      await fetchItems({ ...options, page: totalPages });
    }
  };

  const totalPages = options.totalPagesCount ?? 1;
  const totalCount = options.totalElCount ?? 1;
  const currentPage = options.page ?? 1;
  const maxPagesToShow = 3;

  const startIndex = ((options.page ?? 1) - 1) * (options.perPage ?? 1) + 1;
  const endIndex = Math.min(
    (options.page ?? 1) * (options.perPage ?? 1),
    totalCount
  );

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }

  const handlePageChange = async (pageNumber: number) => {
    const currPage = options.page ?? 1;

    if (!Number.isNaN(pageNumber) && currPage !== pageNumber) {
      onPageChange(pageNumber);
      await fetchItems({ ...options, page: pageNumber });
    }
  };

  const pages: Array<number | string> = [];

  pages.push(
    ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  );

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    pages.push(totalPages);
  }

  return (
    <PaginationContainer>
      <Pages>
        {startIndex}-{endIndex} of {totalCount}
      </Pages>
      <Pagination>
        <PaginationArrowContainerReverse $currentPage={currentPage > 1}>
          <ArrowIcon onClick={movePrevPage} />
          <DoubleArrowIcon onClick={openFirstPage} />
        </PaginationArrowContainerReverse>
        {pages.map((page) => (
          <PagesItem
            $currentPage={page === currentPage}
            key={page}
            onClick={() => handlePageChange(+page)}>
            {page}
          </PagesItem>
        ))}
        <PaginationArrowContainer $currentPage={currentPage < totalPages}>
          <ArrowIcon onClick={moveNextPage} />
          <DoubleArrowIcon onClick={openLastPage} />
        </PaginationArrowContainer>
      </Pagination>
      <ItemsAmountToShow>
        <span>{t('show_on_page')}:</span>
        <Select
          value={initialPerPageItem}
          items={PerPageCountVariants}
          onChange={handlePerPageChange}
        />
      </ItemsAmountToShow>
    </PaginationContainer>
  );
};

export default PaginationComponent;
