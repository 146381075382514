import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { FormikProps } from 'formik';
import { useStore } from '../../stores/store';
import ParcelParticipantPersonalForm from '../../features/parcel-creation/parcel-participant-personal/parcel-participant-personal.component';
import ParcelParticipantContactForm from '../../features/parcel-creation/parcel-participant-contact/parcel-participant-contact.component';
import ParcelParticipantPudoForm from '../../features/parcel-creation/parcel-participant-pudo/parcel-participant-pudo.component';
import DeliveryDetailsForm, {
  UserRole,
} from '../../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import {
  contactDetailsValidationSchema,
  removeCountryCallingCodeFromPhone,
} from '../personal-data/contact-details-form.component';
import Sidebar from '../../common/sidebar-right/sidebar.component';
import { Receiver } from '../../models/user';
import InputSearch from '../../common/input-search/input-search.component';
import { ScrollableContent } from '../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import { MenuItemsReceivers } from '../../features/parcel-creation/parcel-participant-personal/parcel-participant-personal.styles';
import MenuItem from '../../common/menu-item/menu-item.component';
import ReceiverCard from '../../features/parcel-creation/parcel-participant-personal/receiver-card/receiver-card.component';
import EmptyList from '../../common/empty-list/empty-list.component';
import { VALIDATION_RULE_LATIN } from '../../constants/validation';
import { pudoServiceTypes } from '../../utils/parcel-creation/parcel-creation-utils';
import { getSettingReceiverCountry } from '../../utils/countries/countries.utils';

export interface IContactDetailsFormValues {
  phone: string;
  phone_country_code: string;
  email: string;
}

export interface IPersonalDataFormValues {
  first_name: string;
  last_name: string;
}

const ParcelReceiver = () => {
  const {
    parcelCreationStore: {
      shipment,
      selectedReceiverPudo,
      setReceiverFirstName,
      setReceiverLastName,
      setReceiverPhone,
      setReceiverPhoneCountryCode,
      setReceiverEmail,
      setReceiverPudo,
      countryDestination,
      setReceiverAddress,
      isPriceChanged,
      setIsPriceChanged,
      countryDeparture,
      setIsAddressReset,
    },
    userStore: { getReceivers, receivers },
    navStore: { toggleSidebarByName },
    commonStore: { toastInfo },
  } = useStore();

  const [searchOptions, setSearchOptions] = useState<Receiver[] | undefined>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<Receiver | undefined>();
  const [hasSearched, setHasSearched] = useState(false);

  const serviceType =
    shipment?.recipient?.carrier.delivery_service.service_type ?? 'post_office';
  const isCourierOption = serviceType === 'courier';
  const { t } = useTranslation();

  const isDefaultFlow =
    getSettingReceiverCountry(countryDeparture) === 'default_flow';

  const formikRefPersonal = useRef<FormikProps<IPersonalDataFormValues> | null>(
    null
  );
  const formikRefContact =
    useRef<FormikProps<IContactDetailsFormValues> | null>(null);

  useEffect(() => {
    getReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const receiversToDisplay = receivers?.filter((receiver) => {
    const isSameCountry =
      receiver?.phone?.country_code === countryDestination?.countryCode;

    const isSameZipCode =
      receiver?.address?.post_code === countryDestination?.zipCode;

    const shouldCheckZip = isCourierOption && isDefaultFlow;

    return shouldCheckZip ? isSameCountry && isSameZipCode : isSameCountry;
  });

  useEffect(() => {
    if (receivers) {
      setSearchOptions(receiversToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivers]);

  useEffect(() => {
    if (isPriceChanged) {
      toastInfo(t('price_update_message'), true);
      setIsPriceChanged(false);
    }
  }, [isPriceChanged, setIsPriceChanged, t, toastInfo]);

  if (!shipment) return null;

  const modifyListOfDisplayedOptions = (value?: string) => {
    if (value) {
      const listOfDisplayedOptions = receiversToDisplay?.filter(
        (receiver) =>
          receiver.first_name.toLowerCase().includes(value.toLowerCase()) ||
          receiver.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchOptions(
        listOfDisplayedOptions?.length ? listOfDisplayedOptions : []
      );
      setHasSearched(true);
    } else {
      setSearchOptions(receiversToDisplay);
      setHasSearched(false);
    }
  };

  const handleSearchOptionSelected = (option: Receiver) => {
    setReceiverFirstName(option.first_name);
    setReceiverLastName(option.last_name);
    setReceiverPhone(option.phone.phone_number);
    setReceiverPhoneCountryCode(option.phone.country_code);
    setReceiverEmail(option.email);

    if (formikRefPersonal?.current) {
      formikRefPersonal.current.setValues({
        first_name: option.first_name,
        last_name: option.last_name,
      });
    }

    if (formikRefContact?.current) {
      const phoneWithoutCountryCallingCode =
        removeCountryCallingCodeFromPhone(
          option.phone.phone_number ?? '',
          option.phone.country_code ?? ''
        ) ?? '';

      formikRefContact.current.setValues({
        phone: phoneWithoutCountryCallingCode,
        email: option.email,
        phone_country_code: option.phone.country_code,
      });
    }

    const isCourierAndMatchingRegion =
      option.carrier.delivery_service.service_type.toLowerCase() ===
        'courier' &&
      option?.address?.region === countryDestination?.region &&
      option?.address?.post_code === countryDestination?.zipCode;

    const isCourierWithCustomFlow =
      option.carrier.delivery_service.service_type.toLowerCase() ===
        'courier' && !isDefaultFlow;

    if (isCourierAndMatchingRegion || isCourierWithCustomFlow) {
      setReceiverAddress(option.address);
    } else {
      setReceiverAddress({
        full_address: '',
        post_code: '',
        region: '',
        city: '',
        street: '',
        building: '',
        section: '',
        apartment: '',
        buzz_code: '',
        note: '',
        country: '',
      });

      setIsAddressReset(true);
    }

    toggleSidebarByName('receiver_first_name');
    setSelectedOption(option);
  };

  return (
    <>
      <ParcelParticipantPersonalForm
        key='recipient_personal_form'
        initialValues={{
          first_name: shipment.recipient?.first_name ?? '',
          last_name: shipment.recipient?.last_name ?? '',
        }}
        storeParticipantFirstName={setReceiverFirstName}
        storeParticipantLastName={setReceiverLastName}
        isReceiver
        innerRef={formikRefPersonal}
      />

      <ParcelParticipantContactForm
        validationSchema={contactDetailsValidationSchema}
        initialValues={{
          phone:
            removeCountryCallingCodeFromPhone(
              shipment.recipient?.phone?.phone_number ?? '',
              shipment.recipient?.phone?.country_code ?? ''
            ) ?? '',
          phone_country_code: countryDestination?.countryCode ?? 'US',
          email: shipment.recipient?.email ?? '',
        }}
        storeParticipantPhone={setReceiverPhone}
        storeParticipantPhoneCountryCode={setReceiverPhoneCountryCode}
        storeParticipantEmail={setReceiverEmail}
        isDropdownDisabled
        innerRef={formikRefContact}
      />

      {pudoServiceTypes.includes(serviceType) ? (
        <ParcelParticipantPudoForm
          initialValues={{
            pudo: selectedReceiverPudo,
          }}
          role={UserRole.receiver}
          header={t('drop_off_address')}
          storeRecipientPudo={setReceiverPudo}
        />
      ) : (
        <DeliveryDetailsForm role={UserRole.receiver} />
      )}

      <Sidebar
        name='receiver_first_name'
        withBlurredBackground
        header={t('my_receivers')}>
        {hasSearched || (searchOptions && searchOptions.length > 0) ? (
          <InputSearch
            name='search_my_receivers'
            placeholder={t('search_receivers')}
            focusOnOpen
            searchIconPosition='left'
            disableSearchIconHover
            showClearInputButton
            onChange={(targetValue) =>
              modifyListOfDisplayedOptions(targetValue)
            }
            validationRule={VALIDATION_RULE_LATIN}
          />
        ) : null}
        <ScrollableContent
          $heightOfAboveContentMobile='19rem'
          $heightOfAboveContent='18rem'>
          {searchOptions && searchOptions.length > 0 ? (
            <MenuItemsReceivers>
              {searchOptions
                .sort((a, b) => a.first_name.localeCompare(b.first_name))
                .map((receiver) => (
                  <MenuItem
                    key={receiver.id}
                    isSelected={receiver?.id === selectedOption?.id}
                    onClick={() => handleSearchOptionSelected(receiver)}>
                    <ReceiverCard receiver={receiver} />
                  </MenuItem>
                ))}
            </MenuItemsReceivers>
          ) : (
            <EmptyList
              isSidebar
              description={t('please_try_again')}
              title={t('no_search_results')}
            />
          )}
        </ScrollableContent>
      </Sidebar>
    </>
  );
};

export default observer(ParcelReceiver);
