import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';
import 'react-toastify/dist/ReactToastify.min.css';

export const ToastErrorStyles = css`
  --icon-color: ${({ theme }) => theme.colors.baseColors.red100};
  border-color: ${({ theme }) => theme.colors.toastError.borderColor};
  background-color: #fff0f3;
`;

export const ToastStyles = css`
  .toastContainerCustom {
    --toastify-toast-min-height: 38px;
    --toastify-toast-width: 375px;
    --toastify-z-index: 999999999;
    padding: 0;

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .Toastify__toast-body {
    align-items: start;
    margin: 0;
    padding: 0;
  }

  .Toastify__toast,
  .tip {
    padding: 8px 16px;
    border: 1px solid;
    border-radius: 10px;
    gap: 10px;
    font-size: ${FontSizes.normal};
    font-weight: 300;
    color: #000;
    line-height: 1.42;
    letter-spacing: 0.5px;
    font-family: Montserrat, 'Open Sans', sans-serif;
    text-align: left;
    justify-content: start;
    box-shadow: none;
  }

  .toastErrorCustom {
    ${ToastErrorStyles}
  }

  .ErrorMissingPhoneNumber {
    --toastify-toast-width: 51.3rem;
    @media screen and (min-width: 480px) {
      min-width: var(--toastify-toast-width);
    }
  }

  .toastSuccessCustom,
  .toastPromoCustom {
    --icon-color: #47ce72;
    background-color: #f2fff6;
    border-color: #47ce72;
  }

  .toastInfoCustom {
    --icon-color: ${({ theme }) => theme.colors.baseColors.blue100};
    background-color: #e5f4ff;
    border-color: ${({ theme }) => theme.colors.baseColors.blue100};
  }

  .toastWarningCustom {
    --icon-color: #ffcd45;
    background-color: #fffaeb;
    border-color: #ffcd45;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 1rem;
    }

    .Toastify__toast-container--top-center {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const ToastCloseButton = styled.button`
  background: none;
  padding: 0;
  border: none;
  display: flex;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  cursor: pointer;
  color: #75777b;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledTip = styled.div`
  display: flex;
  gap: 10px;
  align-items: start;
`;

export const TipIcon = styled.div`
  display: flex;
  position: relative;

  svg {
    fill: var(--icon-color);
  }
`;

export const ToastPrimaryMessage = styled.span`
  font-size: 1.4rem;
`;

export const ToastSecondaryMessage = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  margin-top: 0.4rem;
`;
