import { useTranslation } from 'react-i18next';
import { VALIDATION_RULE_LATIN_WITH_COMMA } from '../../../constants/validation';
import SearchWithSidebar from '../../../common/search-with-sidebar/search-with-sidebar.component';
import { useStore } from '../../../stores/store';
import {
  getCityFromPlaceDetails,
  getPredictionsWithUserInput,
  getZipCodeFromPlaceDetails,
} from '../../../utils/google-places/google-places.utils';
import { PLACES_API_MINIMAL_REQUEST_LENGTH } from '../../../stores/placesSearchStore';
import { toGeocode } from '../../../common/map/map.helpers';
import { Geocode } from '../../../models/pudoItem';

export const changeCitySidebarName = 'change_city';

interface Props {
  countryCode?: string;
  onCitySelected: (cityName: string, zip: string, location: Geocode) => void;
}

const ChangeCitySidebar = ({ countryCode, onCitySelected }: Props) => {
  const {
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    commonStore: { toastError },
  } = useStore();

  const { t } = useTranslation();

  return (
    <SearchWithSidebar<google.maps.places.AutocompletePrediction>
      name={changeCitySidebarName}
      sidebarTitle={t('choose_city')}
      sidebarInputPlaceholder=''
      debounceSidebarInputChange
      shouldValidateSearchQuery
      getSearchOptions={async (filter) => {
        const predictions = await getAutocompletePredictions({
          value: filter ?? '',
          predictionTypes: ['geocode'],
          countryConstraint: countryCode?.toLocaleLowerCase(),
        });

        return getPredictionsWithUserInput(
          predictions,
          filter,
          PLACES_API_MINIMAL_REQUEST_LENGTH
        );
      }}
      getKeyForSearchOption={(place) => place.place_id}
      getDisplayValueForSearchOption={(place) => place.description}
      onSearchOptionSelected={async (place) => {
        const placeDetails = await getPlaceDetails(place);

        if (placeDetails) {
          const zip = getZipCodeFromPlaceDetails(
            placeDetails.address_components
          );
          const city = getCityFromPlaceDetails(placeDetails.address_components);
          const location = toGeocode(placeDetails.location);

          if (city && zip && location) {
            onCitySelected(city, zip, location);
          }

          return;
        }

        toastError(t('pudo_map_address_not_found'));
      }}
      showSkeleton={false}
      validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
    />
  );
};

export default ChangeCitySidebar;
