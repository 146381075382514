import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { TextLabel } from '../../../common/typography/typography.styles';
import {
  ParcelStepNumber,
  ParcelStepSeparator,
  ParcelStepTitleBody,
  ParcelStepTitleContainer,
  ParcelStepTooltip,
} from './parcel-step-title.styles';
import { ReactComponent as CompletedIcon } from '../../../assets/checkbox-icon.svg';
import {
  Step,
  toTranslationKey,
} from '../../../models/parcelCreationFlowNavigation';

interface Props {
  step: Step;
  stepIndex: number;
  isActive: boolean;
  isLastStep: boolean;
  isCompleted: boolean;
  tooltip: boolean;
  hideSeparator?: boolean;
}

const ParcelStepTitle = ({
  step,
  stepIndex,
  isActive,
  isLastStep,
  isCompleted,
  tooltip,
  hideSeparator = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ParcelStepTitleContainer
      $isActive={isActive}
      $isCompleted={isCompleted}>
      <ParcelStepTitleBody>
        <ParcelStepNumber>
          {!isCompleted && stepIndex}
          {isCompleted && <CompletedIcon />}
        </ParcelStepNumber>
        <TextLabel>{t(toTranslationKey(step.name))}</TextLabel>
      </ParcelStepTitleBody>
      <ParcelStepTooltip>
        {!isLastStep && !hideSeparator && (
          <ParcelStepSeparator
            $tooltip={tooltip}
            $step={stepIndex}
          />
        )}
        {step.name === 'delivery-option' && tooltip && (
          <TextLabel>{t('select_sending_receiving_options')}</TextLabel>
        )}
      </ParcelStepTooltip>
    </ParcelStepTitleContainer>
  );
};

export default observer(ParcelStepTitle);
