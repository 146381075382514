import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { useStore } from '../../stores/store';
import AgentDropoffInstructions from '../../features/parcel-creation/agent-dropoff-instructions/agent-dropoff-instructions.component';
import Tip from '../../common/toasts/tip.component';
import { ReactComponent as HouseIcon } from '../../assets/house-icon.svg';
import { UserRole } from '../../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import ParcelPudoSearchWithSidebar from '../../features/parcel-creation/parcel-pudo-search-sidebar/parcel-pudo-search-sidebar.component';
import { PudoSearchFormChangeHandler } from '../../features/parcel-creation/parcel-participant-pudo/parcel-participant-pudo.component';
import { PudoWrapper } from './styles';

const ParcelDeliveryInstructions = () => {
  const {
    parcelCreationStore: {
      selectedSenderPudo,
      selectedFirstMile,
      setSenderPudo,
    },
  } = useStore();

  const { t } = useTranslation();

  if (!selectedSenderPudo) return;

  const initialValues = {
    pudo: selectedSenderPudo,
  };

  return (
    <>
      <ServicesSubSectionContainer>
        <ServicesSubSectionHeader>
          {t('agent_instructions_drop_off_address')}
        </ServicesSubSectionHeader>
        <PudoWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}>
            {({ handleSubmit }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <ParcelPudoSearchWithSidebar
                  fieldName='pudo'
                  role={UserRole.sender}
                  placeholder={t('choose_address')}
                  sidebarTitle={t('choose_pudo_sender', {
                    CarrierName: selectedFirstMile?.carrier.name,
                  })}
                />

                <PudoSearchFormChangeHandler updatePudo={setSenderPudo} />
              </form>
            )}
          </Formik>
        </PudoWrapper>
        <Tip
          appearance='info'
          title={t('agent_instructions_tips')}
          icon={<HouseIcon />}
        />
      </ServicesSubSectionContainer>
      <AgentDropoffInstructions />
    </>
  );
};

export default observer(ParcelDeliveryInstructions);
