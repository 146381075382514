import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTabsContainer } from './auth-tabbed-container.styles';
import SignIn from '../sign-in/sign-in.component';
import SignUp from '../sign-up/sign-up.component';
import { useStore } from '../../../stores/store';

const AuthTabs = () => {
  const {
    navStore: { authTabIndex, setAuthTabIndex },
    userStore: { setSignUpFormValues },
  } = useStore();

  const { t } = useTranslation();

  useEffect(
    () => () => {
      setSignUpFormValues(undefined);
      /* eslint-disable-next-line */
      setAuthTabIndex(0);
    },
    /* eslint-disable-next-line */
    []
  );

  return (
    <AuthTabsContainer>
      <Tabs
        selectedIndex={authTabIndex}
        onSelect={(index) => setAuthTabIndex(index)}>
        <TabList>
          <Tab tabIndex='-1'>{t('sign_in')}</Tab>
          <Tab tabIndex='-1'>{t('sign_up')}</Tab>
        </TabList>
        <TabPanel>
          <SignIn />
        </TabPanel>
        <TabPanel>
          <SignUp />
        </TabPanel>
      </Tabs>
    </AuthTabsContainer>
  );
};

export default observer(AuthTabs);
