import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import { TextBody } from '../../common/typography/typography.styles';
import Button from '../../common/button/button.component';
import { ButtonContainer } from './delete-account.styles';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { MarginContainer } from '../../common/base-margin/base-margin.component';
import { Modals } from '../../constants/modals';
import { useModal } from '../../common/modal';
import { theme } from '../../styles/themes';
import { useMediaQuery } from '../../hooks/use-media-query.hook';

const DeleteAccount = () => {
  const {
    userStore: { user, isDeletingAccount, deleteAccount },
    navStore: { setAuthCurrentScreen },
  } = useStore();

  const { t } = useTranslation();

  const { openModal } = useModal();
  const isTablet = useMediaQuery('(max-width: 767px)');

  const handleDeleteAccount = () => {
    setAuthCurrentScreen('auth-tabs');
    deleteAccount();
  };

  const showModal = () => {
    openModal({
      id: Modals.DELETE_ACCOUNT,
      name: Modals.DELETE_ACCOUNT,
      props: {
        onApprove: handleDeleteAccount,
        isLoading: isDeletingAccount,
      },
    });
  };

  if (!user) return null;

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('delete_account')}</ServicesSubSectionHeader>
      <MarginContainer
        $mbMobile='2rem'
        $mb='2.4rem'>
        <TextBody color={theme.colors.baseColors.grey100}>
          {t('delete_account_warning_explanation')}
        </TextBody>
      </MarginContainer>
      <ButtonContainer>
        <Button
          appearance='text-danger'
          hideLinkUnderline
          fullWidth={isTablet}
          onClick={showModal}>
          {t('delete_account')}
        </Button>
      </ButtonContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(DeleteAccount);
