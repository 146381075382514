/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react-lite';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import { TextBody } from '../../../common/typography/typography.styles';

import {
  calculateVolumetricWeight,
  getFormulaDivider,
  getLengthConfig,
  getWeightConfig,
} from './dimensionsConfig';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import {
  Container,
  Messages,
  VolumetricWeightWarningText,
} from './parcel-details-dimensions.styles';
import Tip from '../../../common/toasts/tip.component';
import ChangeHandler from './change-handler.component';
import { sideValidatorMax, sideValidatorMin } from './helpers';
import { ALL_LANG_LETTER_REGEX } from '../../../constants/validation';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { isAnyValueEmpty } from '../../../utils/generic/generic.utils';

const MIN_SIDE = 0.1;
const yupCommon = Yup.string()
  .required('this_field_cannot_be_empty')
  .test(
    'no-letters',
    'this_field_cannot_be_empty',
    (value) => !!value && ALL_LANG_LETTER_REGEX.test(value)
  );

const initialValidationSchema = Yup.object({
  weight: yupCommon,
  size_x: yupCommon,
  size_y: yupCommon,
  size_z: yupCommon,
});

const ParcelDetailsDimensions = () => {
  const {
    parcelCreationStore,
    userStore: { measures },
    parcelCreationStore: {
      deliveryLimitations,
      parcelDimensions,
      isNextButtonClicked,
    },
  } = useStore();
  const weightConfig = getWeightConfig(measures);
  const lengthConfig = getLengthConfig(measures);

  const { t } = useTranslation();

  const initialValues = {
    weight: parcelDimensions?.weight || '',
    size_x: parcelDimensions?.size_x || '',
    size_y: parcelDimensions?.size_y || '',
    size_z: parcelDimensions?.size_z || '',
  };

  const getSizeValidationText = (
    size: 'X' | 'Y' | 'Z',
    type: 'max' | 'min'
  ) => {
    const translations: {
      [key in 'max' | 'min']: {
        X: string;
        Y: string;
        Z: string;
      };
    } = {
      min: {
        X: 'create_parcel_width_short',
        Y: 'create_parcel_height_short',
        Z: 'create_parcel_length_short',
      },
      max: {
        X: 'create_parcel_width_long',
        Y: 'create_parcel_height_long',
        Z: 'create_parcel_length_long',
      },
    };

    const limit = type === 'max' ? deliveryLimitations?.maxSide : MIN_SIDE;

    return `${t(translations[type][size])} ${limit} ${lengthConfig.translation}`;
  };

  const getWeightValidationText = (type: 'max' | 'min') => {
    const translations = {
      min: 'create_parcel_weight_light',
      max: 'create_parcel_weight_large',
    };

    return `${t(translations[type])} ${deliveryLimitations?.[`${type}Weight`]} ${weightConfig.translation}`;
  };

  const validationSchema = Yup.object({
    weight: yupCommon
      .test('less-than', getWeightValidationText('max'), (value = '') => {
        if (!deliveryLimitations?.maxWeight) return true;
        return Number(value) <= deliveryLimitations?.maxWeight;
      })
      .test('more-than', getWeightValidationText('min'), (value = '') => {
        if (!deliveryLimitations?.minWeight) return true;
        return Number(value) >= deliveryLimitations?.minWeight;
      }),
    size_x: yupCommon
      .test(
        'less-than',
        getSizeValidationText('X', 'max'),
        sideValidatorMax(deliveryLimitations?.maxSide)
      )
      .test(
        'more-than',
        getSizeValidationText('X', 'min'),
        sideValidatorMin(MIN_SIDE)
      ),
    size_y: yupCommon
      .test(
        'less-than',
        getSizeValidationText('Y', 'max'),
        sideValidatorMax(deliveryLimitations?.maxSide)
      )
      .test(
        'more-than',
        getSizeValidationText('Y', 'min'),
        sideValidatorMin(MIN_SIDE)
      ),
    size_z: yupCommon
      .test(
        'less-than',
        getSizeValidationText('Z', 'max'),
        sideValidatorMax(deliveryLimitations?.maxSide)
      )
      .test(
        'more-than',
        getSizeValidationText('Z', 'min'),
        sideValidatorMin(MIN_SIDE)
      ),
  });

  const allInputsProvided = !isAnyValueEmpty(parcelDimensions);

  const volumetricWeight = parcelDimensions
    ? calculateVolumetricWeight({
        width: parcelDimensions.size_x,
        height: parcelDimensions.size_y,
        length: parcelDimensions.size_z,
        measure: measures,
      })
    : 0;

  const isVolumetricWeightTooLarge =
    allInputsProvided && parcelDimensions
      ? volumetricWeight > parcelDimensions?.weight
      : false;

  const shouldShowVolumetricError =
    deliveryLimitations?.maxVolWeight &&
    volumetricWeight > deliveryLimitations.maxVolWeight;

  const volWeightNumber = Number.isInteger(volumetricWeight)
    ? volumetricWeight
    : volumetricWeight.toFixed(2);

  const shouldShowVolWeight =
    allInputsProvided &&
    parcelDimensions &&
    volumetricWeight > parcelDimensions.weight;

  return (
    <Container>
      <ServicesSubSectionHeader id='parcel-dimensions-heading'>
        {t('package_dimensions')}
      </ServicesSubSectionHeader>
      <Formik
        enableReinitialize
        onSubmit={() => {}}
        validationSchema={
          deliveryLimitations ? validationSchema : initialValidationSchema
        }
        initialValues={initialValues}>
        {({ setFieldValue }) => {
          const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            if (value.trim() === '' || Number.isNaN(Number(value))) return;

            setFieldValue(name, String(Number(value)));
          };

          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            let newValue = value.replace(/,/g, '.').replace(/[^0-9.,]/g, '');
            // Ensure only one decimal point and one digit after the decimal point
            const match = newValue.match(/^(\d*)(\.?)(\d?)/);
            newValue = match ? `${match[1]}${match[2]}${match[3]}` : '';

            setFieldValue(name, newValue);
          };

          const commonProps = {
            validationMethod: 'on-type',
            onBlur: handleBlur,
            onChange: handleChange,
            inputMode: 'decimal',
          } as const;

          const sideProps = {
            ...commonProps,
            infoText: lengthConfig.translation,
          };
          return (
            <form
              aria-labelledby='parcel-dimensions-heading'
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <FormFieldText
                label='weight'
                name='weight'
                infoText={weightConfig.translation}
                {...commonProps}
                triggerValidation={isNextButtonClicked}
              />

              <FormFieldText
                label='length'
                name='size_z'
                {...sideProps}
                triggerValidation={isNextButtonClicked}
              />

              <FormFieldText
                $mb='0'
                label='width'
                name='size_x'
                {...sideProps}
                triggerValidation={isNextButtonClicked}
              />

              <FormFieldText
                $mb='0'
                label='height'
                name='size_y'
                {...sideProps}
                triggerValidation={isNextButtonClicked}
              />
              <ChangeHandler
                deliveryLimitations={deliveryLimitations}
                setParcelDimensions={parcelCreationStore.setParcelDimensions}
              />
            </form>
          );
        }}
      </Formik>
      {(shouldShowVolWeight || shouldShowVolumetricError) && (
        <Messages>
          <div>
            {shouldShowVolWeight && (
              <FlexContainer
                $justify='start'
                $gap='0.4rem'>
                <TextBody>{t('create_parcel_volumetric_calculated')} </TextBody>

                <TextBody $fontWeight={700}>
                  {volumetricWeight === 0
                    ? 0
                    : volumetricWeight > 0.01
                      ? volWeightNumber
                      : '< 0.01'}{' '}
                  {weightConfig.translation}
                </TextBody>
              </FlexContainer>
            )}
            {shouldShowVolumetricError && (
              <VolumetricWeightWarningText>
                {t('create_parcel_volumetric_large')}{' '}
                {`(${t('length')}×${t('width')}×${t('height')})/${getFormulaDivider(measures)}`}{' '}
                {'<'} {deliveryLimitations.maxWeight}
              </VolumetricWeightWarningText>
            )}
          </div>
          {isVolumetricWeightTooLarge && !shouldShowVolumetricError && (
            <Tip
              appearance='warning'
              title={t('create_parcel_volumetric_tip')}
            />
          )}
        </Messages>
      )}
    </Container>
  );
};

export default observer(ParcelDetailsDimensions);
