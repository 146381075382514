import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  ButtonContainer,
  ContainerMobile,
  Counter,
  Description,
  Header,
  SliderPagination,
  TextBlock,
  PaginationContainer,
  BulletContainer,
  Bullet,
  SliderWrapper,
  CardDesktop,
  ContainerDesktop,
  CardMobile,
  ContainerBanner,
  BannerLink,
} from './banner.styles';
import { useSliderMethods } from '../../../hooks/use-slider-methods.hook';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import SliderArrow from '../../../common/slider/slider-arrow.component';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import { VisuallyHidden } from '../../../common/visually-hidden/visually-hidden.styles';

const Banner = () => {
  const navigate = useNavigate();

  const {
    marketingStore: { getAllBanners, banners },
    localizationsStore: { selectedLocalization },
  } = useStore();

  const {
    moveNextSlide,
    handleBulletClick,
    movePrevSlide,
    handleTouchMove,
    handleTouchStart,
    currentSlide,
    containerRef,
  } = useSliderMethods({ items: banners || [] }, true);
  const isMobile = useIsMobile();

  useEffect(() => {
    getAllBanners();
  }, [getAllBanners, selectedLocalization.code]);

  if (!banners) return null;

  return (
    <ContainerBanner>
      <SliderWrapper>
        {!isMobile && (
          <>
            {banners.map((item, index) => (
              <ContainerDesktop
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                $currentSlide={currentSlide}>
                <CardDesktop $backgroundImage={item.desktop_image_url}>
                  <BannerLink href={item.button_url}>
                    <VisuallyHidden>{item.button_text}</VisuallyHidden>
                  </BannerLink>
                  <FlexContainer
                    $align='initial'
                    $justify='space-between'>
                    <TextBlock>
                      <div>
                        <Header $color={item.font_color}>{item.title}</Header>
                        <Description $color={item.font_color}>
                          {item.text}
                        </Description>
                      </div>
                      {item.button_text && (
                        <div>
                          <Button
                            size='large'
                            appearance={
                              item.font_color === 'white'
                                ? 'primary'
                                : 'secondary-filled'
                            }
                            fullWidth={false}>
                            {item.button_text}
                          </Button>
                        </div>
                      )}
                    </TextBlock>
                  </FlexContainer>
                </CardDesktop>
              </ContainerDesktop>
            ))}
            <SliderPagination>
              <ButtonContainer>
                <SliderArrow
                  disabled={currentSlide === 0}
                  theme='white'
                  onClick={movePrevSlide}
                />
                <SliderArrow
                  disabled={currentSlide === banners.length - 1}
                  direction='right'
                  theme='white'
                  onClick={moveNextSlide}
                />
              </ButtonContainer>
              <Counter>
                <span>{currentSlide + 1}</span>/<span>{banners.length}</span>
              </Counter>
            </SliderPagination>
          </>
        )}
        {isMobile &&
          banners.map((item, index) => (
            <ContainerMobile
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              $currentSlide={currentSlide}>
              <CardMobile
                ref={containerRef}
                $index={index}
                $currentSlide={currentSlide}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}>
                <BannerLink href={item.button_url}>
                  <VisuallyHidden>{item.button_text}</VisuallyHidden>
                </BannerLink>
                <PaginationContainer $backgroundImage={item.mobile_image_url}>
                  <BulletContainer>
                    {banners.map((_, idx) => (
                      <Bullet
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        $isActive={idx === currentSlide}
                        onClick={() => handleBulletClick(idx)}
                      />
                    ))}
                  </BulletContainer>
                </PaginationContainer>
                <div>
                  <Header>{item.title}</Header>
                  <Description>{item.text}</Description>
                  {item.button_text && (
                    <Button
                      size='large'
                      onClick={() => {
                        navigate(`${item.button_url}`);
                      }}
                      $mt='16px'
                      appearance='primary'>
                      {item.button_text}
                    </Button>
                  )}
                </div>
              </CardMobile>
            </ContainerMobile>
          ))}
      </SliderWrapper>
    </ContainerBanner>
  );
};
export default observer(Banner);
