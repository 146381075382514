import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { FlexContainer } from './delivery-details-form.styles';
import { useStore } from '../../../stores/store';
import { Address } from '../../../models/parcelDeliveryMiles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { getShipmentCountryName } from '../../../utils/parcel-creation/parcel-creation-utils';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_WITH_NUMBERS,
  VALIDATION_RULE_ONLY_LETTERS,
} from '../../../constants/validation';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';

import { getElementTypeByName } from '../../../models/parcelCreationFlowNavigation';
import { IShipmentCountry } from '../../../stores/parcelCreationFlowStore';
import useDeliveryDetailsInitialization from '../../../hooks/use-delivery-details-initialization';
import { translate } from '../../../../i18n';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import PlacesFormFieldSearch from '../../../common/form-field-places-search/form-field-places-search.component';
import { findAdminUnitFromPrediction } from '../../../utils/countries/countries.utils';

export const courierDeliveryDetailsValidationSchema = Yup.object({
  country: Yup.string().required('this_field_cannot_be_empty!'),
  region: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  city: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  post_code: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  street: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  building: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  section: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_NUMBERS
  ).notRequired(),
  apartment: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  note: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).notRequired(),
  buzz_code: createValidationRule(
    VALIDATION_RULE_IS_NOT_CYRILLIC
  ).notRequired(),
  full_address: Yup.string().notRequired(),
});

export const enum UserRole {
  sender = 'sender',
  receiver = 'receiver',
}

export interface DeliveryDetailsFormValues {
  country: string;
  region: string;
  city: string;
  post_code: string;
  street: string;
  building: string;
  section: string;
  apartment: string;
  note: string;
  buzz_code: string;
  full_address?: string;
}

interface ChangeHandlerProps {
  setAddress: (address: Address) => void;
  setShowInfoMessage: (showInfoMessage: boolean) => void;
  countryDeparture: IShipmentCountry | null;
  countryDestination: IShipmentCountry | null;
  isUserSender?: boolean;
  isUserReceiver?: boolean;
  isAddressReset: boolean;
  setIsAddressReset: (isAddressReset: boolean) => void;
}

export const getRegionLabel = (countryCode: string | undefined): string => {
  if (countryCode === 'CA') return translate('Province');
  if (countryCode === 'US') return translate('State');
  return translate('Region');
};

export const getPostalCodeLabel = (countryCode: string | undefined): string => {
  if (countryCode === 'CA') return translate('create_parcel_postal_code');
  if (countryCode === 'US') return translate('create_parcel_zip_code');

  return `${translate('create_parcel_postal_code')} / ${translate('create_parcel_zip_code')}`;
};

const ChangeHandler = ({
  setAddress,
  setShowInfoMessage,
  countryDeparture,
  countryDestination,
  isUserSender,
  isUserReceiver,
  isAddressReset,
  setIsAddressReset,
}: ChangeHandlerProps) => {
  const { values, resetForm } = useFormikContext<DeliveryDetailsFormValues>();

  useEffect(() => {
    if (isAddressReset && isUserReceiver) {
      resetForm();
      setIsAddressReset(false);
    } else {
      setAddress(values);
    }
  }, [
    values,
    isAddressReset,
    isUserReceiver,
    resetForm,
    setAddress,
    setIsAddressReset,
  ]);

  if (
    (isUserSender &&
      countryDeparture?.zipCode !== values.post_code &&
      values.post_code !== '') ||
    (isUserReceiver &&
      countryDestination?.zipCode !== values.post_code &&
      values.post_code !== '')
  ) {
    setShowInfoMessage(true);
  } else {
    setShowInfoMessage(false);
  }

  return null;
};

interface DeliveryDetailsFormProps {
  role: UserRole;
  isHomeAddress?: boolean;
}

const DeliveryDetailsForm = ({
  role,
  isHomeAddress = false,
}: DeliveryDetailsFormProps) => {
  const {
    parcelCreationStore: {
      shipment,
      countryDeparture,
      countryDestination,
      setSenderAddress,
      setReceiverAddress,
      currentElements,
      isAddressReset,
      setIsAddressReset,
      isNextButtonClicked,
      navigation,
    },
    userStore: { user: accountUser },
  } = useStore();

  const { t } = useTranslation();

  const isUserSender = role === UserRole.sender;
  const isUserReceiver = role === UserRole.receiver;
  const user = isUserSender ? shipment?.sender : shipment?.recipient;
  const country = isUserSender ? countryDeparture : countryDestination;
  const adminUnits = navigation?.current?.elements?.find(
    (element) => element.name === 'administrative-unit'
  )?.behavior?.allowed_values;
  const adminUnitsTitle = navigation?.current?.elements?.find(
    (element) => element.name === 'administrative-unit'
  )?.behavior?.title;

  const initialCountry = getShipmentCountryName(country, t);

  const additionalFieldsType = getElementTypeByName(
    currentElements,
    'additional-fields'
  );

  const formTitle = isHomeAddress ? t('home_address') : t('pick_up_address');

  const isHideFields = additionalFieldsType === 'hide';

  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const { initialValues } = useDeliveryDetailsInitialization({
    isUserSender,
    isUserReceiver,
    isHomeAddress,
    shipmentAddress: user?.address,
    accountUser,
    initialCountry,
    isHideFields,
  });

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{formTitle}</ServicesSubSectionHeader>
      <Formik
        enableReinitialize
        validationSchema={courierDeliveryDetailsValidationSchema}
        initialValues={initialValues}
        onSubmit={() => {}}>
        {({
          handleSubmit,
          setFieldValue,
          setValues,
          validateField,
          values,
        }) => {
          const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value, name } = event.target;
            setFieldValue(name, value);
            setFieldValue('full_address', '');
          };
          return (
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}>
              <ChangeHandler
                setAddress={
                  isUserSender ? setSenderAddress : setReceiverAddress
                }
                setShowInfoMessage={setShowInfoMessage}
                countryDeparture={countryDeparture}
                countryDestination={countryDestination}
                isUserSender={isUserSender}
                isUserReceiver={isUserReceiver}
                isAddressReset={isAddressReset}
                setIsAddressReset={setIsAddressReset}
              />
              <FlexContainer>
                <FormFieldText
                  name='country'
                  label={t('country')}
                  disabled
                  triggerValidation={isNextButtonClicked}
                />
              </FlexContainer>

              <FlexContainer>
                <PlacesFormFieldSearch
                  selectedCountryCode={country?.countryCode}
                  name='full_address'
                  placeholder=''
                  label={t('address_search')}
                  formikValidateField={validateField}
                  setValues={(placeInfo) => {
                    const region =
                      findAdminUnitFromPrediction(
                        placeInfo.region,
                        adminUnits
                      ) ?? '';
                    setValues({
                      ...values,
                      full_address: placeInfo.address,
                      region,
                      city: placeInfo.city,
                      street: placeInfo.street,
                      post_code: placeInfo.zipCode,
                      building: placeInfo.building,
                      apartment: placeInfo.apartment,
                    });
                  }}
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='building'
                  label={t('building')}
                  onChange={onChange}
                  isOptional
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='street'
                  label={t('street')}
                  onChange={onChange}
                  triggerValidation={isNextButtonClicked}
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='apartment'
                  label={t('apartment')}
                  onChange={onChange}
                  isOptional
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='city'
                  label={t('city')}
                  onChange={onChange}
                  triggerValidation={isNextButtonClicked}
                />
              </FlexContainer>

              <FlexContainer>
                {adminUnits && adminUnits.length > 0 ? (
                  <InputSearchWithSidebar
                    name='region'
                    inputIcon={ArrowRightIcon}
                    shouldValidateSearchQuery
                    displayAllOptionsWithEmptyFilter
                    inputValue={values.region}
                    defaultOption={values.region}
                    isOptionSelected={(region) => region === values.region}
                    label={adminUnitsTitle || ''}
                    sidebarTitle={adminUnitsTitle || ''}
                    sidebarInputPlaceholder={t('region_search_placeholder', {
                      region: getRegionLabel(
                        country?.countryCode
                      ).toLowerCase(),
                    })}
                    placeholder={t('select_region', {
                      region: getRegionLabel(
                        country?.countryCode
                      ).toLowerCase(),
                    })}
                    getSearchOptions={(filter) => {
                      const userRegion = user?.address?.region;
                      const adminUnitsValues = adminUnits?.map((i) => i.value);
                      const regions = filter
                        ? adminUnitsValues!.filter((i) =>
                            i
                              .toLowerCase()
                              .includes(filter.toLocaleLowerCase().trim())
                          )
                        : adminUnitsValues;
                      if (!userRegion) return Promise.resolve(regions);

                      const found = regions!.find(
                        (region) => userRegion === region
                      );
                      if (found) {
                        const filteredArray = regions!.filter(
                          (region) => region !== userRegion
                        );
                        return Promise.resolve([found, ...filteredArray]);
                      }
                      return Promise.resolve(regions);
                    }}
                    onSearchOptionSelected={(selectedOption) => {
                      setFieldValue('region', selectedOption, true);
                      setFieldValue('full_address', '');
                    }}
                    getKeyForSearchOption={(option) => option}
                    getDisplayValueForSearchOption={(option) => option}
                    mainInputValidationPredicate={(inputValue) => !!inputValue}
                    validationRule={VALIDATION_RULE_ONLY_LETTERS}
                    errorMessage={t('this_field_cannot_be_empty')}
                    triggerValidation={isNextButtonClicked}
                  />
                ) : (
                  <FormFieldText
                    maxLength={100}
                    name='region'
                    onChange={onChange}
                    placeholder=''
                    label={adminUnitsTitle || ''}
                  />
                )}

                <FormFieldText
                  name='post_code'
                  label={getPostalCodeLabel(countryDeparture?.countryCode)}
                  onChange={onChange}
                  showInfoMessage={showInfoMessage}
                  infoMessage={t('changing_zip_code_affect_price')}
                  triggerValidation={isNextButtonClicked}
                />
              </FlexContainer>
            </form>
          );
        }}
      </Formik>
    </ServicesSubSectionContainer>
  );
};

export default observer(DeliveryDetailsForm);
