import { Formik, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PudoItem } from '../../../models/pudoItem';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import ParcelPudoSearchWithSidebar from '../parcel-pudo-search-sidebar/parcel-pudo-search-sidebar.component';
import { UserRole } from '../delivery-details-form/delivery-details-form.component';

export interface PudoSearchFormChangeHandlerProps {
  updatePudo: (pudo: PudoItem) => void;
}

const pudoValidationSchema = Yup.object({
  pudo: Yup.mixed().required('this_field_cannot_be_empty'),
});

export const PudoSearchFormChangeHandler = ({
  updatePudo,
}: PudoSearchFormChangeHandlerProps) => {
  const { values } = useFormikContext();
  const { pudo } = values as { pudo: PudoItem };

  useEffect(() => {
    updatePudo(pudo);

    // eslint-disable-next-line
  }, [values]);
  return null;
};

interface Props {
  initialValues: { pudo: PudoItem | undefined };
  header: string;
  role: UserRole;
  storeRecipientPudo: (pudo: PudoItem) => void;
}

const ParcelParticipantPudoForm = ({
  initialValues,
  header,
  role,
  storeRecipientPudo,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{header}</ServicesSubSectionHeader>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={pudoValidationSchema}
        onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <ParcelPudoSearchWithSidebar
              role={role}
              placeholder={t('choose_address')}
              sidebarTitle={header}
              fieldName='pudo'
            />

            <PudoSearchFormChangeHandler updatePudo={storeRecipientPudo} />
          </form>
        )}
      </Formik>
    </ServicesSubSectionContainer>
  );
};

export default ParcelParticipantPudoForm;
