import { RefObject, useCallback, useEffect, useRef } from 'react';
import { IOptionsPages } from '../models/paginationOptions';
import { useIsMobile } from './use-is-mobile.hook';

export default function useScrollPagination(
  options: IOptionsPages,
  containerRef: RefObject<HTMLElement>,
  listType: 'parcel' | 'receiver',
  scrollType: 'window' | 'container',
  fetchItems: (options: IOptionsPages, append: boolean) => Promise<void>
) {
  const loadingRef = useRef(false);
  const isMobile = useIsMobile();

  const handleScroll = useCallback(async () => {
    if (loadingRef.current || !containerRef?.current) return;
    const container = containerRef.current;
    const lastElement = container.lastElementChild;

    if (!lastElement) return;

    const currentPage = options.page ?? 1;
    const perPage = options.perPage ?? 0;
    const totalItems = options.totalElCount ?? 0;
    const totalPages = options.totalPagesCount ?? 1;

    const order = listType === 'parcel' ? options.order : undefined;

    const lastElementRect = lastElement.getBoundingClientRect();

    if (
      lastElementRect.bottom <= window.innerHeight &&
      currentPage < totalPages &&
      perPage < totalItems
    ) {
      loadingRef.current = true;

      const nextPage = currentPage + 1;
      await fetchItems({ ...options, page: nextPage, order }, true);

      loadingRef.current = false;
    }
  }, [containerRef, options, listType, fetchItems]);

  useEffect(() => {
    if (!containerRef?.current) return;

    const container = containerRef.current;

    if (scrollType === 'window' && isMobile) {
      window.addEventListener('scroll', handleScroll);
    }

    if (scrollType === 'container') {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollType === 'window') {
        window.removeEventListener('scroll', handleScroll);
      }
      if (scrollType === 'container') {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, scrollType, handleScroll, isMobile]);
}
