import { useEffect, useId, useState } from 'react';
import { useField } from 'formik';
import { TextCheckbox } from '../typography/typography.styles';
import {
  CheckboxContainer,
  CheckboxContent,
  CheckboxWrapper,
} from './checkbox.styles';
import { ReactComponent as CheckboxIcon } from '../../assets/checkbox-icon.svg';

interface Props {
  name: string;
  children: React.ReactNode;
  tabIndex?: number;
  isChecked?: boolean;
  marginBottom?: string;
  isError?: boolean;
  onChecked: (
    field: string,
    value: boolean,
    shouldValidate?: boolean | undefined
  ) => void;
}

const Checkbox = ({
  name,
  children,
  isChecked = false,
  isError,
  onChecked,
  marginBottom,
}: Props) => {
  const [field] = useField(name);
  const [checked, setChecked] = useState(isChecked);
  const id = useId();

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setChecked(!!field.value);
  }, [field.value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    onChecked(name, !checked, true);
  };

  return (
    <CheckboxWrapper $marginBottom={marginBottom}>
      <CheckboxContainer $isError={isError}>
        <input
          id={name + id}
          {...field}
          type='checkbox'
          onChange={handleChange}
          checked={checked}
        />
        <CheckboxContent>
          <CheckboxIcon />
        </CheckboxContent>
      </CheckboxContainer>
      {children && <TextCheckbox>{children}</TextCheckbox>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
