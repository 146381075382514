import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  HeaderMain,
  TextBody,
  TextBodyParagraph,
  TextResendCode,
} from '../../../common/typography/typography.styles';
import { useStore } from '../../../stores/store';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';
import agent from '../../../api/agent';
import { useTimeout } from '../../../hooks/use-timeout';
import Button from '../../../common/button/button.component';
import { AuthButtonsHolder } from '../../../routes/authorization/authorization.styles';

const SignUpEmailSent = () => {
  const {
    navStore: {
      timerEnd,
      setTimerEnd,
      setAuthTabIndex,
      setAuthCurrentScreen,
      showSendAgain,
      setShowSendAgain,
      initialTimeout,
    },
    commonStore: { currentAuthData: currentSignUpData },
  } = useStore();

  const { timeoutValue, resetTimeout } = useTimeout(initialTimeout);

  useEffect(() => {
    if (timeoutValue !== timerEnd) {
      setTimerEnd(initialTimeout);
    }
  }, [initialTimeout, timeoutValue, timerEnd, setTimerEnd]);

  useEffect(
    () => () => {
      setAuthCurrentScreen('auth-tabs');
      // setAuthTabIndex(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { t } = useTranslation();

  function handleBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    const index = e.currentTarget.tabIndex;

    setAuthCurrentScreen('auth-tabs');
    setAuthTabIndex(index);
  }

  async function handleSendAgain(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    resetTimeout(initialTimeout);
    try {
      setShowSendAgain(false);
      await agent.OobActions.sendVerificationEmail(currentSignUpData!.email);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthMessageScreen $height='min'>
      <HeaderMain>{t('youre_almost_there')}</HeaderMain>
      <TextBody>
        <Trans
          i18nKey='email_sent_signup'
          components={{
            1: <b />,
          }}
          values={{ email: currentSignUpData?.email }}
        />
        <TextBodyParagraph>{t('email_check_spam')}</TextBodyParagraph>
      </TextBody>
      {!showSendAgain && timeoutValue !== 0 ? (
        <TextBody>
          <TextResendCode>
            {t('resend_code_in')}
            &nbsp;
            {timeoutValue}
          </TextResendCode>
        </TextBody>
      ) : (
        <TextBody>
          <Button
            $pt='1.4rem'
            $pb='1.4rem'
            appearance='link'
            onClick={handleSendAgain}>
            {t('send_again')}
          </Button>
        </TextBody>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <AuthButtonsHolder>
        <Button
          size='large'
          onClick={handleBack}
          type='submit'
          /* eslint-disable-next-line */
          tabIndex={0}>
          {t('sign_in')}
        </Button>
        <Button
          $pt='1.4rem'
          $pb='1.4rem'
          appearance='text'
          /* eslint-disable-next-line */
          tabIndex={1}
          onClick={handleBack}>
          {t('back')}
        </Button>
      </AuthButtonsHolder>
    </AuthMessageScreen>
  );
};

export default observer(SignUpEmailSent);
