const baseColors = {
  blue0: '#f4f7fc',
  blue5: '#e5f4ff',
  blue10: '#DAEEFF',
  blue50: '#c1dbf0',
  blue65: '#b3ddff',
  blue80: '#008cff',
  blue100: '#0070cc',
  blue150: '#005FAD',

  yellow5: '#fffaeb',
  yellow10: '#fff5d9',
  yellow50: '#f3e1af',
  yellow80: '#ffcd45',
  yellow100: '#FFBE0A',
  yellow150: '#e3ac12',

  red5: '#FFF0F3',
  red10: '#FFE2E9 ',
  red50: '#EAAEBB',
  red65: '#FFB6C2',
  red80: '#ED5576',
  red100: '#E82A53',
  red150: '#BA2243',

  green5: '#f2fff6',
  green10: '#e7ffef',
  green50: '#b3dbbf',
  green80: '#6cd88e',
  green100: '#47ce72',
  green150: '#39a55b',

  grey0: '#ffffff',
  grey5: '#f5f6f7',
  grey10: '#f1f2f4',
  grey25: '#EAECEE',
  grey50: '#dcdde0',
  grey65: '#b7b9bd',
  grey80: '#96999e',
  grey100: '#75777b',
  grey150: '#1D1D1B',
};

const colors = {
  baseColors,
  namedColors: {
    white: baseColors.grey0,
    transparent: 'transparent',

    primary: '#EA0029',
    secondaryPrimary: '#FF002D',
    focus: '#FFB6C2',
    secondary: baseColors.yellow100,
    main: '#B00120',
  },

  componentColors: {
    userAvatar: '#EA0029',
  },
};

export const polonez = {
  colors: {
    ...colors,
    primaryButton: {
      color: colors.namedColors.white,
      backgroundColor: colors.namedColors.primary,
      borderColor: colors.namedColors.transparent,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.namedColors.white,
      backgroundColorHover: colors.namedColors.secondaryPrimary,
      borderColorHover: colors.namedColors.transparent,
      colorFocus: colors.namedColors.white,
      backgroundColorFocus: colors.namedColors.secondaryPrimary,
      borderColorFocus: colors.namedColors.transparent,
      outlineColorFocus: colors.baseColors.blue65,
      colorActive: colors.namedColors.white,
      backgroundColorActive: colors.namedColors.main,
      borderColorActive: colors.namedColors.transparent,
      colorDisabled: colors.namedColors.white,
      backgroundColorDisabled: '#F4C0CA',
      borderColorDisabled: colors.namedColors.transparent,
    },
    secondaryButton: {
      color: colors.baseColors.grey150,
      colorDisabled: colors.baseColors.grey65,
      backgroundColor: colors.namedColors.white,
      borderColor: colors.baseColors.grey50,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.namedColors.secondaryPrimary,
      backgroundColorHover: '#FEEDF0',
      borderColorHover: colors.namedColors.secondaryPrimary,
      colorFocus: colors.namedColors.secondaryPrimary,
      backgroundColorFocus: '#FEEDF0',
      borderColorFocus: colors.baseColors.red65,
      outlineColorFocus: colors.baseColors.blue65,
      colorActive: colors.namedColors.main,
      backgroundColorActive: '#FEEDF0',
      borderColorActive: colors.namedColors.main,
      backgroundColorDisabled: colors.namedColors.white,
      borderColorDisabled: colors.baseColors.grey50,
    },
    secondaryFilledButton: {
      backgroundColor: colors.namedColors.white,
      borderColor: colors.baseColors.grey50,
      backgroundColorHover: colors.namedColors.white,
      backgroundColorFocus: colors.namedColors.white,
      backgroundColorDisabled: colors.namedColors.white,
    },
    tertiaryButton: {
      color: colors.namedColors.primary,
      backgroundColor: colors.namedColors.white,
      borderColor: colors.namedColors.transparent,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.namedColors.secondaryPrimary,
      backgroundColorHover: colors.baseColors.grey5,
      borderColorHover: colors.namedColors.transparent,
      colorFocus: colors.namedColors.secondaryPrimary,
      backgroundColorFocus: colors.baseColors.grey5,
      borderColorFocus: colors.namedColors.transparent,
      outlineColorFocus: colors.baseColors.blue65,
      colorActive: colors.namedColors.main,
      backgroundColorActive: colors.baseColors.grey5,
      borderColorActive: colors.namedColors.transparent,
      colorDisabled: colors.baseColors.red50,
      backgroundColorDisabled: colors.namedColors.white,
      borderColorDisabled: colors.namedColors.transparent,
    },
    invertedDangerButton: {
      color: colors.baseColors.red100,
      backgroundColor: colors.namedColors.white,
      colorHover: colors.baseColors.red80,
      colorFocus: colors.baseColors.red80,
      colorActive: colors.baseColors.red150,
      backgroundColorActive: colors.baseColors.red10,
      borderColor: colors.baseColors.red100,
      borderColorActive: colors.baseColors.red150,
      borderColorHover: colors.baseColors.red80,
      borderColorFocus: colors.baseColors.red80,
      backgroundColorHover: colors.namedColors.white,
      backgroundColorFocus: colors.namedColors.white,
      colorDisabled: colors.baseColors.red50,
      backgroundColorDisabled: colors.namedColors.white,
      borderColorDisabled: colors.baseColors.red50,
    },
    textDangerButton: {
      color: colors.baseColors.red100,
      colorHover: colors.baseColors.red80,
      colorFocus: colors.baseColors.red80,
      outlineColorFocus: colors.namedColors.transparent,
      colorActive: colors.baseColors.red150,
      colorDisabled: colors.baseColors.red50,
    },
    dangerButton: {
      color: colors.namedColors.white,
      backgroundColor: colors.baseColors.red100,
      colorHover: colors.namedColors.white,
      colorFocus: colors.namedColors.white,
      colorActive: colors.namedColors.white,
      backgroundColorActive: colors.baseColors.red150,
      borderColor: colors.baseColors.red100,
      borderColorActive: colors.namedColors.transparent,
      borderColorHover: colors.namedColors.transparent,
      borderColorFocus: colors.baseColors.red50,
      backgroundColorHover: colors.baseColors.red80,
      backgroundColorFocus: colors.baseColors.red80,
      colorDisabled: colors.namedColors.white,
      backgroundColorDisabled: colors.baseColors.red50,
      borderColorDisabled: colors.namedColors.transparent,
    },
    textButton: {
      color: colors.baseColors.grey150,
      colorHover: colors.namedColors.secondaryPrimary,
      colorFocus: colors.namedColors.secondaryPrimary,
      outlineColorFocus: colors.namedColors.transparent,
      colorActive: colors.namedColors.main,
      colorDisabled: colors.baseColors.grey65,
    },
    linkButton: {
      color: colors.baseColors.blue100,
      colorHover: colors.baseColors.blue80,
      colorFocus: colors.baseColors.blue80,
      outlineColorFocus: colors.namedColors.transparent,
      colorActive: colors.baseColors.blue150,
      colorDisabled: colors.baseColors.blue50,
    },
    favoriteButton: {
      color: colors.baseColors.grey50,
      colorActive: colors.namedColors.secondary,
    },
    moreButton: {
      colorHover: colors.namedColors.secondaryPrimary,
      colorFocus: colors.namedColors.secondaryPrimary,
    },
    сheckbox: {
      backgroundColorChecked: colors.namedColors.primary,
      borderColorChecked: colors.namedColors.primary,
      backgroundColorFocus: colors.namedColors.secondaryPrimary,
      borderColorFocus: colors.namedColors.secondaryPrimary,
      backgroundColorHover: colors.namedColors.secondaryPrimary,
      borderColorHover: colors.namedColors.secondaryPrimary,
      shadowColor: colors.namedColors.focus,
    },
    radio: {
      colorActive: colors.namedColors.secondaryPrimary,
    },
    footerColumnTitle: {
      color: colors.namedColors.main,
    },
    progressBar: {
      backgroundColor: colors.namedColors.main,
    },
    link: {
      color: '#0061AF',
    },
    widgetCalculator: {
      backgroundColor: '#0261af',
    },
    normalText: {
      color: '#5A6673',
    },
    placeholderText: {
      color: '#E0E3E7',
    },
    passwordValidationMarker: {
      backgroundColor: '#EAECEE',
      highlightColor: '#FFCB45',
    },
    textCopyright: {
      color: '#2C2C2C',
    },
    textLabel: {
      color: '#7B8085',
    },
    toastError: {
      borderColor: '#ed5576e2',
    },
    headerMain: {
      color: '#334150',
    },
    liveChat: {
      backgroundColorActive: colors.namedColors.primary,
    },
    input: {
      errorColor: colors.baseColors.red80,
      borderColorFocus: colors.baseColors.grey80,
      labelColor: colors.baseColors.grey100,
    },
    select: {
      borderColorFocus: colors.baseColors.grey65,
    },
    tab: {
      backgroundColor: '#F5F5F7',
      color: colors.namedColors.primary,
    },
    body: {
      backgroundColor: '#F5F5F7',
    },
    clusterMarker: {
      boxShadowColor: 'rgba(255, 0, 45, 0.20)',
    },
    postName: {
      color: colors.baseColors.grey150,
    },
    trackingNumber: {
      color: colors.baseColors.blue100,
    },
  },
};
