import { FC, SVGProps, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import InputSearch from '../input-search/input-search.component';
import Sidebar from '../sidebar-right/sidebar.component';
import {
  Container,
  Content,
  InputSearchWithSidebarContainer,
} from './input-search-with-sidebar.styles';
import MenuItem from '../menu-item/menu-item.component';
import { MenuItems } from '../menu-item/menu-item.styles';
import InputMessage from '../input-message/input-message.component';
import EmptyList from '../empty-list/empty-list.component';
import { SidebarName } from '../../types/sidebar.types';
import { NO_VALIDATION_RULE } from '../../constants/validation';
import { ValidationRuleType } from '../../types/input-validation-types';

interface Props<T> {
  inputValue?: string;
  defaultOption?: T | null;
  name: SidebarName;
  placeholder?: string;
  sidebarTitle: string;
  sidebarInputPlaceholder: string;
  label?: string;
  tabIndex?: number;
  disabled?: boolean;
  debounceSidebarInputChange?: boolean;
  onMainInputClick?: () => void;

  // Can control icon displayed in main search input field. Magnifying glass by default
  inputIcon?: FC<SVGProps<SVGSVGElement>>;
  hideInputIcon?: boolean;

  // Allows user to modify selected option manually
  canModifyInput?: boolean;
  onInputChange?: (inputValue: string) => void;

  // where to get search options and how to filter them and how to display
  displayAllOptionsWithEmptyFilter?: boolean;
  // predicate which defines which search option will have 'selected' style
  isOptionSelected?: (
    currentOption: T | undefined | null,
    selectedOption: T | undefined | null
  ) => boolean;
  getSearchOptions: (inputFilterValue?: string) => Promise<T[] | undefined>;
  getKeyForSearchOption?: (option: T) => string;
  getDisplayValueForSearchOption?: (option: T) => string;
  onSearchOptionSelected?: (option: T) => void;
  onSearchFinished?: () => void;

  // selected input validation
  mainInputValidationPredicate?: (inputValue?: string) => boolean;
  errorMessage?: string;
  shouldValidateSearchQuery?: boolean;
  showSkeleton?: boolean;
  validationRule?: ValidationRuleType;
  isRateOrDeliveryChanged?: boolean;
  triggerValidation?: boolean;
}

// Generic search input which opens sidebar with search (non-field version)
function InputSearchWithSidebar<T>({
  name,
  inputValue,
  defaultOption,
  placeholder = '',
  label,
  sidebarTitle,
  sidebarInputPlaceholder,
  tabIndex,
  inputIcon,
  hideInputIcon,
  mainInputValidationPredicate,
  errorMessage,
  disabled,
  canModifyInput,
  debounceSidebarInputChange,
  onInputChange,
  onMainInputClick,
  displayAllOptionsWithEmptyFilter,
  isOptionSelected,
  getSearchOptions,
  getKeyForSearchOption,
  getDisplayValueForSearchOption,
  onSearchOptionSelected,
  onSearchFinished,
  shouldValidateSearchQuery,
  showSkeleton,
  validationRule = NO_VALIDATION_RULE,
  isRateOrDeliveryChanged,
  triggerValidation,
}: Props<T>) {
  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const [searchOptions, setSearchOptions] = useState<T[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState<T | undefined | null>(
    defaultOption
  );
  const [touched, setTouched] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(
    undefined
  );

  const handleBlur = () => {
    canModifyInput && setTouched(true);
  };

  useEffect(() => {
    if (displayAllOptionsWithEmptyFilter) {
      getSearchOptions().then((p) => setSearchOptions(p));
    }
    // eslint-disable-next-line
  }, [getSearchOptions]);

  useEffect(() => {
    if (mainInputValidationPredicate) {
      const validationFailed =
        touched && !!errorMessage && !mainInputValidationPredicate(inputValue);
      setIsError(validationFailed);
    } else {
      setIsError(touched && !!errorMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched, inputValue, isRateOrDeliveryChanged]);

  useEffect(() => {
    if (!triggerValidation) return;

    if (mainInputValidationPredicate) {
      const validationFailed =
        !!errorMessage && !mainInputValidationPredicate(inputValue);
      setIsError(validationFailed);
    } else {
      setIsError(!!errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  useEffect(() => {
    if (isRateOrDeliveryChanged) {
      setIsError(true);
    }
  }, [isRateOrDeliveryChanged]);

  const modifyListOfDisplayedOptions = async (value?: string) => {
    if (value || displayAllOptionsWithEmptyFilter) {
      setSearchOptions((await getSearchOptions(value)) ?? []);
      setSearchInputValue(value);
    } else {
      setSearchOptions(undefined);
    }
  };

  const handleSearchOptionSelected = (option: T) => {
    onSearchOptionSelected && onSearchOptionSelected(option);
    onSearchFinished && onSearchFinished();
    toggleSidebarByName(name);
    modifyListOfDisplayedOptions();
    setSelectedOption(option);
    setSearchInputValue('');
  };

  const handleMainInputChange = (targetValue: string) => {
    canModifyInput && onInputChange && onInputChange(targetValue);
  };

  const { t } = useTranslation();

  return (
    <InputSearchWithSidebarContainer
      $mb='0'
      $isDisabled={disabled}>
      <InputSearch
        name={name}
        inputValue={inputValue ?? ''}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={!canModifyInput}
        autoComplete='off'
        tabIndex={tabIndex}
        type='text'
        searchIconPosition='right'
        treatWholeInputAsSearch={!canModifyInput}
        isError={isError}
        onChange={handleMainInputChange}
        onBlur={handleBlur}
        onInputClick={onMainInputClick}
        hideInputIcon={hideInputIcon}
        InputIcon={inputIcon}
        onInputContainerClick={() =>
          !canModifyInput && !disabled && toggleSidebarByName(name)
        }
        onSearchIconClick={() =>
          canModifyInput && !disabled && toggleSidebarByName(name)
        }
        inputStyle={{ cursor: canModifyInput ? 'text' : 'pointer' }}
        showSkeleton={showSkeleton}
        validationRule={validationRule}
      />

      <Sidebar
        name={name}
        withBlurredBackground
        header={sidebarTitle}
        onClose={() => {
          modifyListOfDisplayedOptions();
          setTouched(true);
          setSearchInputValue('');
        }}>
        <Container>
          <InputSearch
            name={`search_${sidebarTitle}`}
            placeholder={sidebarInputPlaceholder}
            focusOnOpen
            searchIconPosition='left'
            disableSearchIconHover
            showClearInputButton
            debounceInputChange={debounceSidebarInputChange}
            shouldValidateSearchQuery={shouldValidateSearchQuery}
            onChange={(targetValue) =>
              modifyListOfDisplayedOptions(targetValue)
            }
            showSkeleton={showSkeleton}
            validationRule={validationRule}
          />
          <Content>
            {searchOptions && searchOptions.length > 0 ? (
              <MenuItems>
                {searchOptions.map((option) => (
                  <MenuItem
                    key={
                      getKeyForSearchOption
                        ? getKeyForSearchOption(option)
                        : (option as string)
                    }
                    isSelected={
                      isOptionSelected &&
                      isOptionSelected(option, selectedOption)
                    }
                    onClick={() => handleSearchOptionSelected(option)}>
                    {getDisplayValueForSearchOption
                      ? getDisplayValueForSearchOption(option)
                      : (option as string)}
                  </MenuItem>
                ))}
              </MenuItems>
            ) : (
              <EmptyList
                isSidebar
                imageType='loupe'
                title={searchInputValue ? t('no_search_results') : undefined}
                description={
                  searchInputValue
                    ? t('please_try_again')
                    : t('havent_searched_yet')
                }
              />
            )}
          </Content>
        </Container>
      </Sidebar>

      {isError && (
        <InputMessage type='error'>{t(errorMessage || '')}</InputMessage>
      )}
    </InputSearchWithSidebarContainer>
  );
}

export default observer(InputSearchWithSidebar);
