import { Step } from '../../../models/parcelCreationFlowNavigation';

export const isStepActive = (step: Step, currentStep?: string): boolean =>
  step.name === currentStep;

export const isStepCompleted = (
  index: number,
  currentStepIndex: number
): boolean => currentStepIndex > index;

export const isLastStep = (step: Step, steps: Step[]): boolean =>
  step.name === steps.at(-1)?.name;

export const shouldShowDots = (
  step: Step,
  index: number,
  isTwoStepFlow: boolean,
  currentStepIndex: number
): boolean => {
  const showDotsForStep1 = isTwoStepFlow && index === 0 && currentStepIndex < 1;
  const showDotsForStep2 = isTwoStepFlow && step.name === 'delivery-option';
  return showDotsForStep1 || showDotsForStep2;
};
