import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Header,
  Content,
  Description,
  ButtonWrapper,
  SpanDescription,
  BadgeContainer,
  CategoriesContainer,
  CategoryButton,
  CategoryButtonText,
  SettingsFeedbackContainer,
  FormFeedback,
} from './leave-feedback.styles';
import { HeaderSecondary } from '../../common/typography/typography.styles';
import StarRating from '../../features/leave-feedback/star-rating/star-rating.component';
import Button from '../../common/button/button.component';
import BadgeButton from '../../common/badge-button/badge-button.component';
import { useStore } from '../../stores/store';
import FormFieldTextArea from '../../common/form-field-text-area/form-field-text-area.component';
import { AppRoute } from '../../constants/route';
import { generateMyRoute } from '../../utils/uri/uri.utils';
import { FeedbackData } from '../../models/feedBackModel';
import { SERVICE_LEAVE_FEEDBACK, SERVICE_PARCEL_LIST } from './config';
import Spinner from '../../common/spinner/spinner.component';
import {
  filterAndSortSupportLinks,
  getHref,
  getIcon,
  handleClick,
} from '../../utils/live-chat/live-chat.utils';
import {
  capitalizeFirstLetter,
  copyToClipboard,
} from '../../utils/generic/generic.utils';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { SupportLinkType } from '../../models/countriesModel';
import ParcelNumberSelect from '../../features/leave-feedback/parcel-number-select/parcel-number-select.component';
import { useNavigationBlocker } from '../../hooks/use-navigation-blocker.hook';

const FormChangeHandler = () => {
  const {
    values: { rate },
    validateForm,
  } = useFormikContext<FeedbackData>();

  useEffect(() => {
    if (rate <= 3) validateForm();
  }, [rate, validateForm]);

  return null;
};

const LeaveFeedback = () => {
  const {
    navStore: { setSelectedService },
    commonStore: { toastSuccess },
    parcelListStore: { fetchParcels },
    feedbackStore: {
      rate,
      categories,
      selectedCategory,
      shipmentId,
      comment,
      send_from: sendFrom,
      setRate,
      setSelectedCategory,
      setShipmentId,
      setComment,
      submitFeedback,
      getLimit,
      limit,
      isLoadingLimit,
      resetFeedback,
      setIsFeedbackInProgress,
      isFeedbackInProgress,
    },
    localizationsStore: { selectedCountry },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (limit === 0 || limit === null) {
      getLimit();
    }
  }, [getLimit, limit]);

  const { t } = useTranslation();
  const [showContactInfo, setShowContactInfo] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsFeedbackInProgress(true);
    return () => {
      resetFeedback();
    };
  }, [resetFeedback, setIsFeedbackInProgress]);

  const handleSupportClick = () => {
    setShowContactInfo(!showContactInfo);
  };

  const handleSubmit = async (values: FeedbackData) => {
    setIsFeedbackInProgress(false);

    const response = await submitFeedback(values);

    await getLimit();

    navigate(generateMyRoute(AppRoute.PARCEL_LIST));
    setSelectedService(SERVICE_PARCEL_LIST);

    const fullMessage = response?.response_message;

    if (!fullMessage) {
      return;
    }

    const [primaryMessage, ...rest] = fullMessage.split(/(?<=!)\s/);
    const secondaryMessage = rest.join(' ');

    resetFeedback();

    toastSuccess({
      primaryMessage,
      secondaryMessage,
    });
  };

  const customValidationSchema = (fieldName: string) => {
    if (rate > 3) return Yup.mixed().notRequired();

    switch (fieldName) {
      case 'comment':
        return Yup.string()
          .min(10, t('min_x_characters', { count: 10 }))
          .required(t('this_field_cannot_be_empty'));
      case 'categories':
        return Yup.array().min(1);
      case 'shipment_id':
        return Yup.number().notRequired();
      default:
        return Yup.string().notRequired();
    }
  };

  const validationSchema = Yup.object({
    rate: Yup.number().required(),
    comment: customValidationSchema('comment'),
    categories: customValidationSchema('categories'),
    shipment_id: customValidationSchema('shipment_id'),
    send_from: Yup.string().required(),
  });

  useNavigationBlocker({
    Route: AppRoute.LEAVE_FEEDBACK,
    isInProgress: isFeedbackInProgress,
    setIsInProgress: setIsFeedbackInProgress,
    service: SERVICE_LEAVE_FEEDBACK,
  });

  const initialValues = {
    rate: rate || 5,
    categories: selectedCategory ? [selectedCategory] : [],
    shipment_id: shipmentId,
    comment,
    send_from: sendFrom,
  };

  const contactSupportText = t('leave_feedback_contact_support_description');
  const [primaryText, secondaryText] = contactSupportText.split(/(?<=\.)\s+/);

  const links = filterAndSortSupportLinks(selectedCountry?.support);

  if (isLoadingLimit) {
    return <Spinner isFullScreen />;
  }

  const renderBadgeContainer = () => (
    <BadgeContainer>
      {links.map(({ url, type }) => (
        <BadgeButton
          key={url}
          icon={getIcon(type as SupportLinkType)}
          text={
            type === 'viber' || type === 'telegram'
              ? capitalizeFirstLetter(type)
              : url
          }
          onClick={() =>
            handleClick({
              url,
              type: type as SupportLinkType,
              isMobile,
              copyToClipboard,
            })
          }
          href={getHref(url, type as SupportLinkType, isMobile)}
        />
      ))}
    </BadgeContainer>
  );

  const handleRateChange = (index: number) => {
    setRate(index + 1);
    if (index + 1 > 3) {
      setSelectedCategory(null);
      setShipmentId(null);
      setComment(null);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      {({ values, isSubmitting, setFieldValue, isValid }) => (
        <FormFeedback noValidate>
          <Container>
            {limit === 0 ? (
              <Content>
                <Description>{primaryText}</Description>
                <Description>{secondaryText}</Description>
                {renderBadgeContainer()}
              </Content>
            ) : (
              <>
                <Header>
                  <HeaderSecondary
                    as='h1'
                    $size='mediumBig'>
                    {t('leave_feedback_raiting_text')}
                  </HeaderSecondary>
                  <StarRating
                    name='rate'
                    rating={values.rate}
                    onRate={handleRateChange}
                  />
                </Header>
                <Content>
                  <Description>
                    {t('leave_feedback_description_text')}{' '}
                    <SpanDescription onClick={handleSupportClick}>
                      {t('customer_support')}
                    </SpanDescription>
                  </Description>
                  {showContactInfo && renderBadgeContainer()}
                  {rate <= 3 && (
                    <SettingsFeedbackContainer>
                      <HeaderSecondary
                        as='h2'
                        $size='medium'>
                        {t('leave_feedback_category_dropdown')}
                      </HeaderSecondary>
                      <CategoriesContainer>
                        {categories?.map((category) => (
                          <CategoryButton
                            onClick={() => {
                              setFieldValue('categories', [category.value]);
                              setSelectedCategory(category.value);
                            }}
                            key={category.value}
                            $isActive={category.value === selectedCategory}>
                            <CategoryButtonText>
                              {t(category.label)}
                            </CategoryButtonText>
                          </CategoryButton>
                        ))}
                      </CategoriesContainer>
                      {selectedCategory && (
                        <ParcelNumberSelect
                          name='shipment_id'
                          placeholder={t('select_parcel_number')}
                        />
                      )}

                      <FormFieldTextArea
                        label={t('comment')}
                        name='comment'
                        placeholder={t('leave_feedback_comment_placeholder')}
                        maxLength={1000}
                        showValidationError
                        validationMethod={
                          selectedCategory ? 'touched' : 'default'
                        }
                        disabled={!selectedCategory}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </SettingsFeedbackContainer>
                  )}
                  <ButtonWrapper>
                    <Button
                      isLoading={isSubmitting}
                      size='large'
                      fullWidth
                      disabled={!isValid}
                      type='submit'>
                      {t('leave_feedback_submit')}
                    </Button>
                  </ButtonWrapper>
                </Content>
              </>
            )}
          </Container>
          <FormChangeHandler />
        </FormFeedback>
      )}
    </Formik>
  );
};

export default observer(LeaveFeedback);
