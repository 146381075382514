import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import SignatureField from '../../../common/signature-field/signature-field.component';
import { useStore } from '../../../stores/store';
import { isSignatureSupported } from '../../../utils/parcel-creation/parcel-creation-utils';
import { Title } from './signature-details-summary.styles';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';

interface Props {
  children?: React.ReactNode;
}

const SignatureDetailsSummary = ({ children }: Props) => {
  const {
    parcelCreationStore: {
      submitSignature,
      setSignature,
      fetchSignature,
      signature,
      signatureIsPresent,
      countryDeparture,
      isNextStepButtonLoading,
      isSignButtonClicked,
      setIsSignButtonClicked,
    },
  } = useStore();

  const isMobile = useIsMobile();

  const { t } = useTranslation();

  useEffect(() => {
    if (signatureIsPresent) fetchSignature();
  }, [fetchSignature, signatureIsPresent]);

  if (!isSignatureSupported(countryDeparture?.countryCode || '')) {
    return null;
  }

  const handleConfirm = async (
    newSignature: FormData,
    signatureUrl: string
  ) => {
    await submitSignature(newSignature);
    setSignature(signatureUrl);
  };

  const renderSignatureField = (isMobileLayout: boolean) => (
    <>
      <ServicesSubSectionHeader>{t('signature')}</ServicesSubSectionHeader>
      <Title>{t('adding_signature_required_step')}</Title>
      <SignatureField
        signature={signature}
        onConfirm={handleConfirm}
        onClear={() => {
          setSignature('');
          setIsSignButtonClicked(false);
        }}
        isSignatureProcessing={isNextStepButtonLoading}
        isSignButtonClicked={isSignButtonClicked}>
        {!isMobileLayout && children}
      </SignatureField>
    </>
  );

  return isMobile ? (
    <ServicesSubSectionContainer $marginTop='-5rem'>
      {renderSignatureField(true)}
    </ServicesSubSectionContainer>
  ) : (
    renderSignatureField(false)
  );
};

export default observer(SignatureDetailsSummary);
