import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ParcelCreationProgressContainer } from './parcel-creation-progress.styles';
import ParcelCreationBottom from '../parcel-creation-bottom/parcel-creation-bottom.component';
import ParcelStepTitle from '../parcel-step-title/parcel-step-title.component';
import { ReactComponent as ParcelStepDots } from '../../../assets/vertical-dots-icon.svg';
import { PaddingContainer } from '../parcel-creation-bottom/parcel-creation-bottom.styles';
import {
  findStepIndex,
  Step,
} from '../../../models/parcelCreationFlowNavigation';
import {
  isStepActive,
  isStepCompleted,
  isLastStep,
  shouldShowDots,
} from './helpers';
import { AppRoute } from '../../../constants/route';

const ParcelCreationProgress = () => {
  const {
    parcelCreationStore: { navigation, isDraftEditing },
  } = useStore();

  const { t } = useTranslation();

  const steps = navigation.map.filter(
    (step) => step.name !== AppRoute.SIGNATURE
  );
  const currentStep = navigation.current?.name;
  const currentStepIndex = currentStep
    ? findStepIndex(navigation, currentStep)
    : -1;
  const isTwoStepFlow = steps.length === 2;

  const title = isDraftEditing ? t('edit_parcel') : t('create_a_parcel');

  const renderStep = (step: Step, index: number) => {
    const stepIndex = index + 1;
    const isActive = isStepActive(step, currentStep);
    const isCompleted = isStepCompleted(index, currentStepIndex);
    const lastStep = isLastStep(step, steps);
    const showDots = shouldShowDots(
      step,
      index,
      isTwoStepFlow,
      currentStepIndex
    );

    return (
      <Fragment key={step.name}>
        <ParcelStepTitle
          step={step}
          stepIndex={stepIndex}
          isActive={isActive}
          isCompleted={isCompleted}
          isLastStep={lastStep}
          tooltip={false}
          hideSeparator={showDots}
        />
        {showDots && (
          <PaddingContainer
            key={`dots-${index}`}
            $pl='1.1rem'
            $pt='0.4rem'>
            <ParcelStepDots />
          </PaddingContainer>
        )}
      </Fragment>
    );
  };

  const renderedSteps =
    steps.length > 2
      ? steps.map(renderStep)
      : steps.map((step, i) =>
          i <= currentStepIndex ? renderStep(step, i) : null
        );

  return (
    <ParcelCreationProgressContainer id='parcelProgressDesktop'>
      <ParcelCreationProgressContainer
        $pt='2.4rem'
        $pr='2.4rem'
        $pb='2.4rem'
        $pl='2.4rem'>
        <HeaderSecondary $size='medium'>{title}</HeaderSecondary>
        {renderedSteps}
      </ParcelCreationProgressContainer>
      <ParcelCreationBottom />
    </ParcelCreationProgressContainer>
  );
};

export default observer(ParcelCreationProgress);
