import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ParcelDimensions } from '../../../models/shipmentCountryInfo';
import { IDeliveryLimitations } from '../../../models/parcelDimensions';
import { useStore } from '../../../stores/store';
import { isObjectsEqual } from '../../../utils/generic/generic.utils';

export interface FormValues {
  weight: string;
  size_x: string;
  size_y: string;
  size_z: string;
}

interface ChangeHandlerProps {
  setParcelDimensions: (dimensions: ParcelDimensions | null) => void;
  deliveryLimitations?: IDeliveryLimitations;
}

const ChangeHandler = ({
  setParcelDimensions,
  deliveryLimitations,
}: ChangeHandlerProps) => {
  const { values, validateForm } = useFormikContext<FormValues>();
  const {
    parcelCreationStore: { parcelDimensions },
  } = useStore();

  useEffect(() => {
    const newDimensions: ParcelDimensions = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key as keyof FormValues] =
          typeof value === 'string' ? parseFloat(value) || 0 : value;
        return acc;
      },
      {} as ParcelDimensions
    );

    if (!isObjectsEqual(newDimensions, parcelDimensions)) {
      setParcelDimensions(newDimensions);
    }
  }, [parcelDimensions, setParcelDimensions, values]);

  useEffect(() => {
    validateForm();
  }, [deliveryLimitations, validateForm]);

  return null;
};

export default observer(ChangeHandler);
