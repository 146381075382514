import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Container, Input, Inputs } from './otp-input.styles';
import { TextBody } from '../typography/typography.styles';
import InputMessage from '../input-message/input-message.component';
import { useTimeout } from '../../hooks/use-timeout';
import Button from '../button/button.component';
import { formatTimeout } from '../../utils/format-timeout/format-timeout.utils';
import { theme } from '../../styles/themes';

interface Props {
  textAlign?: string;
  fullWidth?: boolean;
  title?: string;
  errorMessage: string;
  titleColor?: string;
  numOfDigits?: number;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  handleSendAgain?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
}

const getValue = (otp: string[]) => otp.join('');

const OtpInput = ({
  textAlign = 'left',
  fullWidth = false,
  title,
  titleColor,
  errorMessage,
  isInvalid = false,
  numOfDigits = 4,
  onChange,
  handleSendAgain,
}: Props) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(() => new Array(numOfDigits).fill(''));
  const otpBoxRef = useRef<(HTMLInputElement | null)[]>([]);

  const { resetTimeout, timeoutValue } = useTimeout(60);

  const handleChange = (value: string, id: number) => {
    const newOtp = [...otp];
    newOtp[id] = value;
    setOtp(newOtp);

    if (value && id < numOfDigits - 1) {
      otpBoxRef?.current[id + 1]?.focus();
    }

    onChange && onChange(getValue(newOtp));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: number
  ) => {
    const prevInput = otpBoxRef.current[id - 1];
    const currentInputValue = otpBoxRef.current[id]?.value;

    if (event.key === 'Backspace' && id > 0 && currentInputValue === '') {
      event.preventDefault();
      prevInput?.focus();
    }

    if (currentInputValue && /\d/.test(event.key)) {
      event.preventDefault();
      handleChange(event.key, id);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const newOtp = paste.slice(0, numOfDigits).split('');
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      newOtp.forEach((value, index) => {
        updatedOtp[index] = value;
        if (otpBoxRef.current[index]) {
          otpBoxRef.current[index]!.value = value;
        }
      });
      onChange(getValue(updatedOtp));
      return updatedOtp;
    });

    const nextIndex = Math.min(newOtp.length, numOfDigits - 1);
    otpBoxRef?.current[nextIndex]?.focus();
  };

  const handleResendCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    resetTimeout();
    if (handleSendAgain) {
      handleSendAgain(e);
    }
  };

  return (
    <Container>
      {title && (
        <TextBody
          $color={titleColor}
          $fontWeight={400}>
          {title}
        </TextBody>
      )}
      <Inputs
        $isInvalid={isInvalid}
        $numOfDigits={numOfDigits}>
        {otp.map((value, index) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            maxLength={1}
            ref={(ref) => {
              if (otpBoxRef.current.length === numOfDigits) return;
              otpBoxRef.current[index] = ref;
            }}
            value={value}
            onChange={(event) => handleChange(event.target.value, index)}
            onKeyDown={(event) => {
              handleKeyDown(event, index);
            }}
            onPaste={handlePaste}
          />
        ))}
      </Inputs>
      {isInvalid && <InputMessage type='error'>{errorMessage}</InputMessage>}

      <TextBody
        $mt='1rem'
        $color={theme.colors.baseColors.grey100}
        $textAlign={textAlign}
        $fontWeight={400}>
        {timeoutValue !== 0
          ? `${t('resend_code_in')} ${formatTimeout(timeoutValue)}`
          : t('the_time_has_expired_click_send_again')}
      </TextBody>

      {timeoutValue === 0 && (
        <Button
          $ml='auto'
          fullWidth={fullWidth}
          appearance='tertiary'
          onClick={handleResendCode}>
          {t('send_again')}
        </Button>
      )}
    </Container>
  );
};

export default observer(OtpInput);
