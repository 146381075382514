import styled from 'styled-components';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { BasePaddingComponent } from '../../../common/base-padding/base-padding.component';
import { CloseButtonContainer } from '../../../common/close-button/close-button.styles';

export const ParcelCreationProgressContainer = styled(
  BasePaddingComponent('div')
)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  min-width: 22rem;

  ${HeaderSecondary} {
    margin-bottom: 2.4rem;
  }

  .separatorLine {
    width: 42rem;
    height: 1px;
    background-color: #dcdde0;
    transform: translateX(-24px);
    margin-top: 2.4rem;
  }
`;

export const ParcelCreationMobileHeader = styled.div`
  position: relative;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 0.8rem;

  ${CloseButtonContainer} {
    position: absolute;
    margin-right: 0;
    right: 0.4rem;
    top: 1rem;
  }
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 1.6rem;
`;

export const ParcelCreationProgressMobileContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  position: absolute;
  top: -12.4rem;
  left: -1.6rem;
  width: 100vw;
  padding: 0 1.6rem 0.8rem 1.6rem;
`;
