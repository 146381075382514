import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce';
import {
  ClearButton,
  SignatureContent,
  Wrapper,
} from './signature-field.styles';
import Button from '../button/button.component';
import { meest } from '../../styles/meest';
import { FlexContainer } from '../flex-container/flex-container.styles';
import InputMessage from '../input-message/input-message.component';

interface Props {
  signature?: string;
  onConfirm?: (signature: FormData, signatureUrl: string) => void;
  onClear?: () => void;
  children?: React.ReactNode;
  isSignatureProcessing?: boolean;
  isSignButtonClicked?: boolean;
}

const SignatureField = ({
  signature,
  onConfirm,
  onClear,
  children,
  isSignatureProcessing,
  isSignButtonClicked,
}: Props) => {
  const [isSignatureSubmitted, setIsSignatureSubmitted] = useState(!!signature);
  const [isEmpty, setIsEmpty] = useState(true);
  const canvasRef = useRef<SignatureCanvas | null>(null);

  const { t } = useTranslation();

  const handleClear = () => {
    if (!canvasRef?.current) return;

    canvasRef.current.clear();
    setIsEmpty(true);
    onClear?.();
  };

  const submitSignature = () => {
    if (!canvasRef?.current) return;

    setIsSignatureSubmitted(true);
    canvasRef.current.getCanvas().toBlob((blob) => {
      if (!blob) return;

      const file = new File([blob], 'signature.jpeg', { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('signature', file);

      const signatureDataUrl = canvasRef.current?.getCanvas().toDataURL();
      if (!signatureDataUrl) return;

      onConfirm?.(formData, signatureDataUrl);
    });
  };

  const handleReEnter = () => {
    setIsSignatureSubmitted(false);
    handleClear();
  };

  useEffect(() => {
    if (!canvasRef?.current || !signature) return;

    setIsEmpty(false);
    canvasRef.current.fromDataURL(signature);
  }, [signature]);

  return (
    <Wrapper
      $isDisabled={isSignatureSubmitted}
      $error={isSignButtonClicked && isEmpty}>
      <SignatureContent>
        <SignatureCanvas
          onEnd={debounce(submitSignature, 850)}
          clearOnResize={false}
          ref={canvasRef}
          penColor='#000'
          canvasProps={{ className: 'canvas' }}
        />
        {isSignButtonClicked && isEmpty && (
          <InputMessage type='alert'>
            {t('this_field_cannot_be_empty')}
          </InputMessage>
        )}
        <FlexContainer
          $mt='2.4rem'
          $align='center'
          $justify='space-between'
          $gap='2.4rem'>
          <ClearButton>
            <Button
              theme={meest}
              disabled={isEmpty || isSignatureProcessing}
              onClick={handleReEnter}
              appearance='text'>
              {t('clear')}
            </Button>
          </ClearButton>
          {children}
        </FlexContainer>
      </SignatureContent>
    </Wrapper>
  );
};

export default SignatureField;
