import { observer } from 'mobx-react-lite';
import { useModal } from './use-modal.hook';
import DeleteAccountDialog from '../../routes/delete-account/components/delete-account-dialog.component';
import ModalLeaveParcelCreation from '../../features/parcel-creation/modal-leave-parcel-creation/modal-leave-parcel-creation.component';
import DeleteItemDialog from '../../features/parcel-creation/parcel-items-management/delete-item-dialog.component';
import QuitEditItemDialog from '../../features/parcel-creation/parcel-items-management/quit-edit-item-dialog.component';
import AllowLocationAccessDialog from '../../features/parcel-creation/parcel-pudo-search-sidebar/allow-location-access-dialog.component';
import CodeGenerationDialog from '../../features/shipments/details-sidebar/components/code-generation-dialog.component';
import CreateAccountModal from '../../features/shipments/create-account-modal/create-account-modal.component';
import PaymentModal from '../payment-modal/payment-modal.component';
import ParcelManual from '../../features/shipments/parcel-manual/parcel-manual.component';
import { TModals } from './modal-context';
import OnboardingManual from '../../features/user/onboarding-manual.component';
import { useStore } from '../../stores/store';
import LeaveFeedbackModal from '../../features/shipments/leave-feedback-modal/leave-feedback-modal.component';
import NoDeliveryOptionModal from '../../features/shipments/no-delivery-option-modal/no-delivery-option-modal.component';
import SignatureModal from '../../features/shipments/signature-modal/signature-modal.component';

const Modals: Record<TModals, React.FC<any>> = {
  parcelManual: ParcelManual,
  onboardingManual: OnboardingManual,
  payment: PaymentModal,
  createAccount: CreateAccountModal,
  deleteAccount: DeleteAccountDialog,
  leaveThisPage: ModalLeaveParcelCreation,
  deleteItem: DeleteItemDialog,
  quitEditItem: QuitEditItemDialog,
  allowLocationAccess: AllowLocationAccessDialog,
  codeGeneration: CodeGenerationDialog,
  leaveFeedback: LeaveFeedbackModal,
  noDeliveryOptionModal: NoDeliveryOptionModal,
  signature: SignatureModal,
};

const modalsWithMobileView: TModals[] = ['parcelManual', 'onboardingManual'];

const ModalRoot = () => {
  const { currentModal, closeModal } = useModal();
  const {
    navStore: { closeSidebarByName },
  } = useStore();
  if (!currentModal) return null;

  const { name, id, props, closesManually } = currentModal;
  const Component = Modals[name];

  return (
    <Component
      id={id}
      key={id}
      {...props}
      isOpen={props?.isOpen ?? true}
      onClose={() => {
        if (!closesManually) {
          closeModal(id);
          if (modalsWithMobileView.includes(name)) {
            closeSidebarByName(name);
          }
        }
        props?.onClose?.();
      }}
    />
  );
};

export default observer(ModalRoot);
