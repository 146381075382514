import styled from 'styled-components';
import mabBackground from '../../../assets/map-background.png';
import { InputSearchWithSidebarContainer } from '../../../common/input-search-with-sidebar/input-search-with-sidebar.styles';
import {
  SidebarBody,
  SidebarInner,
} from '../../../common/sidebar-right/sidebar.styles';
import { FontSizes } from '../../../common/typography/typography.styles';
import { InputSelect } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.styles';
import {
  InputSearchContainer,
  SearchIconContainer,
} from '../../../common/input-search/input-search.styles';
import {
  PudoLeftColumn,
  PudoListItemContainer,
} from '../pudo-list-item/pudo-list-item.styles';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../common/button/button.styles';
import { scrollStyles } from '../../../common/scroll/scroll.styles';
import { SelectOption } from '../parcel-delivery-options-tabs/parcel-delivery-options-tabs.styles';

export const PudoSearchWithSidebarContainer = styled(
  InputSearchWithSidebarContainer
)`
  ${SidebarBody} {
    overflow: hidden;
  }

  ${InputSelect} {
    padding: 1rem 4.4rem 1rem 1.6rem !important;

    ${PudoListItemContainer} {
      ${PudoLeftColumn} {
        flex: 0 0 100%;
      }
    }
  }

  ${InputSearchContainer} {
    input {
      height: 4.8rem;
      max-height: 4.8rem;
    }

    ${SearchIconContainer} {
      transform: translateY(-67%);
    }

    @media (max-width: 768px) {
      input {
        height: 4rem;
        max-height: 4rem;
      }

      ${SearchIconContainer} {
        transform: translateY(-50%);
      }
    }
  }
`;

export const NoResultsTextBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  span:nth-child(1) {
    font-size: ${FontSizes.mediumPlus};
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 133.333% */
    letter-spacing: 0.5px;
    display: inline-block;
  }

  span:nth-child(2) {
    font-size: ${FontSizes.normal};
    font-style: normal;
    font-weight: 400;
    line-height: 2rem; /* 142.857% */
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.baseColors.grey100};
    display: inline-block;
  }
`;

export const PudoSearchSidebarContent = styled(SidebarInner)`
  width: 100%;
  /* height: calc(100dvh - 73px); */

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 480px;
  }
`;

interface PudoMapContainerProps {
  $hidden?: boolean;
}

export const PudoMapContainer = styled.div<PudoMapContainerProps>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;

  @media screen and (max-width: 767px) {
    transform: scaleX(1.06) translateX(-11px);
    transform-origin: left;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${mabBackground}) no-repeat center center;
    background-size: cover;
    filter: blur(5px); /* Apply the blur effect */
    z-index: -1; /* Place the pseudo-element behind the content */
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: ${FontSizes.mediumBig};
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 100% */
    letter-spacing: 0.5px;
  }
`;

export const ShowMapButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 9rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 0.8rem;
  min-width: 8.5rem;
  min-height: 3.2rem;
  font-weight: 500;
`;

export const PudoSearchContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 1.2rem 1.2rem 0 1.2rem;
  display: grid;
  grid-template-rows: auto auto 1fr;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  ${SelectOption} {
    margin-bottom: 1.2rem;
  }
`;

export const CityButtonWrapper = styled.span`
  ${TextButton} {
    padding: 0;
    min-height: 3.2rem;
    --button-color: ${({ theme }) => theme.colors.baseColors.grey150};
  }
`;

export const LocationBlock = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchAndFilterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.6rem;
  ${SecondaryButton} {
    height: 4rem;
    --button-border-color: ${({ theme }) => theme.colors.baseColors.grey50};
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 2.4rem;
  }
`;

export const TogglePudoDisplayModeButtonContainer = styled.div`
  position: fixed;
  display: inline-block;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3.2rem;
  z-index: 20001;
`;

export const FilteredPudos = styled.div`
  height: 100%;
  overflow: auto;
  ${scrollStyles};
`;

export const CnahgeCitySidebarBody = styled(SidebarInner)`
  display: grid;
  gap: 1.6rem;
  grid-template-rows: auto 1fr;
  padding: 2.4rem;
`;
