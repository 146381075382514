import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  HeaderMain,
  TextBody,
} from '../../../common/typography/typography.styles';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';
import Button from '../../../common/button/button.component';
import Checkbox from '../../../common/checkbox/checkbox-plain.component';
import TermsOfUseTranslation from '../../../common/terms-of-use-translation/terms-of-use-translation.component';

export interface WelcomeScreenCheckboxes {
  termsAndConditions: boolean;
  newsSubscription: boolean;
}

interface Props {
  isLoading?: boolean;
  onSubmit: (checkboxes: WelcomeScreenCheckboxes) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
}

const WelcomeScreenLayout = ({
  isLoading,
  onSubmit,
  text,
  onBackClick,
}: Props) => {
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState<WelcomeScreenCheckboxes>({
      termsAndConditions: false,
      newsSubscription: false,
    });
  const { t } = useTranslation();
  const handleChange = (name: keyof WelcomeScreenCheckboxes) => () => {
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleSubmit = () => {
    onSubmit({
      termsAndConditions: selectedCheckboxes.termsAndConditions,
      newsSubscription: selectedCheckboxes.newsSubscription,
    });
  };

  return (
    <AuthMessageScreen $height='fit-content'>
      <HeaderMain>{t('welcome')}</HeaderMain>
      <TextBody>{t(text)}</TextBody>

      <div>
        <Checkbox
          isChecked={selectedCheckboxes.termsAndConditions}
          onChange={handleChange('termsAndConditions')}
          name='termsAndConditions'>
          <TermsOfUseTranslation />
        </Checkbox>

        <Checkbox
          isChecked={selectedCheckboxes.newsSubscription}
          onChange={handleChange('newsSubscription')}
          name='newsSubscription'>
          {t('news_subscription')}
        </Checkbox>
      </div>

      <Button
        isLoading={isLoading}
        onClick={handleSubmit}
        disabled={!selectedCheckboxes.termsAndConditions}>
        {t('confirm')}
      </Button>
      <Button
        appearance='text'
        onClick={onBackClick}>
        {t('cancel')}
      </Button>
    </AuthMessageScreen>
  );
};

export default WelcomeScreenLayout;
