import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { Description, Header } from './create-account-modal.styles';
import Button from '../../../common/button/button.component';
import { AppRoute } from '../../../constants/route';
import {
  generateLeaveFeedbackRoute,
  generateLocaleRoute,
} from '../../../utils/uri/uri.utils';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isFeedbackModal?: boolean;
}

const CreateAccountModal = ({
  isOpen = false,
  onClose,
  isFeedbackModal,
}: Props) => {
  const { t } = useTranslation();
  const {
    navStore: { setAuthTabIndex },
  } = useStore();
  const { closeModal } = useModal();

  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      width='40rem'
      padding='3.2rem 4rem'
      displayButtons={false}>
      <Header>
        {isFeedbackModal
          ? t('sign_in_to_continue')
          : t('do_you_want_create_account')}
      </Header>
      <Description>
        {isFeedbackModal
          ? t('sign_in_to_feedback_text')
          : t('by_registering_benefits_text')}
      </Description>
      <Button
        $mb='1.6rem'
        onClick={() => {
          setAuthTabIndex(1);
          closeModal(Modals.CREATE_ACCOUNT);
        }}
        href={generateLocaleRoute(
          isFeedbackModal
            ? `${AppRoute.AUTH}?redirect=${generateLeaveFeedbackRoute()}`
            : AppRoute.AUTH
        )}>
        {isFeedbackModal ? t('sign_in') : t('yes_create_my_account')}
      </Button>
      <Button
        onClick={onClose}
        appearance='tertiary'>
        {isFeedbackModal ? t('cancel') : t('no_ask_me_later')}
      </Button>
    </ModalDialog>
  );
};

export default observer(CreateAccountModal);
