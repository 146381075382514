import { observer } from 'mobx-react-lite';
import ReCaptcha from 'react-google-recaptcha';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Container, RecaptchaWrapper } from './recaptcha.styles';
import { useStore } from '../../stores/store';
import { envConfig } from '../../config';
import InputMessage from '../input-message/input-message.component';

interface Props {
  onChange: (value: string | null) => void;
  onExpired: () => void;
  isError?: boolean;
}

const ReCAPTCHA = ({ isError, onChange, onExpired }: Props) => {
  const {
    localizationsStore: { selectedLocalization },
  } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  return (
    <Container $isLoading={isLoading}>
      {isLoading && (
        <Skeleton
          width='304px'
          height='78px'
        />
      )}
      <RecaptchaWrapper $isError={isError}>
        <ReCaptcha
          asyncScriptOnLoad={() => {
            setIsLoading(false);
          }}
          key={selectedLocalization.code}
          hl={selectedLocalization.code}
          onExpired={onExpired}
          onChange={onChange}
          sitekey={envConfig.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
        />
        {isError && (
          <InputMessage
            type='error'
            hasIcon={false}>
            {t('verify_not_robot')}
          </InputMessage>
        )}
      </RecaptchaWrapper>
    </Container>
  );
};

export default observer(ReCAPTCHA);
