import styled, { css, keyframes } from 'styled-components';
import { HeaderMain, HeaderSecondary } from '../typography/typography.styles';
import { AuthContainer } from '../../routes/authorization/authorization.styles';
import { CloseButtonContainer } from '../close-button/close-button.styles';

interface Props {
  $isOpened: boolean;
  $width: string;
  $withoutHeader?: boolean;
  $zIndex?: number;
  $backgroundColor?: string;
}

const createSlideToLeftAnimation = ($width: string) => keyframes`
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(max(-${$width}, -100vw));
    }
`;

export const SidebarBody = styled.div<SidebarBodyProps>`
  position: relative;
  padding: ${({ $padding }) => $padding || '2.4rem'};
  word-break: break-all;
  overflow: hidden;

  ${({ $contentWithVerticalScroll }) =>
    $contentWithVerticalScroll &&
    css`
      overflow: auto;
      -webkit-overflow-scrolling: auto !important;
    `}

  ${AuthContainer} {
    padding: 0;
    margin: 0;
  }
`;

export const SidebarContainer = styled.div<Props>`
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: ${({ $backgroundColor = 'white' }) => $backgroundColor};
  height: 100dvh;
  top: 0;
  z-index: ${({ $zIndex = 9000 }) => $zIndex};

  ${({ $width }) => css`
    width: min(${$width}, 100vw);
    right: max(-${$width}, -100vw);
  `}

  ${(props) =>
    props.$withoutHeader &&
    css`
      margin-top: 7.3rem;
      z-index: 10000000;
      height: calc(100% - 7.3rem);

      ${SidebarBody} {
        height: 100%;
      }
    `}

    transition: transform .4s ease-out;

  ${({ $isOpened, $width }) =>
    $isOpened &&
    css`
      animation: ${createSlideToLeftAnimation($width)} 0.4s;
      transform: translateX(max(-${$width}, -100vw));
    `}

  ${HeaderMain} {
    text-align: center;
  }
`;

interface SidebarHeaderProps {
  $justifyContent?: string;
  $padding?: string;
}

export const SidebarHeader = styled.div<SidebarHeaderProps>`
  display: flex;
  justify-content: ${({ $justifyContent = 'space-between' }) =>
    $justifyContent};
  align-items: center;
  padding: ${({ $padding = '2rem 0' }) => $padding};
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  background-color: #fff;

  ${HeaderSecondary} {
    margin-left: 2.4rem;
  }

  ${CloseButtonContainer} {
    margin-right: 2.4rem;
  }
`;

interface SidebarBodyProps {
  $contentWithVerticalScroll?: boolean;
  $padding?: string;
}

export const SidebarInner = styled.div`
  height: 100%;
  overflow: hidden;
`;
