import styled from 'styled-components';

interface WrapperProps {
  $isDisabled?: boolean;
  $error?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  .canvas {
    border: ${({ $error = false, theme }) =>
      $error
        ? `2px solid ${theme.colors.input.errorColor}`
        : '1px solid #d8e0eb'};

    border-radius: 12px;
    width: 100%;
    height: 160px;

    pointer-events: ${({ $isDisabled = false }) =>
      $isDisabled ? 'none' : 'auto'};
  }

  @media screen and (min-width: 650px) {
    .canvas {
      height: 240px;
    }
  }
`;

export const SignatureContent = styled.div`
  display: grid;
  padding-top: 8px;
  @media screen and (min-width: 650px) {
    padding-top: 12px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1.6rem;

  @media screen and (min-width: 650px) {
    flex-direction: row;
    gap: 2.4rem;
  }

  @media screen and (min-width: 768px) {
    min-width: 34.4rem;
    justify-self: end;
  }
`;

export const AddButton = styled.div`
  padding-top: 24px;
`;

export const ClearButton = styled.div`
  @media screen and (max-width: 650px) {
    flex: 1 1 100%;
  }
`;
