import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import debounce from 'debounce';
import {
  List,
  HeaderCell,
  ListViewContainer,
  ReceiverListRowHeader,
  Grid,
  CardContainer,
  FiltersMobile,
  ReceiversListSkeleton,
} from './receivers-list.styles';
import {
  Container,
  Header,
  FiltersDesktop,
  FiltersLeft,
  ListRowSkeleton,
} from '../parcel-list/parcel-list.styles';
import { HeaderSecondary } from '../../common/typography/typography.styles';
import { useStore } from '../../stores/store';
import { PerPageCountVariants } from './config';
import { useDetectSticky } from '../../hooks/use-detect-sticky.hook';
import PaginationComponent from '../../common/pagination/pagination.component';
import ReceiversListRow from '../../features/shipments/receivers-list-row/receivers-list-row.component';
import ReceiverCard from '../../features/parcel-creation/parcel-participant-personal/receiver-card/receiver-card.component';
import InputListSearch from '../../common/input-list-search/input-list-search.component';
import { Receiver } from '../../models/user';
import { SearchParam } from '../../constants/route';
import ReceiverCardSkeleton from '../../features/parcel-creation/parcel-participant-personal/receiver-card/receiver-card-skeleton.component';
import EmptyList from '../../common/empty-list/empty-list.component';
import { IOptionsPages } from '../../models/paginationOptions';
import Spinner from '../../common/spinner/spinner.component';

import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { MarginContainer } from '../../common/base-margin/base-margin.component';

export const ListRowHeaderValues = [
  // text here are keys to translation file, which are later passed to i18.t func
  { id: 'full-name', text: 'full_name', width: '24rem' },
  { id: 'phone-number', text: 'phone_number', width: '16rem' },
  { id: 'email', text: 'email', width: '16rem' },
  { id: 'address', text: 'address', width: '27.2rem' },
  { id: 'note', text: 'note', width: '16rem' },
];

const ReceiversList = () => {
  const {
    userStore: {
      getReceivers,
      receivers,
      receiversOptions,
      setReceiversOptions,
      isLoadingReceivers,
    },
  } = useStore();

  const { ref: mobileFiltersRef, isSticky } = useDetectSticky();
  const containerRef = useRef<HTMLUListElement>(null);

  const [isFetchingEmulated, setIsFetchingEmulated] = useState(false);

  const [searchOptions, setSearchOptions] = useState<Receiver[] | undefined>(
    []
  );
  const [isReceiversEmpty, setIsReceiversEmpty] = useState(false);
  const [hasSearchValue, setHasSearchValue] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get(SearchParam.PAGE);
  const perPageParam = searchParams.get(SearchParam.PER_PAGE);
  const page = pageParam ? Number.parseInt(pageParam, 10) : 1;
  const perPage = perPageParam ? Number.parseInt(perPageParam, 10) : 12;
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const handleSearchQueryChange = (nextPage: number, nextPerPage: number) => {
    if (isMobile) {
      setSearchParams(
        (prev) => ({
          ...prev,
          [SearchParam.PAGE]: undefined,
          [SearchParam.PER_PAGE]: undefined,
        }),
        {
          replace: true,
        }
      );
    } else {
      setSearchParams(
        (prev) => ({
          ...prev,
          [SearchParam.PAGE]: nextPage,
          [SearchParam.PER_PAGE]: nextPerPage,
        }),
        {
          replace: true,
        }
      );
    }
  };

  useLayoutEffect(() => {
    if (page || perPage) return;

    handleSearchQueryChange(page || 1, perPage || 12);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  useEffect(() => {
    const options: IOptionsPages = {
      page,
      perPage,
    };

    getReceivers(options, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReceivers]);

  useLayoutEffect(() => {
    if (receivers) {
      setSearchOptions(
        receivers
          .filter((receiver) => receiver?.first_name)
          .sort((a, b) => a.first_name.localeCompare(b.first_name))
      );
    }
  }, [receivers]);

  useLayoutEffect(() => {
    setIsReceiversEmpty(searchOptions?.length === 0);
  }, [searchOptions]);

  const initialPerPageItem = PerPageCountVariants.find(
    (item) => item.value === receiversOptions.perPage
  );

  const getReceiversSearch = debounce((value?: string) => {
    if (value) {
      const searchValue = value.toLowerCase();
      const listOfDisplayedOptions = receivers?.filter((receiver) => {
        const firstName = receiver?.first_name?.toLowerCase() || '';
        const lastName = receiver?.last_name?.toLowerCase() || '';
        return (
          firstName.includes(searchValue) || lastName.includes(searchValue)
        );
      });

      setSearchOptions(
        listOfDisplayedOptions?.length ? listOfDisplayedOptions : []
      );
      setHasSearchValue(true);
      setReceiversOptions({
        totalElCount: listOfDisplayedOptions?.length,
      });
    } else {
      setSearchOptions(receivers);
      setHasSearchValue(false);
      setReceiversOptions({
        totalElCount: receivers?.length,
      });
    }

    setIsFetchingEmulated(false);
  }, 500);

  const handleSearch = (value?: string) => {
    setIsFetchingEmulated(true);
    getReceiversSearch(value);
  };

  const shouldShowSkeleton =
    (isLoadingReceivers || isFetchingEmulated) && !isMobile;
  const shouldShowReceivers = !isReceiversEmpty && !shouldShowSkeleton;

  return (
    <Container
      $isEmpty={isReceiversEmpty}
      $isFiltersApplied={hasSearchValue}>
      <Header>
        <HeaderSecondary
          as='h1'
          $size='mediumBig'>
          {t('my_receivers')}
        </HeaderSecondary>
      </Header>

      <FiltersDesktop>
        <FiltersLeft>
          <InputListSearch
            isLoading={isFetchingEmulated}
            isDisabled={isLoadingReceivers}
            listType='receiver'
            onChange={handleSearch}
          />
        </FiltersLeft>
      </FiltersDesktop>

      <FiltersMobile
        $isSticky={isSticky}
        ref={mobileFiltersRef}
        $isNoFilters>
        <InputListSearch
          isLoading={isFetchingEmulated}
          isDisabled={isFetchingEmulated}
          listType='receiver'
          onChange={handleSearch}
        />
      </FiltersMobile>

      {shouldShowSkeleton && (
        <>
          <ReceiversListSkeleton>
            <ReceiverListRowHeader>
              {ListRowHeaderValues.map((value) => (
                <HeaderCell
                  key={value.id}
                  $width={value.width}>
                  <Skeleton
                    containerClassName='flex-1'
                    width='100%'
                    height='1.6rem'
                    borderRadius={6}
                  />
                </HeaderCell>
              ))}
            </ReceiverListRowHeader>
            {Array.from({ length: 6 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListRowSkeleton key={index}>
                {ListRowHeaderValues.map((value) => (
                  <HeaderCell
                    key={value.id}
                    $width={value.width}>
                    <Skeleton
                      containerClassName='flex-1'
                      width='100%'
                      height='1.6rem'
                      borderRadius={6}
                    />
                  </HeaderCell>
                ))}
              </ListRowSkeleton>
            ))}
          </ReceiversListSkeleton>
          <Grid>
            {Array.from({ length: 3 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <CardContainer key={index}>
                <ReceiverCardSkeleton />
              </CardContainer>
            ))}
          </Grid>
        </>
      )}

      {shouldShowReceivers && (
        <>
          <List>
            <ReceiverListRowHeader>
              {ListRowHeaderValues.map((value) => (
                <HeaderCell
                  key={value.id}
                  $width={value.width}>
                  {t(value.text)}
                </HeaderCell>
              ))}
            </ReceiverListRowHeader>
            {searchOptions?.map((receiver) => (
              <ReceiversListRow
                key={receiver.id}
                receiver={receiver}
              />
            ))}
          </List>
          <ListViewContainer>
            <PaginationComponent
              fetchItems={getReceivers}
              onPageChange={(newPage) =>
                handleSearchQueryChange(newPage, perPage)
              }
              onPerPageChange={(newPerPage) =>
                handleSearchQueryChange(1, newPerPage)
              }
              options={receiversOptions}
              initialPerPageItem={initialPerPageItem}
              containerRef={containerRef}
              listType='receiver'
            />
          </ListViewContainer>
          <Grid ref={containerRef}>
            {searchOptions?.map((receiver) => (
              <CardContainer key={receiver.id}>
                <ReceiverCard receiver={receiver} />
              </CardContainer>
            ))}
          </Grid>
        </>
      )}

      {(isLoadingReceivers || isFetchingEmulated) && isMobile && (
        <MarginContainer $mb='1rem'>
          <Spinner isSmall />
        </MarginContainer>
      )}

      {!isLoadingReceivers &&
        !isFetchingEmulated &&
        isReceiversEmpty &&
        hasSearchValue && (
          <EmptyList
            title={t('no_search_results')}
            description={t('please_try_again')}
          />
        )}

      {!isLoadingReceivers &&
        !isFetchingEmulated &&
        isReceiversEmpty &&
        !hasSearchValue && (
          <EmptyList description={t('you_not_have_receivers_yet')} />
        )}
    </Container>
  );
};

export default observer(ReceiversList);
