import { useTranslation } from 'react-i18next';
import {
  Content,
  DownloadAppIconsBlock,
  DownloadAppContainer,
  DownloadAppHeader,
  DownloadAppQrCode,
  DownloadAppQrCodeBlock,
  DownloadAppSectionDescription,
  DownloadAppWrapper,
  Section,
  DownloadAppStyledNavLink,
  StyledAppleStoreIcon,
  StyledGooglePlayIcon,
  IphoneImg,
} from './download-app.styles';
import iphoneBackgroundWebp from '../../../assets/main-page/download-app/iPhone13Pro.webp';
import iphoneBackgroundWebp2x from '../../../assets/main-page/download-app/iPhone13Pro_2x.webp';
import iphoneBackground from '../../../assets/main-page/download-app/iPhone13Pro.png';
import iphoneBackground2x from '../../../assets/main-page/download-app/iPhone13Pro_2x.png';
import AppDownloadQRCode from './download-app-qrcode.component';
import { VisuallyHidden } from '../../../common/visually-hidden/visually-hidden.styles';
import { useStore } from '../../../stores/store';

const DownloadApp = () => {
  const {
    marketingStore: { footerLinks },
  } = useStore();

  const { t } = useTranslation();

  return (
    <DownloadAppContainer>
      <Section>
        <Content>
          <DownloadAppWrapper>
            <DownloadAppHeader>
              {t('meest_in_your_smartphone')}
            </DownloadAppHeader>
            <DownloadAppSectionDescription>
              {t(
                'install_the_app_and_control_your_postal_items_directly_from_your_smartphone'
              )}
            </DownloadAppSectionDescription>
            <DownloadAppQrCodeBlock>
              <DownloadAppQrCode>
                <AppDownloadQRCode />
              </DownloadAppQrCode>
              <DownloadAppIconsBlock>
                <DownloadAppStyledNavLink
                  target='_blank'
                  href={footerLinks?.appStore}>
                  <VisuallyHidden>
                    {t('download_on_the_app_store')}
                  </VisuallyHidden>
                  <StyledAppleStoreIcon />
                </DownloadAppStyledNavLink>
                <DownloadAppStyledNavLink
                  target='_blank'
                  href={footerLinks?.playMarket}>
                  <VisuallyHidden>{t('get_it_on_google_pay')}</VisuallyHidden>
                  <StyledGooglePlayIcon />
                </DownloadAppStyledNavLink>
              </DownloadAppIconsBlock>
            </DownloadAppQrCodeBlock>
          </DownloadAppWrapper>
        </Content>
        <picture>
          <source
            width={375}
            height={590}
            type='image/webp'
            srcSet={`${iphoneBackgroundWebp} 1x, ${iphoneBackgroundWebp2x} 2x`}
          />
          <IphoneImg
            decoding='async'
            width={375}
            height={590}
            loading='lazy'
            src={iphoneBackground}
            srcSet={`${iphoneBackground} 1x, ${iphoneBackground2x} 2x`}
            alt=''
          />
        </picture>
      </Section>
    </DownloadAppContainer>
  );
};
export default DownloadApp;
