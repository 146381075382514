import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderSecondary,
  HeaderTertiary,
  TextBody as TypographyTextBody,
} from '../../../common/typography/typography.styles';
import { TextBody } from '../../../routes/parcel-list/parcel-list.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionNote,
  ServicesSubSectionRow,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
} from '../../services/services-subsection/services-subsection.styles';

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionsList = styled.div`
  display: grid;
  gap: 8px;
  flex: 1;
  overflow-y: auto;
  align-items: start;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.baseColors.grey5};
    border: 4px solid ${({ theme }) => theme.colors.baseColors.blue0};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey80};
    border: 4px solid ${({ theme }) => theme.colors.baseColors.grey5};
    border-radius: 12px;
  }
`;

export const Section = styled(ServicesSubSectionContainer)`
  min-width: 0;
`;

export const SectionTitle = styled(ServicesSubSectionHeader)``;

export const Date = styled(TextBody).attrs({ as: 'time' })``;

export const SubSections = styled.div``;

export const SubSectionList = styled.div`
  display: grid;
  gap: 8px;

  ${TypographyTextBody} {
    color: #2c363f;
  }
`;

export const SubSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #dcdde0;
  }
`;

export const SectionFields = styled.div`
  display: grid;
  gap: 12px;

  @media screen and (min-width: 650px) {
    gap: 16px;
  }
`;

const rowCommonStyles = css`
  grid-template-columns: 120px 1fr;
  gap: 12px;
`;

export const Row = styled(ServicesSubSectionRow)`
  --services-subsection-row-title: 120px;
  align-items: start;
  justify-content: initial;
`;

export const SubSectionRow = styled.div`
  display: grid;
  ${rowCommonStyles};
`;

export const Title = styled(ServicesSubSectionTitle)``;

export const Value = styled(ServicesSubSectionValue)``;

export const ValueContent = styled(HeaderSecondary).attrs({
  as: 'p',
  $size: 'medium',
})`
  display: grid;
  gap: 4px;
  font-weight: 500;
  word-break: break-word;
`;

export const ValueNote = styled(ServicesSubSectionNote).attrs({ as: 'span' })`
  font-size: 1.2rem;
`;

export const DeliveryDetailsContainer = styled.div``;

type DeliveryDetailsFlexContainerProps = {
  $mb: string;
};
export const DeliveryDetailsFlexContainer = styled(
  FlexContainer
)<DeliveryDetailsFlexContainerProps>`
  margin-bottom: ${({ $mb }) => $mb || 0};
  align-items: start;
`;

export const TrackingNumber = styled(BaseMarginComponent('button'))`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.trackingNumber.color};
  cursor: pointer;
  margin-left: auto;
`;

export const PostName = styled(HeaderTertiary)`
  word-break: break-all;
  color: ${({ theme }) => theme.colors.postName.color};
  font-size: ${FontSizes.medium};
  position: relative;
  top: 2px;
`;

export const PostImage = styled.img`
  width: 54px;
  height: 22px;
  flex-shrink: 0;
  object-fit: contain;
`;

export const DeliveryDetailsHeader = styled(FlexContainer)`
  margin-bottom: 8px;
`;

export const ParcelContentTitle = styled(ValueContent)`
  margin-bottom: 12px;
`;

export const DeliveryStatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 24px;
`;

export const DeliveryStatusMore = styled.div`
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

interface DeliveryStatusAccordionTriggerProps {
  $isActive?: boolean;
}

export const DeliveryStatusAccordionTrigger = styled.button<DeliveryStatusAccordionTriggerProps>`
  background: none;
  border: none;
  font: inherit;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
  cursor: pointer;
  color: #75777b;

  svg {
    transition: transform 0.3s;
    ${({ $isActive }) =>
      $isActive &&
      css`
        transform: rotate(180deg);
      `};
  }
`;

export const DeliveryStatuses = styled(FlexContainer)`
  margin-top: 12px;
`;

interface DeliveryStatusProps {
  $isActive?: boolean;
}

export const DeliveryStatusItem = styled(FlexContainer)<DeliveryStatusProps>`
  color: ${({ $isActive }) => ($isActive ? '#2C363F' : '#75777b')};
`;

export const ActionButtonContainer = styled.div`
  padding-top: 24px;

  &:empty {
    display: none;
  }
`;

export const CodeGenerationButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
`;

export const IconWrapper = styled.span`
  display: block;
  width: 20px;
  height: 20px;
`;

export const QrcodeWrapper = styled.div`
  max-width: 320px;
  max-height: 326px;
  padding: 12px;

  @media (max-width: 650px) {
    margin: 0 24px;
    max-width: 264px;
    max-height: 264px;
  }
`;

export const BarcodeWrapper = styled.div`
  & > svg {
    text {
      font: 14px Montserrat !important;
      letter-spacing: 10px;
    }
  }

  @media (max-width: 650px) {
    width: auto;
    height: auto;
    transform: rotate(-90deg);
    & > svg {
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
  }
`;
