import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  Container,
  FiltersDesktop,
  FiltersLeft,
  FiltersMobile,
  FiltersRight,
  Grid,
  Header,
  HeaderCell,
  List,
  ListRowSkeleton,
  ListSkeleton,
  ListViewContainer,
  ParcelListRowHeader,
  ViewMode,
  ViewModeDivider,
} from './parcel-list.styles';
import { HeaderSecondary } from '../../common/typography/typography.styles';
import Button from '../../common/button/button.component';
import { useStore } from '../../stores/store';
import Select, { IDropdownItem } from '../../common/select/select.component';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  getAddress,
  getOrderItems,
  IDirectionItem,
  IParcelListPathValue,
  IStatusItem,
  listPathValues,
  ListRowHeaderValues,
  PARCEL_LIST_SIDEBARS,
  PerPageCountVariants,
  statusItems,
} from './config';
import { useDetectSticky } from '../../hooks/use-detect-sticky.hook';
import {
  ShipmentQueryFilter,
  ShipmentQueryOrder,
} from '../../stores/parcelListStore';
import { ParcelListShipment } from '../../models/parcelDeliveryMiles';
import {
  copyToClipboard,
  lockBodyScroll,
  unlockBodyScroll,
} from '../../utils/generic/generic.utils';
import IconButton from '../../common/icon-button/icon-button';
import ParcelListCard from '../../features/shipments/parcel-list-card/parcel-list-card.component';
import ParcelListRow from '../../features/shipments/parcel-list-row/parcel-list-row.component';
import ParcelListFilters from '../../features/shipments/parcel-list-filters/parcel-list-filters.component';
import PaginationComponent from '../../common/pagination/pagination.component';
import ParcelListOrder from '../../features/shipments/parcel-list-order/parcel-list-order.component';
import { useStartParcelCreation } from '../../hooks/use-start-parcel-creation.hook';
import { SearchParam } from '../../constants/route';
import usePrevious from '../../hooks/use-previous.hook';
import InputListSearch from '../../common/input-list-search/input-list-search.component';
import ParcelListCardSkeleton from '../../features/shipments/parcel-list-card/parcel-list-card-skeleton.component';
import EmptyList from '../../common/empty-list/empty-list.component';
import Spinner from '../../common/spinner/spinner.component';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { MarginContainer } from '../../common/base-margin/base-margin.component';
import { IOptionsPages } from '../../models/paginationOptions';
import usePaymentStatusUpdater from '../../hooks/use-payment-status-updater.hook';

type TViewMode = 'grid' | 'list';

interface ParcelListProps {
  routeName: IParcelListPathValue['path'];
}

const ParcelList = ({ routeName }: ParcelListProps) => {
  const {
    parcelListStore: {
      setRouteName,
      isLoadingSearch,
      options,
      fetchParcels,
      hasSearchValue,
      getParcels,
      getParcelsOrder,
      filterParcels,
      toggleFavorite,
      archiveParcel,
      selectedOrder,
      setOrder,
      getInitialOrder,
      selectedDirection,
      defaultDirection,
      setDirection,
      selectedStatus,
      setStatus,
      isEmpty,
      parcels,
      isLoadingShipmentApi,
    },
    userStore: { user, isLoadingUser, getOnboardingManual },
    navStore: { toggleSidebarByName },
    commonStore: { toastSuccess, isGuestParcelCreation },
  } = useStore();

  const { ref: mobileFiltersRef, isSticky } = useDetectSticky();
  const handleStartParcelCreation = useStartParcelCreation();
  const containerRef = useRef<HTMLUListElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const viewMode = searchParams.get(SearchParam.PARCEL_LIST_VIEW_MODE);
  const page = Number.parseInt(
    searchParams.get(SearchParam.PAGE) ?? `${DEFAULT_PAGE}`,
    10
  );
  const perPage = Number.parseInt(
    searchParams.get(SearchParam.PER_PAGE) ?? `${DEFAULT_PER_PAGE}`,
    10
  );
  const previousRouteName = usePrevious<string>(routeName);

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const directionItems: IDirectionItem[] = [
    defaultDirection,
    {
      label: 'to_me',
      value: 'to_me',
    },
    {
      label: 'from_me',
      value: 'from_me',
    },
  ];

  useLayoutEffect(() => {
    if (isMobile) return;
    if (isLoadingShipmentApi) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isLoadingShipmentApi, isMobile]);

  useLayoutEffect(() => {
    setRouteName(routeName);
  }, [routeName, setRouteName]);

  useLayoutEffect(() => {
    getInitialOrder(routeName);
  }, [getInitialOrder, routeName]);

  const handleSearchQueryChange = (
    nextViewMode: TViewMode,
    nextPage: number,
    nextPerPage: number
  ) => {
    if (isMobile) {
      searchParams.set(SearchParam.PARCEL_LIST_VIEW_MODE, 'grid');
      searchParams.delete(SearchParam.PAGE);
      searchParams.delete(SearchParam.PER_PAGE);
    } else {
      searchParams.set(SearchParam.PARCEL_LIST_VIEW_MODE, nextViewMode);
      searchParams.set(SearchParam.PAGE, nextPage.toString());
      searchParams.set(SearchParam.PER_PAGE, nextPerPage.toString());
    }
    setSearchParams(searchParams, { replace: true, preventScrollReset: true });
  };

  useLayoutEffect(() => {
    if (viewMode) return;

    handleSearchQueryChange('grid', DEFAULT_PAGE, DEFAULT_PER_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useLayoutEffect(() => {
    if (previousRouteName !== undefined && previousRouteName !== routeName) {
      handleSearchQueryChange('grid', DEFAULT_PAGE, DEFAULT_PER_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName]);

  usePaymentStatusUpdater();

  const fetchParcelsByRoute = useCallback(
    (customPage?: number) => {
      const abortController = new AbortController();
      const pageToFetch = customPage !== undefined ? customPage : page;

      switch (routeName) {
        case 'parcellist':
          getParcels(pageToFetch, perPage, 'all', abortController.signal, true);
          break;
        case 'drafts':
          getParcels(pageToFetch, perPage, 'draft', abortController.signal);
          break;
        case 'archive':
          getParcels(pageToFetch, perPage, 'archived', abortController.signal);
          break;
        default:
          console.error(`Unexpected route: ${routeName}`);
          break;
      }

      return () => {
        abortController.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParcels, routeName]
  );

  useEffect(() => fetchParcelsByRoute(), [fetchParcelsByRoute, user?.lang]);

  useEffect(
    () => () => {
      setStatus(null);
      setDirection(null);
    },
    [setDirection, setStatus, routeName]
  );

  useEffect(() => {
    if (user?.show_onboarding_manual && user?.phone_verified) {
      getOnboardingManual();
    }
  }, [getOnboardingManual, user?.phone_verified, user?.show_onboarding_manual]);

  const handleFavoriteClick = useCallback(
    async (shipmentId: number, value: boolean) => {
      const success = await toggleFavorite({
        shipmentId,
        value,
      });
      if (success) {
        value
          ? toastSuccess(t('parcel_is_added_to_favourites'))
          : toastSuccess(t('parcel_is_removed_from_favourites'));
      }
    },
    [toastSuccess, toggleFavorite, t]
  );

  const handleFetchItems = useCallback(
    async (fetchOptions: IOptionsPages, append: boolean = false) => {
      await fetchParcels(fetchOptions, undefined, true, append);
    },
    [fetchParcels]
  );

  const handleArchive = useCallback(
    async (shipmentId: number, value: boolean) => {
      const success = await archiveParcel({ shipmentId, value });

      if (!success) return;

      const messageKey = value ? 'parcel_is_archived' : 'parcel_is_unarchived';
      toastSuccess(t(messageKey));

      const isLastPage = options.page === options.totalPagesCount;
      const hasParcels = parcels && parcels.length > 0;

      if (isLastPage && hasParcels) return;

      if (parcels && parcels.length > 0) {
        fetchParcelsByRoute(page);
        return;
      }

      if (page === options.totalPagesCount) {
        const newPage = page - 1;
        searchParams.set(SearchParam.PAGE, newPage.toString());
        setSearchParams(searchParams, {
          replace: true,
          preventScrollReset: true,
        });
        fetchParcelsByRoute(newPage);
        return;
      }

      fetchParcelsByRoute(page);
    },
    [
      archiveParcel,
      toastSuccess,
      t,
      fetchParcelsByRoute,
      parcels,
      page,
      searchParams,
      setSearchParams,
      options.page,
      options.totalPagesCount,
    ]
  );

  const handleCopy = useCallback(
    async ({
      status,
      delivery_at,
      delivery_time,
      sender,
      tracking_number,
      recipient,
    }: ParcelListShipment) => {
      const data: string[] = [];
      const deliveryDate =
        status === 'created'
          ? t('parcel_not_sent_yet', { deliveryTime: delivery_time })
          : delivery_at || t('no_info');
      data.push(`${t('tracking_number')}: ${tracking_number}`);
      data.push(`${t('approximate_delivery_date')}: ${deliveryDate}`);
      data.push(`${t('from')}: ${getAddress(sender!)}`);
      data.push(`${t('to')}: ${getAddress(recipient!)}`);
      data.push(`http://post.meest.com/downloadapp`);
      const text = data.filter((i) => i !== '').join('\n');
      copyToClipboard({
        text,
        successMessage: t('parcel_information_is_copied'),
      });
    },
    [t]
  );

  const handleFilterParcels = (
    filter: (ShipmentQueryFilter | undefined)[],
    shouldShowLoading: boolean = false
  ) => {
    filterParcels(
      {
        order: selectedOrder.value,
        filter,
      },
      shouldShowLoading
    );
  };

  const handleStatusChange = (item: IStatusItem) => {
    setStatus(item);
    handleFilterParcels(
      [defaultDirection.value, selectedDirection?.value, item.value],
      true
    );
  };

  const handleStatusClear = () => {
    setStatus(null);
    handleFilterParcels(
      [defaultDirection.value, selectedDirection?.value],
      true
    );
  };

  const handleDirectionChange = (item: IDirectionItem) => {
    setDirection(item);
    const filterDirectionValue =
      defaultDirection?.value !== item?.value ? item?.value : undefined;

    handleFilterParcels(
      [defaultDirection.value, filterDirectionValue, selectedStatus?.value],
      true
    );
  };

  const handleOrderChange = (item: IDropdownItem<ShipmentQueryOrder>) => {
    setOrder(item);
    getParcelsOrder(item.value);
  };

  useEffect(() => {
    if (routeName) {
      setStatus(null);
      setDirection(defaultDirection);
    }
  }, [defaultDirection, routeName, setDirection, setStatus]);

  const initialPerPageItem = PerPageCountVariants.find(
    (item) => item.value === options.perPage
  );

  const isFiltersApplied =
    selectedDirection !== null || selectedStatus !== null || hasSearchValue;
  const isNoFilters = routeName === listPathValues.drafts.path;

  const apiLoading = isLoadingShipmentApi || isLoadingUser;

  return (
    <Container
      $isEmpty={isEmpty}
      $isFiltersApplied={isFiltersApplied}>
      <Header>
        <HeaderSecondary
          as='h1'
          $size='mediumBig'>
          {t(listPathValues[routeName].header)}
        </HeaderSecondary>
        {!isGuestParcelCreation && (
          <ViewMode>
            <Button
              disabled={apiLoading}
              selectable
              isSelected={viewMode === 'grid'}
              icon={{ glyph: 'grid' }}
              contentStyle='thin'
              size='mini'
              appearance='tertiary'
              fullWidth={false}
              onClick={() => handleSearchQueryChange('grid', page, perPage)}>
              {t('grid')}
            </Button>
            <ViewModeDivider />
            <Button
              disabled={apiLoading}
              selectable
              isSelected={viewMode === 'list'}
              icon={{ glyph: 'list' }}
              contentStyle='thin'
              size='mini'
              appearance='tertiary'
              fullWidth={false}
              onClick={() => handleSearchQueryChange('list', page, perPage)}>
              {t('list')}
            </Button>
          </ViewMode>
        )}
      </Header>
      {!isGuestParcelCreation && (
        <>
          <FiltersDesktop>
            <FiltersLeft>
              <InputListSearch
                isLoading={isLoadingSearch}
                isDisabled={apiLoading}
                listType='parcel'
                routeName={routeName}
              />
              {!isNoFilters && (
                <>
                  <Select
                    isDisabled={apiLoading}
                    value={selectedDirection || defaultDirection}
                    items={directionItems}
                    onChange={handleDirectionChange}
                  />
                  <Select
                    isDisabled={apiLoading}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    placeholder={t('no_filter')}
                    items={statusItems}
                    showClear={selectedStatus !== null}
                    onClear={handleStatusClear}
                  />
                </>
              )}
            </FiltersLeft>
            <FiltersRight>
              <Select
                isDisabled={apiLoading}
                dropdownPosition='bottom-end'
                items={getOrderItems(routeName)}
                value={selectedOrder}
                onChange={handleOrderChange}
              />
            </FiltersRight>
          </FiltersDesktop>
          <FiltersMobile
            $isSticky={isSticky && !isEmpty}
            ref={mobileFiltersRef}
            $isNoFilters={isNoFilters}>
            <InputListSearch
              isLoading={isLoadingSearch}
              isDisabled={apiLoading}
              listType='parcel'
              routeName={routeName}
            />
            {!isGuestParcelCreation && !isNoFilters && (
              <IconButton
                disabled={apiLoading}
                showBadge={!!(selectedStatus || selectedDirection)}
                onClick={() =>
                  toggleSidebarByName(PARCEL_LIST_SIDEBARS.filters)
                }
                appearance='secondary-filled'
                aria-label='Filter'
                icon={{ glyph: 'filter' }}
              />
            )}
            {!isGuestParcelCreation && (
              <IconButton
                disabled={apiLoading}
                onClick={() => toggleSidebarByName(PARCEL_LIST_SIDEBARS.order)}
                appearance='secondary-filled'
                aria-label='Sort'
                icon={{ glyph: selectedOrder.glyph! }}
              />
            )}
          </FiltersMobile>
        </>
      )}

      {viewMode === 'grid' &&
        (apiLoading && !isMobile ? (
          <>
            <Grid>
              {Array.from({ length: 12 }).map((_, index) => (
                <ParcelListCardSkeleton
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  routeName={routeName}
                />
              ))}
            </Grid>
            {!isGuestParcelCreation && (
              <PaginationComponent
                fetchItems={handleFetchItems}
                onPageChange={(newPage) =>
                  handleSearchQueryChange(viewMode, newPage, perPage)
                }
                onPerPageChange={(newPerPage) =>
                  handleSearchQueryChange(viewMode, 1, newPerPage)
                }
                options={options}
                initialPerPageItem={initialPerPageItem}
                containerRef={containerRef}
                listType='parcel'
              />
            )}
          </>
        ) : (
          !isEmpty && (
            <>
              <Grid ref={containerRef}>
                {parcels?.map((parcel) => (
                  <ParcelListCard
                    routeName={routeName}
                    onToggleFavorite={(value) => {
                      handleFavoriteClick(parcel.id, value);
                    }}
                    onToggleArchive={(value) => handleArchive(parcel.id, value)}
                    onCopy={() => handleCopy(parcel)}
                    key={parcel.id}
                    parcel={parcel}
                    isGuestUser={!!isGuestParcelCreation}
                  />
                ))}
              </Grid>
              {!isGuestParcelCreation && (
                <PaginationComponent
                  fetchItems={handleFetchItems}
                  onPageChange={(newPage) =>
                    handleSearchQueryChange(viewMode, newPage, perPage)
                  }
                  onPerPageChange={(newPerPage) =>
                    handleSearchQueryChange(viewMode, 1, newPerPage)
                  }
                  options={options}
                  initialPerPageItem={initialPerPageItem}
                  containerRef={containerRef}
                  listType='parcel'
                />
              )}
            </>
          )
        ))}

      {apiLoading && isMobile && (
        <MarginContainer $mb='1rem'>
          <Spinner isSmall />
        </MarginContainer>
      )}

      {viewMode === 'list' &&
        (apiLoading ? (
          <>
            <ListSkeleton>
              <ParcelListRowHeader>
                {ListRowHeaderValues.map((value) => (
                  <HeaderCell
                    key={value.id}
                    $width={value.width}>
                    <Skeleton
                      containerClassName='flex-1'
                      width='100%'
                      height='1.6rem'
                      borderRadius={6}
                    />
                  </HeaderCell>
                ))}
              </ParcelListRowHeader>
              {Array.from({ length: 12 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListRowSkeleton key={index}>
                  {ListRowHeaderValues.map((value) => (
                    <HeaderCell
                      key={value.id}
                      $width={value.width}>
                      <Skeleton
                        containerClassName='flex-1'
                        width='100%'
                        height='1.6rem'
                        borderRadius={6}
                      />
                    </HeaderCell>
                  ))}
                </ListRowSkeleton>
              ))}
            </ListSkeleton>
            {!isGuestParcelCreation && (
              <PaginationComponent
                fetchItems={handleFetchItems}
                onPageChange={(newPage) =>
                  handleSearchQueryChange(viewMode, newPage, perPage)
                }
                onPerPageChange={(newPerPage) =>
                  handleSearchQueryChange(viewMode, 1, newPerPage)
                }
                options={options}
                initialPerPageItem={initialPerPageItem}
                containerRef={containerRef}
                listType='parcel'
              />
            )}
          </>
        ) : (
          !isEmpty && (
            <>
              <List>
                <ParcelListRowHeader>
                  {ListRowHeaderValues.map((value) => (
                    <HeaderCell
                      $centerPenultimate
                      key={value.id}
                      $width={value.width}>
                      {t(value.text)}
                    </HeaderCell>
                  ))}
                </ParcelListRowHeader>
                {parcels?.map((parcel) => (
                  <ParcelListRow
                    routeName={routeName}
                    onToggleFavorite={(value) => {
                      handleFavoriteClick(parcel.id, value);
                    }}
                    onToggleArchive={(value) => handleArchive(parcel.id, value)}
                    onCopy={() => handleCopy(parcel)}
                    key={parcel.id}
                    parcel={parcel}
                    isGuestUser={!!isGuestParcelCreation}
                  />
                ))}
              </List>
              <ListViewContainer>
                {!isGuestParcelCreation && (
                  <PaginationComponent
                    fetchItems={handleFetchItems}
                    onPageChange={(newPage) =>
                      handleSearchQueryChange(viewMode, newPage, perPage)
                    }
                    onPerPageChange={(newPerPage) =>
                      handleSearchQueryChange(viewMode, page, newPerPage)
                    }
                    options={options}
                    initialPerPageItem={initialPerPageItem}
                    containerRef={containerRef}
                    listType='parcel'
                  />
                )}
              </ListViewContainer>
            </>
          )
        ))}

      {!isLoadingShipmentApi && isEmpty && isFiltersApplied && (
        <EmptyList
          title={t('no_search_results')}
          description={t('please_try_again')}
        />
      )}
      {!isLoadingShipmentApi && isEmpty && !isFiltersApplied && (
        <EmptyList
          buttonText={t('create_parcel')}
          description={t(`you_not_have_parcels_yet_${routeName}`)}
          onClick={() => {
            handleStartParcelCreation();
          }}
        />
      )}

      <ParcelListFilters handleFilterParcels={handleFilterParcels} />
      <ParcelListOrder
        isDrafts={routeName === 'drafts'}
        selectedValue={selectedOrder}
        onClick={handleOrderChange}
      />
    </Container>
  );
};

export default observer(ParcelList);
