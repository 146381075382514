import { AdministrativeUnits } from './countriesModel';

export interface ParcelCreationNavigation {
  previous: Step | null | undefined;
  current: Step | null | undefined;
  next: Step | null | undefined;
  map: Step[];
}

export interface Step {
  name: StepName;
  type: string;
  elements: Element[];
  behavior?: string | null;
}

export type ElementName =
  | 'taxes'
  | 'additional-fields'
  | 'signature'
  | 'action-pay-parcel'
  | 'administrative-unit';

export type ElementType =
  | 'action-show-taxes'
  | 'hide'
  | 'show'
  | 'input-signature'
  | 'action-pay-parcel'
  | 'input-select';

export interface Element {
  name: ElementName;
  type: ElementType;
  behavior?: AdministrativeUnits;
  elements?: string[];
}

export type StepName =
  | 'parcel-details'
  | 'delivery-option'
  | 'delivery-instructions'
  | 'sender-details-pudo'
  | 'receiver-details-pudo'
  | 'sender-details-courier'
  | 'receiver-details-courier'
  | 'verify-phone'
  | 'parcel-content'
  | 'summary'
  | 'signature';

// helpers

export function movePointerBack(
  navigation: ParcelCreationNavigation,
  stepToSkip?: StepName
) {
  if (navigation.previous == null) return navigation;
  const next =
    navigation.previous.name !== stepToSkip
      ? navigation.current
      : navigation.previous;

  const current =
    navigation.previous.name !== stepToSkip
      ? navigation.previous
      : findPrevStep(navigation, stepToSkip);

  const previous = current ? findPrevStep(navigation, current.name) : null;

  return { ...navigation, next, current, previous };
}

export function movePointerForward(
  navigation: ParcelCreationNavigation,
  stepToSkip?: StepName
) {
  if (navigation.next == null) return navigation;
  const previous =
    navigation.next.name !== stepToSkip ? navigation.current : navigation.next;

  const current =
    navigation.next.name !== stepToSkip
      ? navigation.next
      : findNextStep(navigation, stepToSkip);

  const next = current ? findNextStep(navigation, current.name) : null;

  return { ...navigation, next, current, previous };
}

function movePointer(
  navigation: ParcelCreationNavigation,
  direction: 'next' | 'previous',
  stepToSkip?: StepName
) {
  let futureStep = direction === 'next' ? navigation.next : navigation.previous;
  if (!futureStep) return;

  futureStep =
    futureStep.name !== stepToSkip ? navigation.current! : futureStep;
  const findFutureStep = direction === 'next' ? findNextStep : findPrevStep;

  const current =
    futureStep.name !== stepToSkip
      ? futureStep
      : findFutureStep(navigation, stepToSkip);

  const precedingStep = current
    ? findFutureStep(navigation, current.name)
    : null;

  if (direction === 'next') {
    return {
      ...navigation,
      current,
      next: futureStep,
      previous: precedingStep,
    };
  }
  return {
    ...navigation,
    current,
    next: futureStep,
    previous: precedingStep,
  };
}

export function findStepIndex(
  navigation: ParcelCreationNavigation,
  name: StepName
) {
  return navigation.map.findIndex((s) => s.name === name);
}

export function findNextStep(
  navigation: ParcelCreationNavigation,
  name: StepName
) {
  const nextStepIndex = findStepIndex(navigation, name) + 1;
  if (nextStepIndex === 0 || nextStepIndex >= navigation.map.length)
    return null;

  return navigation.map[nextStepIndex];
}

export function findPrevStep(
  navigation: ParcelCreationNavigation,
  name: StepName
) {
  const prevStepIndex = findStepIndex(navigation, name) - 1;
  if (prevStepIndex < 0) return null;

  return navigation.map[prevStepIndex];
}

export function toTranslationKey(stepName: StepName | undefined) {
  return stepName ? stepName.replaceAll('-', '_') : '';
}

export function getElementTypeByName(
  elements: Element[],
  name: ElementName
): ElementType | undefined {
  const element = elements.find((el) => el.name === name);
  return element?.type;
}
