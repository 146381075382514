import { ListRow, CellContainer } from './receivers-list-row.styles';
import { FontSizes } from '../../../common/typography/typography.styles';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';

import { Receiver } from '../../../models/user';
import { getFullUserAddress } from '../../../utils/generic/generic.utils';

interface Props {
  receiver: Receiver;
}

const ReceiversListRow = ({ receiver }: Props) => {
  const {
    first_name: firstName,
    last_name: lastName,
    phone,
    address,
    email,
  } = receiver;

  return (
    <ListRow tabIndex={0}>
      <CellContainer $width='24rem'>
        <TruncatedTextWithTooltip
          textType='label'
          fontSize={FontSizes.small}>
          {`${firstName || ''} ${lastName || ''}`}
        </TruncatedTextWithTooltip>
      </CellContainer>

      <CellContainer $width='16rem'>
        <TruncatedTextWithTooltip
          textType='label'
          fontSize={FontSizes.small}>
          {phone?.phone_number || ''}
        </TruncatedTextWithTooltip>
      </CellContainer>

      <CellContainer $width='16rem'>
        <TruncatedTextWithTooltip
          textType='label'
          fontSize={FontSizes.small}>
          {email || ''}
        </TruncatedTextWithTooltip>
      </CellContainer>

      <CellContainer $width='27.2rem'>
        <TruncatedTextWithTooltip
          textType='label'
          fontSize={FontSizes.small}>
          {getFullUserAddress(address) || ''}
        </TruncatedTextWithTooltip>
      </CellContainer>

      <CellContainer $width='16rem'>
        <TruncatedTextWithTooltip
          textType='label'
          fontSize={FontSizes.small}>
          {address?.note || ''}
        </TruncatedTextWithTooltip>
      </CellContainer>
    </ListRow>
  );
};

export default ReceiversListRow;
