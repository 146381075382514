import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import i18n from '../../i18n';
import { currencies, currenciesMap } from '../constants/currency';
import agent from '../api/agent';
import { CountriesResponse } from '../models/countriesModel';
import { Language } from '../models/language';
import { Measure } from '../utils/parcel-measurements.ts/parcel-measurements-utils';
import {
  CountryLimit,
  PriorityCountries,
} from '../features/main/countries-list/countries.config';
import { localizationsMap } from '../constants/languages';
import { handleError } from '../utils/generic/generic.utils';
import { isMeest } from '../utils/client/client.utils';
import { client } from '../config';

export const DEFAULT_COUNTRY: CountriesResponse = {
  administrative_units: {
    allowed_values: [],
    form_field: '',
    is_required: false,
    placeholder: '',
    selected_value: '',
    title: '',
    validation: [],
  },
  country_code: 'US',
  local_name: 'United States of America',
  default_measures: Measure.LB_IN,
  default_language: 'en',
  en_name: 'United States of America',
  flag_emoji: '🇺🇸',
  flag_emoji_2: '🇺🇸',
  flag_image: '',
  flag_image_svg: '',
  continent: 'North America',
  allowed_languages: ['en', 'uk', 'ru'],
  allowed_currencies: ['USD', 'CAD', 'UAH'],
  default_currency: 'USD',
  links: {
    privacy_policy: '',
    terms_of_use: '',
    delivery_rules: '',
  },
  support: [],
  settings: [],
  countryDetails: {
    code: 'US',
    emoji: '🇺🇸',
    dial_code: '+1',
    localized_name: 'USA',
  },
};

const EN_LOCALIZATION_INDEX = 0;

const getBrowserLocalization = (availableLocalizations: Language[]) => {
  const browserLanguageCode = navigator.language.split('-')[0];
  const foundLanguage = availableLocalizations.find(
    (loc) => loc.code === browserLanguageCode
  );
  return foundLanguage ?? availableLocalizations[EN_LOCALIZATION_INDEX];
};

export default class LocalizationsStore {
  availableCountries: CountriesResponse[] | undefined = undefined;

  availableLocalizations: Language[] = isMeest(client)
    ? [
        { code: 'en', languageLabel: 'English' },
        { code: 'uk', languageLabel: 'Українська' },
        { code: 'ru', languageLabel: 'Русский' },
      ]
    : [{ code: 'en', languageLabel: 'English' }];

  isLoadingCountriesApi: boolean = false;

  isLanguageChanging: boolean = false;

  availableCurrencies = currencies;

  selectedCountry: CountriesResponse | undefined = undefined;

  selectedLocalization: Language = getBrowserLocalization(
    this.availableLocalizations
  );

  countryLimit: CountryLimit | null = null;

  get languageCode() {
    return (
      this.selectedLocalization ||
      getBrowserLocalization(this.availableLocalizations)
    ).code.toLowerCase();
  }

  get localeParam() {
    const country = this.selectedCountry || DEFAULT_COUNTRY;

    return `/${country.country_code.toLowerCase()}-${this.languageCode}`;
  }

  get availableLanguageDashCountryPairs() {
    if (!this.availableCountries) return;

    const pairs = this.availableCountries
      .map((c) =>
        this.availableLocalizations.map(
          (l) => `${c.country_code.toLowerCase()}-${l.code.toLowerCase()}`
        )
      )
      .flat();

    return pairs;
  }

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'LocalizationsStore-session',
      properties: [
        'isLoadingCountriesApi',
        'isLanguageChanging',
        'availableCurrencies',
        'availableCountries',
        'countryLimit',
      ],
      storage: window.sessionStorage,
    });
    makePersistable(this, {
      name: 'LocalizationsStore-local',
      properties: ['selectedCountry', 'selectedLocalization'],
      storage: window.localStorage,
    });

    reaction(
      () => this.selectedCountry,
      (selectedCountry) => {
        if (!selectedCountry) return;
        this.setAvailableCurrencies(selectedCountry.allowed_currencies);
        this.setAvailableLocalizations(selectedCountry.allowed_languages);
      }
    );
  }

  setAvailableLocalizations = (langs: string[]) => {
    this.availableLocalizations = langs
      .map((lang) => localizationsMap[lang])
      .filter(Boolean);
  };

  setAvailableCurrencies = (currs: string[]) => {
    this.availableCurrencies = currs
      .map((curr) => currenciesMap[curr])
      .filter(Boolean);
  };

  setInitialLocalization = async (lang?: string) => {
    await this.selectLocalization(
      this.selectedLocalization ||
        (lang && this.selectLocalizationByCode(lang)) ||
        getBrowserLocalization(this.availableLocalizations)
    );
  };

  selectLocalization = async (language: Language) => {
    await i18n.changeLanguage(language.code);
    if (language.code === this.selectedLocalization.code) return;
    runInAction(async () => {
      this.fetchCountries(true);
      this.selectedLocalization = language;
    });
  };

  selectLocalizationByCode = async (code: string) => {
    const foundLanguage = this.availableLocalizations.find(
      (loc) => loc.code === code
    );
    if (foundLanguage) {
      runInAction(() => {
        this.selectLocalization(foundLanguage);
      });
    }
  };

  selectCountryByCode = async (code: string) => {
    if (!this.availableCountries) return;

    const foundCountry = this.availableCountries.find(
      (c) => c.country_code === code
    );
    if (foundCountry) {
      runInAction(() => {
        this.selectedCountry = foundCountry;
      });
    }
  };

  fetchCountries = async (rewriteExisting?: boolean) => {
    if (this.availableCountries && !rewriteExisting) return;

    try {
      this.isLoadingCountriesApi = true;
      const apiCountries = await agent.Countries.countries();

      runInAction(() => {
        this.availableCountries = apiCountries;
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingCountriesApi = false;
    }
  };

  findCountryByCode = (countryCode: string) => {
    if (!this.availableCountries) return undefined;

    return this.availableCountries.find(
      (i) => i.country_code === countryCode.toLocaleUpperCase()
    );
  };

  setCountryLimit = (code: string) => {
    const limit = PriorityCountries[code];
    runInAction(() => {
      this.countryLimit = limit;
    });
  };

  setIsLanguageChanging = (isChanging: boolean) => {
    this.isLanguageChanging = isChanging;
  };
}
