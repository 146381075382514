import { useTranslation } from 'react-i18next';
import { TrackingItem } from '../../../../../models/parcelDeliveryMiles';
import {
  TrackingContainer,
  TrackingStatusTitle,
} from './parcel-history.styles';
import ParcelTrackingItem from './parcel-tracking-item.component';
import { getIcon, ParcelHistoryProgress } from './parcel-history.helpers';
import { FlexContainer } from '../../../../../common/flex-container/flex-container.styles';
import { MarginContainer } from '../../../../../common/base-margin/base-margin.component';
import { theme } from '../../../../../styles/themes';

interface Props {
  status: string;
  trackingItems: TrackingItem[];
  progress: ParcelHistoryProgress;
  isLast: boolean;
}

const ParcelHistoryItem = ({
  status,
  trackingItems,
  progress,
  isLast,
}: Props) => {
  const { t } = useTranslation();

  return (
    <MarginContainer $mb='2px'>
      <FlexContainer
        $gap='1.2rem'
        $mb='4px'
        $color={theme.colors.namedColors.primary}
        $justify='left'>
        {getIcon(progress)}
        <TrackingStatusTitle $colorType={progress}>
          {t(status)}
        </TrackingStatusTitle>
      </FlexContainer>
      <TrackingContainer
        $isEmpty={trackingItems.length === 0}
        $hideBorder={isLast}
        $borderType={progress === 'completed' ? 'colored' : 'grey'}>
        {trackingItems.map((item) => (
          <ParcelTrackingItem
            key={status}
            trackingItem={item}
            progress={progress}
          />
        ))}
      </TrackingContainer>
    </MarginContainer>
  );
};

export default ParcelHistoryItem;
