import styled from 'styled-components';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';

export const ActionButtonsContainer = styled(FlexContainer)`
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  gap: 1.6rem;

  & button:first-child {
    width: fit-content;
  }

  & button:last-child {
    max-width: 210px;
  }
`;
