import stringSimilarity from 'string-similarity';
import { countryInfoList } from '../../assets/data/countryInfo';
import {
  AdminUnit,
  CountrySetting,
  SettingCategory,
  SettingName,
} from '../../models/countriesModel';
import { IShipmentCountry } from '../../stores/parcelCreationFlowStore';

export const getCountryZip = (countryCode: string) =>
  countryInfoList.find((country) => country.countryCode === countryCode)
    ?.defaultZIP;

export const getSetting = (
  settings: CountrySetting[],
  category: SettingCategory,
  name: SettingName
): string | undefined =>
  settings.find((s) => s.category === category && s.name === name)?.value;

export const getSettingReceiverCountry = (
  country?: IShipmentCountry | null
): string | undefined =>
  getSetting(country?.settings ?? [], 'delivery_options', 'receiver_country');

export const findAdminUnitFromPrediction = (
  adminUnitKey: string,
  adminUnits: AdminUnit[] | undefined | null
) => {
  const probabilities = adminUnits
    ?.map((unit) =>
      unit.keys
        .map((key) => {
          const probability = stringSimilarity.compareTwoStrings(
            key.toLocaleLowerCase().replace(' oblats', ''),
            adminUnitKey.toLocaleLowerCase().replace(' oblats', '')
          );
          return { value: unit.value, probability };
        })
        .flat()
    )
    .flat();

  return probabilities?.toSorted((a, b) => b.probability - a.probability)[0]
    .value;
};
