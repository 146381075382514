const baseColors = {
  blue0: '#f4f7fc',
  blue5: '#e5f4ff',
  blue10: '#cce8ff',
  blue50: '#c1dbf0',
  blue65: '#b3ddff',
  blue80: '#008cff',
  blue100: '#0070cc',
  blue150: '#005FAD',

  yellow5: '#fffaeb',
  yellow10: '#fff5d9',
  yellow50: '#f3e1af',
  yellow80: '#ffcd45',
  yellow100: '#ffbe0a',
  yellow150: '#e3ac12',

  red5: '#fff0f3',
  red10: '#ffe2e9',
  red50: '#eaaebb',
  red80: '#ed5576',
  red100: '#e82a53',
  red150: '#ba2243',

  green5: '#f2fff6',
  green10: '#e7ffef',
  green50: '#b3dbbf',
  green80: '#6cd88e',
  green100: '#47ce72',
  green150: '#39a55b',

  grey0: '#ffffff',
  grey5: '#f5f6f7',
  grey10: '#f1f2f4',
  grey50: '#dcdde0',
  grey65: '#b7b9bd',
  grey80: '#96999e',
  grey100: '#75777b',
  grey150: '#2c363f',
};
const colors = {
  baseColors,
  namedColors: {
    white: baseColors.grey0,
    transparent: 'transparent',

    primary: baseColors.blue100,
    secondaryPrimary: baseColors.blue80,
    focus: baseColors.blue65,
    secondary: baseColors.yellow100,
    main: baseColors.blue150,
  },

  componentColors: {
    userAvatar: baseColors.blue80,
  },
};

export const meest = {
  colors: {
    ...colors,
    primaryButton: {
      color: colors.namedColors.white,
      backgroundColor: colors.namedColors.primary,
      borderColor: colors.namedColors.transparent,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.namedColors.white,
      backgroundColorHover: colors.baseColors.blue80,
      borderColorHover: colors.namedColors.transparent,
      colorFocus: colors.namedColors.white,
      backgroundColorFocus: colors.baseColors.blue80,
      borderColorFocus: colors.namedColors.transparent,
      outlineColorFocus: colors.namedColors.focus,
      colorActive: colors.namedColors.white,
      backgroundColorActive: colors.baseColors.blue150,
      borderColorActive: colors.namedColors.transparent,
      colorDisabled: colors.namedColors.white,
      backgroundColorDisabled: colors.baseColors.blue50,
      borderColorDisabled: colors.namedColors.transparent,
    },
    secondaryButton: {
      color: colors.namedColors.primary,
      colorDisabled: colors.baseColors.blue50,
      backgroundColor: colors.namedColors.transparent,
      borderColor: colors.namedColors.primary,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.baseColors.blue80,
      backgroundColorHover: colors.namedColors.transparent,
      borderColorHover: colors.baseColors.blue80,
      colorFocus: colors.baseColors.blue80,
      backgroundColorFocus: colors.namedColors.transparent,
      borderColorFocus: colors.namedColors.transparent,
      outlineColorFocus: colors.namedColors.focus,
      colorActive: colors.baseColors.blue150,
      backgroundColorActive: colors.baseColors.blue10,
      borderColorActive: colors.baseColors.blue150,
      backgroundColorDisabled: colors.namedColors.transparent,
      borderColorDisabled: colors.baseColors.blue50,
    },
    secondaryFilledButton: {
      backgroundColor: colors.namedColors.white,
      borderColor: colors.baseColors.grey50,
      backgroundColorHover: colors.namedColors.white,
      backgroundColorFocus: colors.namedColors.white,
      backgroundColorDisabled: colors.namedColors.white,
    },
    tertiaryButton: {
      color: colors.namedColors.primary,
      backgroundColor: colors.namedColors.transparent,
      borderColor: colors.namedColors.transparent,
      outlineColor: colors.namedColors.transparent,
      colorHover: colors.baseColors.blue80,
      backgroundColorHover: colors.baseColors.blue10,
      borderColorHover: colors.namedColors.transparent,
      colorFocus: colors.baseColors.blue80,
      backgroundColorFocus: colors.baseColors.blue10,
      borderColorFocus: colors.namedColors.transparent,
      outlineColorFocus: colors.namedColors.focus,
      colorActive: colors.baseColors.blue150,
      backgroundColorActive: colors.baseColors.blue50,
      borderColorActive: colors.namedColors.transparent,
      backgroundColorDisabled: colors.namedColors.transparent,
      borderColorDisabled: colors.namedColors.transparent,
    },
    textButton: {
      color: colors.namedColors.primary,
      colorHover: colors.baseColors.blue80,
      colorFocus: colors.baseColors.blue80,
      outlineColorFocus: colors.namedColors.transparent,
      colorActive: colors.baseColors.blue150,
    },
    textDangerButton: {
      color: colors.baseColors.red100,
      colorHover: colors.baseColors.red80,
      colorFocus: colors.baseColors.red80,
      outlineColorFocus: colors.namedColors.transparent,
      colorActive: colors.baseColors.red150,
      colorDisabled: colors.baseColors.red50,
    },
    invertedDangerButton: {
      color: '#FE6975',
      backgroundColor: colors.namedColors.white,
      colorHover: colors.namedColors.white,
      colorFocus: colors.namedColors.white,
      colorActive: colors.baseColors.red100,
      backgroundColorActive: colors.namedColors.transparent,
      borderColor: '#FE6975',
      borderColorActive: colors.baseColors.red100,
      backgroundColorHover: '#FE6975',
      backgroundColorFocus: '#FE6975',
    },
    dangerButton: {
      color: colors.namedColors.white,
      backgroundColor: colors.baseColors.red100,
      colorHover: colors.namedColors.white,
      colorFocus: colors.namedColors.white,
      colorActive: colors.namedColors.white,
      backgroundColorActive: colors.baseColors.red150,
      borderColor: colors.baseColors.red100,
      borderColorActive: colors.namedColors.transparent,
      borderColorHover: colors.namedColors.transparent,
      borderColorFocus: colors.baseColors.red50,
      backgroundColorHover: colors.baseColors.red80,
      backgroundColorFocus: colors.baseColors.red80,
      colorDisabled: colors.namedColors.white,
      backgroundColorDisabled: colors.baseColors.red50,
      borderColorDisabled: colors.namedColors.transparent,
    },
    favoriteButton: {
      color: colors.baseColors.grey50,
      colorActive: colors.namedColors.secondary,
    },
    moreButton: {
      colorHover: colors.namedColors.main,
      colorFocus: colors.namedColors.main,
    },
    сheckbox: {
      backgroundColorChecked: colors.baseColors.blue80,
      borderColorChecked: colors.baseColors.blue80,
      backgroundColorFocus: '#0061AF',
      borderColorFocus: '#0061AF',
      backgroundColorHover: '#0061AF',
      borderColorHover: '#0061AF',
      shadowColor: colors.namedColors.focus,
    },
    radio: {
      colorActive: colors.namedColors.primary,
    },
    footerColumnTitle: {
      color: colors.namedColors.main,
    },
    progressBar: {
      backgroundColor: colors.namedColors.main,
    },
    link: {
      color: '#0061AF',
    },
    widgetCalculator: {
      backgroundColor: '#0261af',
    },
    normalText: {
      color: '#5A6673',
    },
    placeholderText: {
      color: '#E0E3E7',
    },
    passwordValidationMarker: {
      backgroundColor: '#EAECEE',
      highlightColor: '#FFCB45',
    },
    textCopyright: {
      color: '#2C2C2C',
    },
    textPopupColorSuccess: {
      color: '#57C278',
    },
    textLabel: {
      color: '#7B8085',
    },
    dragLine: {
      backgroundColor: '#3C3C434D',
    },
    toastError: {
      borderColor: '#ed5576e2',
    },
    headerMain: {
      color: '#334150',
    },
    liveChat: {
      backgroundColorActive: colors.baseColors.blue80,
    },
    input: {
      errorColor: colors.baseColors.red100,
      borderColorFocus: colors.baseColors.blue80,
      labelColor: colors.baseColors.grey80,
    },
    select: {
      borderColorFocus: colors.baseColors.blue80,
    },
    tab: {
      backgroundColor: colors.baseColors.blue0,
      color: 'inherit',
    },
    body: {
      backgroundColor: colors.baseColors.blue0,
    },
    clusterMarker: {
      boxShadowColor: 'rgba(0, 112, 204, 0.2)',
    },
    postName: {
      color: colors.namedColors.primary,
    },
    trackingNumber: {
      color: colors.namedColors.primary,
    },
  },
};
