import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import FormFieldText from '../../common/form-field-text/form-field-text.component';
import Button from '../../common/button/button.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';
import { AddressDataValues } from '../../models/addressDataValues';
import {
  createValidationRule,
  isInitialValuesEqualCurrentValues,
} from '../../utils/forms/forms.utils';
import CountriesFormFieldSearch from '../../common/form-field-countries-search/form-field-countries-search.component';
import useNavigateNewLocale from '../../hooks/use-navigate-to-new-locale.hook';
import { AccountSettingsForm } from '../../common/form/form.styles';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_WITH_NUMBERS,
  VALIDATION_RULE_ONLY_LETTERS,
} from '../../constants/validation';
import PlacesFormFieldSearch from '../../common/form-field-places-search/form-field-places-search.component';
import {
  getPostalCodeLabel,
  getRegionLabel,
} from '../../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import InputSearchWithSidebarForm from '../../common/input-search-with-sidebar/input-search-with-sidebar-form.component';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right-icon.svg';
import { findAdminUnitFromPrediction } from '../../utils/countries/countries.utils';

const validationSchema = Yup.object({
  country: Yup.string().required('this_field_cannot_be_empty'),
  region: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  city: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  post_code: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  street: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  full_address: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  building: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  apartment: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  section: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_NUMBERS
  ).notRequired(),
  buzz_code: createValidationRule(
    VALIDATION_RULE_IS_NOT_CYRILLIC
  ).notRequired(),
  note: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).notRequired(),
});

type IFormValues = AddressDataValues & { full_address: string };

const MyAddressEditForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { user, updateAddress },
    localizationsStore: { findCountryByCode },
    placesSearchStore: { setUserPlaceDetails, setPlaceDetails },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  useEffect(() => {
    // On the first run init place values in PlacesSearchStore based on current user.
    // Then they will be updated based on response from Places API
    setUserPlaceDetails();

    // Clear PlacesSearchStore when form is closed
    return () => {
      setPlaceDetails(null);
    };
  }, [setPlaceDetails, setUserPlaceDetails]);

  const [selectedCountry, setSelectedCountry] = useState(
    () => findCountryByCode(user!.country)!
  );

  const initialValues = useMemo(
    () =>
      ({
        country: selectedCountry.country_code,
        full_address: user?.address?.full_address || '',
        region: user?.address?.region || '',
        city: user?.address?.city || '',
        post_code: user?.address?.post_code || '',
        street: user?.address?.street || '',
        building: user?.address?.building || '',
        section: user?.address?.section || '',
        apartment: user?.address?.apartment || '',
        buzz_code: user?.address?.buzz_code || '',
        note: user?.address?.note || '',
      }) as IFormValues,
    [
      selectedCountry?.country_code,
      user?.address?.apartment,
      user?.address?.building,
      user?.address?.buzz_code,
      user?.address?.city,
      user?.address?.full_address,
      user?.address?.note,
      user?.address?.post_code,
      user?.address?.region,
      user?.address?.section,
      user?.address?.street,
    ]
  );

  const handleFormSubmit = async (
    formValues: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    if (isInitialValuesEqualCurrentValues(initialValues, formValues)) {
      onCancel && onCancel();
      return;
    }

    formikHelpers.setFieldTouched('region', true, true);

    navigateToNewLocale({
      countryCode: formValues.country.toLocaleLowerCase(),
    });
    const success = await updateAddress(formValues);
    success && toastSuccess(t('data_updated_successfully'));
    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const adminUnits = selectedCountry?.administrative_units?.allowed_values;
  const adminUnitsTitle = selectedCountry?.administrative_units?.title;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}>
      {({
        handleSubmit,
        values,
        isSubmitting,
        setValues,
        validateField,
        validateForm,
        setFieldValue,
        resetForm,
        setStatus,
        status,
      }) => {
        const onChange = (_: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('full_address', '', false);
        };
        const validationMethod = status === 'submit' ? 'always' : 'default';
        return (
          <AccountSettingsForm
            noValidate
            onSubmit={(e) => {
              setStatus('submit');
              validateForm(values);
              e.preventDefault();
              handleSubmit();
            }}>
            <div className='inputs'>
              <CountriesFormFieldSearch
                initialCountry={selectedCountry}
                name='country'
                label={t('country')}
                onCountrySelected={(c) => {
                  const newCountry = findCountryByCode(c);
                  if (
                    !!newCountry?.country_code &&
                    selectedCountry.country_code !== newCountry.country_code
                  ) {
                    // Resets places search store in case country was changed and therefore initial values and as a result whole form state is reset.
                    setPlaceDetails(null);
                    resetForm({
                      values: {
                        country: newCountry?.country_code || '',
                        full_address: '',
                        region: '',
                        city: '',
                        post_code: '',
                        street: '',
                        building: '',
                        section: '',
                        apartment: '',
                        buzz_code: '',
                        note: '',
                      },
                    });
                  }
                  setSelectedCountry(newCountry!);
                }}
              />
              <PlacesFormFieldSearch
                selectedCountryCode={selectedCountry?.country_code}
                name='full_address'
                placeholder=''
                label={t('address_search')}
                formikValidateField={validateField}
                setValues={(placeInfo) => {
                  const region =
                    findAdminUnitFromPrediction(placeInfo.region, adminUnits) ??
                    '';
                  setValues({
                    ...values,
                    full_address: placeInfo.address,
                    region,
                    city: placeInfo.city,
                    street: placeInfo.street,
                    post_code: placeInfo.zipCode,
                    building: placeInfo.building,
                    apartment: placeInfo.apartment,
                  });
                }}
              />

              <FormFieldText
                maxLength={10}
                name='building'
                label='building'
                onChange={onChange}
                validationMethod={validationMethod}
                isOptional
              />

              <FormFieldText
                maxLength={100}
                name='street'
                label='street'
                validationMethod={validationMethod}
                onChange={onChange}
              />

              <FormFieldText
                maxLength={10}
                name='apartment'
                label='apartment'
                onChange={onChange}
                validationMethod={validationMethod}
                isOptional
              />

              <FormFieldText
                maxLength={100}
                name='city'
                placeholder=''
                label='city'
                onChange={onChange}
                validationMethod={validationMethod}
              />
              {adminUnits && adminUnits.length > 0 ? (
                <InputSearchWithSidebarForm
                  name='region'
                  inputIcon={ArrowRightIcon}
                  shouldValidateSearchQuery
                  displayAllOptionsWithEmptyFilter
                  inputValue={values.region ?? ''}
                  defaultOption={values.region ?? ''}
                  isOptionSelected={(region) => region === values.region}
                  label={adminUnitsTitle || ''}
                  sidebarTitle={adminUnitsTitle || ''}
                  sidebarInputPlaceholder={t('region_search_placeholder', {
                    region: getRegionLabel(
                      selectedCountry?.country_code
                    ).toLowerCase(),
                  })}
                  placeholder={t('select_region', {
                    region: getRegionLabel(
                      selectedCountry?.country_code
                    ).toLowerCase(),
                  })}
                  getSearchOptions={(filter) => {
                    const userRegion = user?.address?.region;
                    const adminUnitsValues = adminUnits?.map((i) => i.value);
                    const regions = filter
                      ? adminUnitsValues!.filter((i) =>
                          i
                            .toLowerCase()
                            .includes(filter.toLocaleLowerCase().trim())
                        )
                      : adminUnitsValues;
                    if (!userRegion) return Promise.resolve(regions);

                    const found = regions!.find(
                      (region) => userRegion === region
                    );
                    if (found) {
                      const filteredArray = regions!.filter(
                        (region) => region !== userRegion
                      );
                      return Promise.resolve([found, ...filteredArray]);
                    }
                    return Promise.resolve(regions);
                  }}
                  onSearchOptionSelected={(selectedOption) => {
                    setFieldValue('full_address', '');
                    setFieldValue('region', selectedOption, true);
                  }}
                  getKeyForSearchOption={(option) => option}
                  getDisplayValueForSearchOption={(option) => option}
                  validationRule={VALIDATION_RULE_ONLY_LETTERS}
                  validationMethod='touched'
                />
              ) : (
                <FormFieldText
                  maxLength={100}
                  name='region'
                  placeholder=''
                  onChange={onChange}
                  validationMethod={validationMethod}
                  label={adminUnitsTitle || ''}
                />
              )}

              <FormFieldText
                maxLength={10}
                name='post_code'
                placeholder=''
                onChange={onChange}
                validationMethod={validationMethod}
                label={getPostalCodeLabel(selectedCountry?.country_code)}
              />
            </div>
            <div className='buttons'>
              <Button
                appearance='secondary'
                disabled={isSubmitting}
                onClick={() => onCancel && onCancel()}>
                {t('cancel')}
              </Button>
              <Button
                isLoading={isSubmitting}
                type='submit'>
                {t('save_changes')}
              </Button>
            </div>
          </AccountSettingsForm>
        );
      }}
    </Formik>
  );
};

export default observer(MyAddressEditForm);
