import React from 'react';
import { InputMessageContainer } from './input-message.styles';
import { ReactComponent as ChevronLeft } from '../../assets/error-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-tip.svg';
import { ReactComponent as AlertCircle } from '../../assets/alert-circle.svg';
import { ReactComponent as AlertTriangleYellow } from '../../assets/alert-triangle-yellow.svg';
import { exhaustiveGuard } from '../../utils/generic/generic.utils';

export type InputMessageType =
  | 'error'
  | 'info'
  | 'alert'
  | 'alertTriangleYellow';
export type InputMessageSize = 'small' | 'medium';

interface Props {
  children: React.ReactNode;
  hasIcon?: boolean;
  fontSize?: string;
  color?: string;
  type?: InputMessageType;
  size?: InputMessageSize;
  noMargin?: boolean;
}

const getIcon = (type: InputMessageType) => {
  switch (type) {
    case 'error':
      return <ChevronLeft />;
    case 'info':
      return <InfoIcon />;
    case 'alert':
      return <AlertCircle />;
    case 'alertTriangleYellow':
      return <AlertTriangleYellow />;
    default:
      exhaustiveGuard(type);
  }
};

const InputMessage = ({
  children,
  hasIcon = true,
  fontSize,
  color,
  type = 'info',
  size,
  noMargin,
}: Props) => (
  <InputMessageContainer
    $hasIcon={hasIcon}
    $fontSize={fontSize}
    $size={size || (type === 'info' ? 'small' : 'medium')}
    $color={color}
    $noMargin={noMargin}
    $type={type}>
    {hasIcon && getIcon(type)}
    {children}
  </InputMessageContainer>
);

export default InputMessage;
