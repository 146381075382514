import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import ParcelCreationProgress from '../../features/parcel-creation/parcel-creation-progress/parcel-creation-progress.component';
import ParcelCreationProgressMobile from '../../features/parcel-creation/parcel-creation-progress/parcel-creation-progress-mobile.component';
import { ParcelStepLeftContainer } from '../../features/parcel-creation/parcel-step-left-container/parcel-step-left-container.styles';
import { ParcelStepRightContainer } from '../../features/parcel-creation/parcel-step-right-container/parcel-step-right-container.styles';
import { useIsLastStep } from '../../hooks/use-is-last-step.hook';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { useStore } from '../../stores/store';
import {
  DesktopParcelWrapper,
  ParcelTitle,
  WrapperForParcelContainers,
} from '../../features/parcel-creation/parcel-title-and-wrapper/parcel-title-and-wrapper.styles';
import { useLoadPlacesLibs } from '../../hooks/use-load-places-libs.hook';
import { AppRoute } from '../../constants/route';
import ParcelCreationBottom from '../../features/parcel-creation/parcel-creation-bottom/parcel-creation-bottom.component';
import { useNavigationBlocker } from '../../hooks/use-navigation-blocker.hook';
import { toTranslationKey } from '../../models/parcelCreationFlowNavigation';

const parcelCreationContent = (isLastStep: boolean) => (
  <>
    <ParcelStepLeftContainer $isLastStep={isLastStep}>
      <ParcelCreationProgressMobile />
      <Outlet />
      <ParcelCreationBottom isMobileView />
    </ParcelStepLeftContainer>
    <ParcelStepRightContainer>
      <ParcelCreationProgress />
    </ParcelStepRightContainer>
  </>
);

const ParcelCreation = () => {
  const isMobile = useIsMobile();
  const isLastStep = useIsLastStep();
  const location = useLocation();

  const {
    parcelCreationStore: {
      navigation,
      isParcelCreationInProgress,
      setIsParcelCreationInProgress,
      resetAllData,
    },
    userStore: { user, getOnboardingManual },
  } = useStore();
  const { t } = useTranslation();
  useLoadPlacesLibs();

  useEffect(() => {
    if (location.pathname.includes(AppRoute.PARCEL_CREATE)) {
      setIsParcelCreationInProgress(true);
    }
  }, [location.pathname, setIsParcelCreationInProgress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.current]);

  useNavigationBlocker({
    isInProgress: isParcelCreationInProgress,
    Route: AppRoute.PARCEL_CREATE,
    setIsInProgress: setIsParcelCreationInProgress,
    onLeave: resetAllData,
  });

  useEffect(() => {
    if (user?.show_onboarding_manual && user?.phone_verified) {
      getOnboardingManual();
    }
  }, [getOnboardingManual, user?.phone_verified, user?.show_onboarding_manual]);

  return isMobile ? (
    parcelCreationContent(isLastStep)
  ) : (
    <DesktopParcelWrapper>
      <ParcelTitle>{`${t(toTranslationKey(navigation.current?.name))}`}</ParcelTitle>
      <WrapperForParcelContainers>
        {parcelCreationContent(isLastStep)}
      </WrapperForParcelContainers>
    </DesktopParcelWrapper>
  );
};

export default observer(ParcelCreation);
