import { makeAutoObservable, runInAction } from 'mobx';
import { GenericAbortSignal } from 'axios';
import { makePersistable } from 'mobx-persist-store';
import agent from '../api/agent';
import {
  ParcelListShipment,
  Shipment,
  transformShipmentToParcelListShipment,
} from '../models/parcelDeliveryMiles';
import {
  DEFAULT_ORDER_ITEM,
  IDirectionItem,
  IOrderItem,
  IParcelListPathValue,
  IStatusItem,
  PARCEL_LIST_SIDEBARS,
  orderItems,
} from '../routes/parcel-list/config';
import { buildQueryParams } from '../utils/uri/uri.utils';
import { IOptionsPages } from '../models/paginationOptions';
import { store } from './store';
import { Manual } from '../models/manual';
import { Modals } from '../constants/modals';
import { handleError, postponeExecution } from '../utils/generic/generic.utils';
import {
  BusMessageHandler,
  handleBusMessages,
} from '../utils/message-bus/message-bus-utils';
import { MessageBus } from '../models/apiResponse';

export type ShipmentQueryFilter =
  | 'all'
  | 'no_filter'
  | 'to_me'
  | 'from_me'
  | 'favorite'
  | 'archived'
  | 'in_delivery'
  | 'ready_to_pick_up'
  | 'received'
  | 'draft';

export type ShipmentQueryOrder =
  | 'created_at_from_closest'
  | 'created_at_from_latest'
  | 'delivery_at_from_closest'
  | 'delivery_at_from_latest';

export type PerPageParcelCount = 12 | 24 | 48 | 100;

export enum ParcelListLocalStorage {
  order = 'parcel_list_order',
}

const getSortingStorageItem = (routeName: IParcelListPathValue['path']) =>
  `${routeName}:${ParcelListLocalStorage.order}`;

export interface IUpdateParcelField {
  field: keyof Shipment;
  value: unknown;
  shipmentId: number;
  onUpdate?: (index: number, newShipment: ParcelListShipment) => void;
}

interface ShipmentQueryOptions extends IOptionsPages {
  filter?: (ShipmentQueryFilter | undefined)[];
  order?: ShipmentQueryOrder;
  search?: string;
}

export default class ParcelListStore {
  shipment: Shipment | undefined = undefined;

  parcels: ParcelListShipment[] | undefined = undefined;

  parcelsInPaymentProgress: number[] = [];

  isLoadingShipmentApi: boolean = false;

  isLoadingSearch: boolean = false;

  selectedStatus: IStatusItem | null = null;

  selectedDirection: IDirectionItem | null = null;

  isDesktopManualVisible: boolean = false;

  defaultDirection: IDirectionItem = {
    label: 'all_parcels',
    value: 'all',
  };

  selectedOrder: IOrderItem = DEFAULT_ORDER_ITEM;

  options: ShipmentQueryOptions = {
    page: 1,
    perPage: 12,
    filter: ['all'],
    order: 'created_at_from_closest',
  };

  isPrintingLabel = false;

  routeName: IParcelListPathValue['path'] = 'parcellist';

  savedManuals: { [key: number]: Manual } = {};

  activeManualId: number | undefined = undefined;

  isManualLoading: boolean = false;

  fetchingPaymentLinkId: number | null = null;

  isDontShowAgainVisible: boolean = false;

  feedbackShipmentId: number | null = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ParcelListStore',
      properties: [
        'shipment',
        'parcels',
        'isLoadingShipmentApi',
        'isLoadingSearch',
        'defaultDirection',
        'options',
        'isPrintingLabel',
      ],
      storage: window.sessionStorage,
    });
  }

  setRouteName = (value: IParcelListPathValue['path']) => {
    this.routeName = value;
  };

  getInitialOrder = (routeName: IParcelListPathValue['path']) => {
    const localData = localStorage.getItem(getSortingStorageItem(routeName));
    let direction = DEFAULT_ORDER_ITEM;

    if (localData) {
      direction = orderItems.find((item) => item.value === localData)!;
    }

    this.selectedOrder = direction;
  };

  setOrder = (order: IOrderItem) => {
    this.selectedOrder = order;
  };

  setDirection = (direction: IDirectionItem | null) => {
    this.selectedDirection =
      this.defaultDirection?.value !== direction?.value ? direction : null;
  };

  setDefaultDirection = (direction: IDirectionItem['value']) => {
    this.defaultDirection = {
      label: 'all_parcels',
      value: direction,
    };
  };

  setStatus = (status: IStatusItem | null) => {
    this.selectedStatus = status;
  };

  getParcel = async (shipmentId: number) => {
    try {
      const { shipment, messageBus } = await agent.Shipment.summary(shipmentId);
      runInAction(() => {
        this.shipment = shipment;
      });

      if (messageBus) {
        this.handleMessages(messageBus);
      }

      return shipment;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  fetchParcels = async (
    options: ShipmentQueryOptions = {
      page: 1,
      perPage: 12,
      filter: [this.defaultDirection.value],
      order: 'created_at_from_closest',
    },
    signal?: GenericAbortSignal,
    showMessageBus?: boolean,
    append: boolean = false
  ) => {
    this.isLoadingShipmentApi = true;
    const query = buildQueryParams(options);

    try {
      const {
        data,
        meta,
        message_bus: messageBus,
      } = await agent.Shipment.shipment(query, signal);

      if (showMessageBus) this.handleMessages(messageBus);

      runInAction(() => {
        const order = this.selectedOrder.value;

        if (
          append &&
          options.page &&
          options.page > 1 &&
          this.parcels &&
          this.parcels.length > 0
        ) {
          this.parcels = [...this.parcels, ...data!];
        } else {
          this.parcels = data;
        }

        this.options = { ...options, order };
        this.options.totalPagesCount = meta?.pageCount;
        this.options.totalElCount = meta?.totalCount;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  get isEmpty() {
    if (!this.parcels) return true;

    return this.parcels.length === 0;
  }

  get hasSearchValue() {
    if (this.options.search === undefined) return false;

    return this.options.search !== '';
  }

  get order() {
    return (localStorage.getItem(getSortingStorageItem(this.routeName)) ||
      'created_at_from_closest') as ShipmentQueryOrder;
  }

  getPaymentLink = async (shipmentId: number) => {
    this.fetchingPaymentLinkId = shipmentId;
    try {
      const link = await agent.Shipment.payment(shipmentId);
      return link;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.fetchingPaymentLinkId = null;
      });
    }
  };

  getInvoiceData = async (shipmentId: number) => {
    try {
      const data = await agent.Shipment.invoice(shipmentId);
      return data;
    } catch (error) {
      handleError(error);
    }
  };

  showParcelManual = () => {
    store.modalStore.open({
      id: Modals.PARCEL_MANUAL,
      name: Modals.PARCEL_MANUAL,
      opensManually: true,
    });
  };

  showLeaveFeedbackModal = () => {
    store.modalStore.open({
      id: Modals.LEAVE_FEEDBACK,
      name: Modals.LEAVE_FEEDBACK,
    });
  };

  confirmParcelManual = async (shipmentId: number) => {
    try {
      await agent.Shipment.confirmManual(shipmentId);
    } catch (error) {
      handleError(error);
    }
  };

  getParcelManual = async ({
    shipmentId,
    shouldShowDontShowAgain,
  }: {
    shipmentId: number;
    shouldShowDontShowAgain?: boolean;
  }) => {
    this.activeManualId = shipmentId;
    this.showParcelManual();
    this.isManualLoading = true;
    this.isDontShowAgainVisible = !!shouldShowDontShowAgain;

    const shipmentInPaymentProgress =
      this.parcelsInPaymentProgress.includes(shipmentId);

    if (!shipmentInPaymentProgress && this.savedManuals[shipmentId]) {
      this.isManualLoading = false;
      return;
    }

    try {
      postponeExecution(
        async () => {
          const data = await agent.Shipment.manual(shipmentId);
          runInAction(() => {
            this.savedManuals[shipmentId] = data;
            this.isManualLoading = false;
          });
        },
        () => this.parcelsInPaymentProgress.includes(shipmentId)
      );
    } catch (error) {
      handleError(error);
    }
  };

  tryUpdateShipmentPaymentStatus = async (shipmentId: number) => {
    if (!this.isParcelInPaymentProgress(shipmentId)) return true;

    const recentlyPaidParcel = this.parcels?.find((p) => p.id === shipmentId);

    if (!recentlyPaidParcel) return true;

    if (recentlyPaidParcel.status === 'paid') {
      this.removeParcelFromPaymentInProgress(shipmentId);
      return true;
    }

    const isPaidStatusReached =
      await this.updateParcelPaymentStatus(shipmentId);

    if (isPaidStatusReached) {
      this.removeParcelFromPaymentInProgress(shipmentId);
      return true;
    }
    return false;
  };

  updateParcelPaymentStatus = async (shipmentId: number) => {
    if (!this.parcels) return;

    const parcel = await this.getParcel(shipmentId);

    if (!parcel) return;

    if (parcel.status === 'paid') {
      runInAction(() => {
        this.parcels = this.parcels!.map((item) => {
          if (item.id === parcel!.id) {
            return {
              ...item,
              status: 'paid',
              flags: {
                ...item.flags,
                can_pay: false,
              },
            } as ParcelListShipment;
          }

          return item;
        });
        return true;
      });
      return false;
    }

    try {
      const { shipment } = await agent.Shipment.summary(shipmentId);
      if (!shipment) return;

      runInAction(() => {
        this.parcels = this.parcels!.map((item) => {
          if (item.id === shipment!.id) {
            return transformShipmentToParcelListShipment(shipment);
          }

          return item;
        });
      });
    } catch (error) {
      handleError(error);
    }
  };

  getParcels = async (
    page: number,
    perPage: number,
    parcelType: ShipmentQueryFilter,
    signal?: GenericAbortSignal,
    showMessageBus?: boolean
  ) => {
    this.fetchParcels(
      {
        page,
        perPage,
        filter: [parcelType],
        order: this.order,
      },
      signal,
      showMessageBus
    );
  };

  filterParcels = async (
    options?: Pick<ShipmentQueryOptions, 'filter' | 'order'>,
    shouldShowLoading: boolean = false
  ) => {
    const filters = options?.filter?.filter(Boolean) || [
      this.defaultDirection.value,
    ];

    const opts: ShipmentQueryOptions = {
      page: 1,
      perPage: 12,
      filter: filters,
      order: options?.order,
    };
    if (this.hasSearchValue) {
      opts['search'] = this.options.search;
    }

    this.fetchParcels(opts);
  };

  getParcelsOrder = async (order: ShipmentQueryOrder) => {
    const query = buildQueryParams({ ...this.options, order });
    localStorage.setItem(getSortingStorageItem(this.routeName), order);

    try {
      this.isLoadingShipmentApi = true;
      const { data } = await agent.Shipment.shipment(query);

      runInAction(() => {
        this.isLoadingShipmentApi = false;
        this.parcels = data;
      });
    } catch (error) {
      handleError(error);
    }
  };

  updateParcelField = async ({
    field,
    value,
    shipmentId,
    onUpdate,
  }: IUpdateParcelField) => {
    if (!shipmentId) return false;

    try {
      const shipment = await agent.Shipment.update(shipmentId, {
        field,
        value,
      });

      if (shipment?.id && shipmentId === shipment.id) {
        runInAction(async () => {
          const parcelIndex = this.parcels?.findIndex(
            (ship) => shipment.id === ship.id
          );

          if (parcelIndex !== undefined && parcelIndex !== -1 && this.parcels) {
            if (
              store.navStore.isSidebarOpenedByName(
                `${PARCEL_LIST_SIDEBARS.details}_${shipmentId}`
              )
            ) {
              // @ts-ignore
              this.shipment![field] = value;
            }
            if (onUpdate) onUpdate(parcelIndex, shipment);
            else this.parcels[parcelIndex] = shipment;
          }
        });
      }

      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  };

  toggleFavorite = async (
    options: Omit<IUpdateParcelField, 'field' | 'onUpdate'>
  ) => this.updateParcelField({ ...options, field: 'is_favorite' });

  archiveParcel = async (
    options: Omit<IUpdateParcelField, 'field' | 'onUpdate'>
  ) =>
    this.updateParcelField({
      ...options,
      field: 'is_archived',
      onUpdate: (index) => {
        this.parcels!.splice(index, 1);
      },
    });

  getParcelsSearch = async (searchQuery: string) => {
    const options: ShipmentQueryOptions = {
      ...this.options,
      search: searchQuery,
      page: 1, // substitute the first page for the backend to display the search (most likely temporary)
    };

    const query = buildQueryParams(options);

    this.isLoadingSearch = true;
    this.isLoadingShipmentApi = true;

    try {
      const { data, meta } = await agent.Shipment.shipment(query);

      runInAction(() => {
        this.parcels = data;
        this.options['search'] = searchQuery;

        this.options.totalPagesCount = meta?.pageCount;
        this.options.totalElCount = meta?.totalCount;
        this.options.page = 1;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingSearch = false;
        this.isLoadingShipmentApi = false;
      });
    }
  };

  incrementPerPage = (perPage: number) => {
    this.options.perPage = perPage + 20;
  };

  setParcels = (data: ParcelListShipment[] | undefined) => {
    this.parcels = data;
  };

  printLabel = async (shipmentId: number) => {
    try {
      this.isPrintingLabel = true;
      const res = await agent.Shipment.label(shipmentId);

      return res;
    } catch (error) {
      throw new Error('something_went_wrong_try_again');
    } finally {
      runInAction(() => {
        this.isPrintingLabel = false;
      });
    }
  };

  toggleDesktopManualVisibility = () => {
    this.isDesktopManualVisible = !this.isDesktopManualVisible;
  };

  showDesktopManual = () => {
    this.isDesktopManualVisible = true;
  };

  hideDesktopManual = () => {
    this.isDesktopManualVisible = false;
  };

  setActiveManualId = (id: number | undefined) => {
    this.activeManualId = id;
  };

  removeParcelFromPaymentInProgress = (id: number) => {
    // console.log('removing parcel from payment in progress', [
    //  ...this.parcelsInPaymentProgress,
    // ]);
    this.parcelsInPaymentProgress = this.parcelsInPaymentProgress.filter(
      (parcelId) => parcelId !== id
    );
  };

  addParcelInPaymentProgress = (id: number) => {
    this.parcelsInPaymentProgress.push(id);
  };

  isParcelInPaymentProgress = (id: number) =>
    this.parcelsInPaymentProgress.some((parcelId) => parcelId === id);

  private handleMessages = (messageBus: MessageBus[] | undefined) => {
    if (!messageBus) return;

    const showManualHandler: BusMessageHandler = {
      selector: ({ type, subtype }) =>
        type === 'manual' && subtype === 'shipmentManual',
      handler: (msg) =>
        this.getParcelManual({
          shipmentId: msg.shipment_id,
          shouldShowDontShowAgain: true,
        }),
    };

    const leaveFeedbackHandler: BusMessageHandler = {
      selector: ({ type, subtype }) =>
        type === 'feedback' && subtype === 'leaveFeedback',
      handler: (msg) => {
        this.feedbackShipmentId = msg.shipment_id;
        this.showLeaveFeedbackModal();
      },
    };

    const promoCodeDeletedHandler: BusMessageHandler = {
      selector: (msg: MessageBus) =>
        msg.type === 'promoCode' && msg.subtype === 'promoCodeDeleted',
      handler: (msg: MessageBus) => {
        store.commonStore.toastError(msg.message);
      },
    };

    const repriceHandler: BusMessageHandler = {
      selector: (msg: MessageBus) => msg.type === 'reprice',
      handler: (msg: MessageBus) => {
        // As far as I understand from Leonid's words,
        // it is not necessary to break the price from mesedjbas for the time being,
        // it is a plan for the future
        // const newPriceInfo = msg.additional_information?.find(
        //   (info) => info.key === 'new_price'
        // );

        runInAction(() => {
          if (this.shipment) {
            // if (newPriceInfo) {
            //   const newPrice = parseFloat(newPriceInfo.value);
            //   this.shipment.converted_price = {
            //     ...this.shipment.converted_price,
            //     total_price: newPrice,
            //   };
            // }
            this.shipment.markers!.tip = msg.message;
          }
        });
      },
    };

    handleBusMessages(
      messageBus,
      [
        showManualHandler,
        leaveFeedbackHandler,
        promoCodeDeletedHandler,
        repriceHandler,
      ],
      true
    );
  };
}
