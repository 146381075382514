import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../tooltip/tooltip.component';
import Button from '../button/button.component';
import { ButtonWrapper } from './map.styles';
import ZoomControls from './zoom-controls.component';

interface Props {
  onCurrentLocationClick: () => void;
  disableZoom: boolean;
  disableCurrentLocation: boolean;
  showTooltip: boolean;
  hideCurrentLocation?: boolean;
}

const MapControls = ({
  disableZoom,
  disableCurrentLocation,
  hideCurrentLocation,
  showTooltip,
  onCurrentLocationClick,
}: Props) => (
  <ButtonWrapper>
    <Tooltip placement='left'>
      <TooltipTrigger
        style={{ width: '1.6rem' }}
        asChild>
        {!hideCurrentLocation && (
          <Button
            onClick={onCurrentLocationClick}
            appearance='tertiary'
            icon={{ glyph: 'location-cross' }}
            size='large'
            fullWidth={false}
            disabled={disableCurrentLocation}
          />
        )}
      </TooltipTrigger>
      {showTooltip && (
        <TooltipContent maxWidth='25rem'>
          You have denied access to your current location
        </TooltipContent>
      )}
    </Tooltip>
    <ZoomControls disabled={disableZoom} />
  </ButtonWrapper>
);

export default MapControls;
