import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import OtpInput from '../../../common/otp-input/otp-input.component';
import {
  AuthMessageScreenCenteredElement,
  AuthMessageScreen,
} from '../auth-messege-screen/auth-message-screen.styles';
import { TextBody } from '../../../common/typography/typography.styles';
import Button from '../../../common/button/button.component';
import { Buttons } from './sign-up.styles';
import { useStore } from '../../../stores/store';
import { AddPhoneHeader } from '../phone-verification/add-phone.component.styles';
import { AppRoute } from '../../../constants/route';
import {
  generateLocaleRoute,
  generateMyRoute,
} from '../../../utils/uri/uri.utils';
import { theme } from '../../../styles/themes';

export const CONFIRM_NUMBER_DIGITS_AMOUNT = 4;

const ConfirmPhoneNumber = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(true);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const isFilled = verifyCode.length === CONFIRM_NUMBER_DIGITS_AMOUNT;
  const {
    localizationsStore: { selectedCountry },
    navStore: { setAuthCurrentScreen, closeAllRightSidebars },
    commonStore: { toastSuccess },
    userStore: {
      user,
      isNeedMigrationAgreement,
      isPhoneVerifying,
      verifyPhone,
      updatePhone,
    },
  } = useStore();
  const [searchParams] = useSearchParams();
  const navigateToParam = searchParams.get('redirect');

  const countryCode = selectedCountry?.country_code || '';
  const phone = user?.phoneForVerification || '';

  useEffect(() => {
    if (isFilled) {
      confirmButtonRef.current?.focus();
    }
  }, [isFilled]);

  async function handleSendAgain() {
    try {
      const userPhone = {
        country_code: countryCode,
        phone_number: user?.phoneForVerification || '',
      };
      await updatePhone(userPhone);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {
    const success = await verifyPhone(countryCode, phone, verifyCode);

    if (success) {
      const urlWithouMigration =
        navigateToParam || generateMyRoute(AppRoute.PARCEL_LIST);
      const url = isNeedMigrationAgreement
        ? generateLocaleRoute(`${AppRoute.AUTH}/${AppRoute.DATA_MIGRATION}`)
        : urlWithouMigration;

      navigate(url, {
        replace: true,
      });

      setAuthCurrentScreen(
        isNeedMigrationAgreement ? 'data-migration-agreement' : 'auth-tabs'
      );

      closeAllRightSidebars();

      toastSuccess(t('successfully_verified_phone'));
    } else {
      setIsCodeValid(false);
    }
  };

  return (
    <AuthMessageScreen>
      <AuthMessageScreenCenteredElement>
        <AddPhoneHeader>{t('confirm_the_phone_number')}</AddPhoneHeader>
      </AuthMessageScreenCenteredElement>
      <TextBody $color={theme.colors.baseColors.grey100}>
        <Trans
          i18nKey='phone_sent_signup'
          components={{
            1: <b />,
          }}
          values={{ phoneNumber: `+${phone}` }}
        />
      </TextBody>

      <OtpInput
        handleSendAgain={handleSendAgain}
        numOfDigits={CONFIRM_NUMBER_DIGITS_AMOUNT}
        onChange={(val: string) => {
          setVerifyCode(val);
          setIsCodeValid(true);
        }}
        isInvalid={!isCodeValid}
        errorMessage={t('incorrect_verification_code')}
        fullWidth
        titleColor={theme.colors.baseColors.grey100}
        title={t('enter_code_from_sms')}
      />
      <Buttons $gap='1.6rem'>
        <Button
          onClick={handleSubmit}
          disabled={!isFilled || !isCodeValid || isPhoneVerifying}
          ref={confirmButtonRef}>
          {t('confirm')}
        </Button>
        <Button
          appearance='tertiary'
          onClick={() => {
            setAuthCurrentScreen('add-phone-number');
          }}>
          {t('back')}
        </Button>
      </Buttons>
    </AuthMessageScreen>
  );
};

export default observer(ConfirmPhoneNumber);
