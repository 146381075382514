import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { useStore } from '../../stores/store';
import FormFieldText from '../../common/form-field-text/form-field-text.component';
import Button from '../../common/button/button.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';
import { ContactDetailsValues } from '../../models/contactDetailsValues';
import FormFieldPhone, {
  getCountryCallingCodeWrapper,
} from '../../common/form-field-phone/form-field-phone.component';
import {
  EMAIL_REGEX,
  VALIDATION_RULE_IS_NOT_CYRILLIC,
} from '../../constants/validation';
import {
  createValidationRule,
  isInitialValuesEqualCurrentValues,
} from '../../utils/forms/forms.utils';

export const phoneRegex = /^[0-9]{11,16}$/;

const phoneValidation = (phoneField: string, countryCodeField: string) =>
  Yup.string()
    .required('this_field_cannot_be_empty')
    .test('phone', 'invalid_phone_number', (_, ctx) => {
      const countryCode = ctx.parent[countryCodeField];
      const phoneNumber = ctx.parent[phoneField];
      if (phoneNumber.length <= 4 || phoneNumber.length > 16) return false;

      if (countryCode) {
        const combinedValue = `+${getCountryCallingCodeWrapper(countryCode)}${phoneNumber}`;
        const parsed = parsePhoneNumber(combinedValue);

        return (
          parsed.isValid() && parsed.number.length === combinedValue.length
        );
      }

      const combinedValue = `+${phoneNumber}`;
      const parsed = parsePhoneNumber(combinedValue);

      return parsed.isValid() && parsed.number.length === combinedValue.length;
    });

export const contactDetailsValidationSchema = Yup.object({
  phone: phoneValidation('phone', 'phone_country_code'),

  phone_country_code: Yup.string().notRequired(),

  email: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
    .required('this_field_cannot_be_empty')
    .matches(EMAIL_REGEX, 'wrong_email_format'),
});

export const contactDetailsValidationSchemaWithAdditionalPhone =
  contactDetailsValidationSchema.shape({
    contact_phone_number: phoneValidation(
      'contact_phone_number',
      'contact_phone_country_code'
    ),

    contact_phone_country_code: Yup.string().notRequired(),
  });

export function removeCountryCallingCodeFromPhone(
  phone?: string | null,
  countryCode?: string | null
): string | undefined {
  if (phone && countryCode) {
    const callingCode = getCountryCallingCodeWrapper(countryCode);
    if (callingCode && phone.startsWith(callingCode)) {
      return phone.substring(callingCode.length);
    }
    return phone;
  }

  return undefined;
}

const ContactDetailsEditForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { user, updateContactInfo },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();

  const handleFormSubmit = async (
    formValues: ContactDetailsValues,
    formikHelpers: FormikHelpers<ContactDetailsValues>
  ) => {
    const phoneWithCountryCallingCode =
      getCountryCallingCodeWrapper(formValues.phone_country_code) +
      formValues.phone;

    const success = await updateContactInfo({
      ...formValues,
      phone: phoneWithCountryCallingCode,
    } as ContactDetailsValues);

    success && toastSuccess(t('data_updated_successfully'));

    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const initialValues: ContactDetailsValues = {
    phone:
      removeCountryCallingCodeFromPhone(
        user?.phone?.phone_number,
        user?.phone?.country_code
      ) ?? '',
    phone_country_code: user?.phone?.country_code ?? 'US',
    email: user?.email ?? '',
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={contactDetailsValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
      {({ handleSubmit, isValid, isSubmitting, values }) => (
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <div className='inputs'>
            <FormFieldText
              name='email'
              placeholder=''
              label={`${t('email')} *`}
              inputMode='email'
            />

            <FormFieldPhone
              phoneFieldName='phone'
              codeFieldName='phone_country_code'
              placeholder='(555) 000 - 0000'
              label={`${t('phone_number')} *`}
            />
          </div>

          <div className='buttons'>
            <Button
              appearance='secondary'
              disabled={isSubmitting}
              onClick={() => onCancel && onCancel()}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={
                isInitialValuesEqualCurrentValues(initialValues, values) ||
                !isValid
              }
              type='submit'>
              {t('save_changes')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default observer(ContactDetailsEditForm);
